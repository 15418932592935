import React, { Component } from 'react'

import Auth from '~/modules/Auth'
import FeaturesList from './FeaturesList'

import './FeaturesCard.scss'

export default class FeaturesCard extends Component {
  render() {
    return (
      <div className="scp-wizard-features-Card">
        <h3>
          Bem-Vindo, <span className="user-name">{Auth.userData.displayName || Auth.userData.name}</span>
        </h3>
        <h1>CRIE SUA GALERIA</h1>
        <FeaturesList />
      </div>
    )
  }
}
