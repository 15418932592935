import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import toastr from 'toastr'
import swal from 'sweetalert'
import format from 'date-fns/format'
import ReactGA from 'react-ga'

import Api from '~/modules/Api'
import CustomerService from '~/modules/CustomerService'

import Loading from '~/widgets/Loading'
import SendWhatsappBox from '~/widgets/SendWhatsappBox'

import CollectionSteps from '../common/Steps'

import SelectionList from './SelectionList'
import CoverUpload from './CoverUpload'
import ProcessingStatus from './ProcessingStatus'

import './CoverUpload.scss'

class ReviewCollection extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isLoading: true,
      isUploadingCover: false,
      processingCover: false,

      collection: {
        customization: {
          bgColor: '',
          fontColor: ''
        }
      },
      selectedSelection: false,
      modalAction: 'new',
      selections: []
    }

    this.goToStep = this.goToStep.bind(this)
    this.getData = this.getData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.deleteSelection = this.deleteSelection.bind(this)
    this.deleteCollection = this.deleteCollection.bind(this)
    this.reEnableSelection = this.reEnableSelection.bind(this)
    this.updateColor = this.updateColor.bind(this)
    this.getSelectionPDF = this.getSelectionPDF.bind(this)
    this.customizeCollection = this.customizeCollection.bind(this)
    this.uploadCover = this.uploadCover.bind(this)
    this.checkUnprocessedCoverTimeout = this.checkUnprocessedCoverTimeout.bind(this)

  }

  handleChange(e) {
    const formState = this.state.form
    formState[e.target.name] = e.target.value
    this.setState({ form: formState })
  }

  goToStep (stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/collection/${this.props.params.id}/${stepName}`)
  }

  updateColor (partName, colorString) {
    this.setState({ collection: {
      ...this.state.collection,
      customization: {
        ...(this.state.collection.customization || {}),
        [`${partName}Color`]: colorString
      }
    } })
  }

  async customizeCollection () {
    const { id, customization } = this.state.collection
    const result = await Api.post('/collection/update', { id, customization })
    ReactGA.event({
      category: 'Seleção',
      action: 'Cusomizou a coleção'
    })
    if (result && result.success) {
      toastr['success']('Dados da coleção atualizados com sucesso!')
      return
    }
    toastr['error']('Erro ao atualizar dados da coleção')
  }

  async getSelectionPDF (hash) {
    const pdf = await Api.get(`/selection/pdf/${hash}`)
    ReactGA.event({
      category: 'Seleção',
      action: 'Gerou o PDF da seleção'
    })
    if (pdf && pdf.ready) {
      const content = document.createElement('div')
      content.style = 'text-align: center; font-size: 14px;padding: 20px 10px 10px 10px;word-wrap: break-word; word-break: break-all;'

      content.innerHTML = `<strong>Link para download:</strong> <br /> <a href='${pdf.url}'>${pdf.url}</a>`

      await swal({
        content,
        dangerMode: false,
        title: false,
        icon: 'success',
        buttons: {
          cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
          closeModal: true,
        }
        }
      })
    } else {
      const content = document.createElement('div')
      content.style = 'text-align: center; font-size: 14px;padding: 20px 10px 10px 10px;word-wrap: break-word; word-break: break-all;'

      content.innerHTML = 'O PDF com os detalhes desta seleção ainda não foi totalmente processado! <br /> <strong>Por favor, aguarde 1 minuto e tente novamente!</strong>'

      await swal({
        content,
        dangerMode: false,
        title: false,
        icon: 'warning',
        buttons: {
          cancel: {
          text: 'Ok',
          value: false,
          visible: true,
          closeModal: true,
        } }
      })
    }
  }

  async sendWhatsapp (hash, extradata) {
    // const pdf = await Api.get(`/selection/pdf/${hash}`)

    let wrapper = document.createElement('div')
    ReactDOM.render(<SendWhatsappBox hash={hash} extradata={extradata} />, wrapper)

    // const content = document.createElement('div')
    // content.style = 'text-align: center; font-size: 14px;padding: 20px 10px 10px 10px;word-wrap: break-word; word-break: break-all;'
    //
    // content.innerHTML = `<strong>Link para download:</strong> <br /> <a href='${hash}'>${hash}</a>`
    ReactGA.event({
      category: 'Seleção',
      action: 'Abriu o modal de envio por whatsapp'
    })
    await swal({
      content: wrapper,
      dangerMode: false,
      title: false,
      icon: false,
      buttons: false
    })
  }

  async deleteSelection (selection_id) {
    const willDelete = await swal({
      title: 'Deletar seleção?',
      text: 'Tem certeza que deseja deletar esta seleção de fotos?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Deletar'
      }
    })
    if (willDelete) {
      ReactGA.event({
        category: 'Seleção',
        action: 'Deletou uma seleção'
      })
      const { id } = this.state.collection
      await Api.post('/collection/selections', { id, action: 'delete', selection_id })
      toastr['success']('Seleção removida!')
      this.getData()
    }
  }

  async deleteGenericLink (linkId) {
    const willDelete = await swal({
      title: 'Deletar link público?',
      text: 'Tem certeza que deseja deletar este link?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Deletar'
      }
    })
    if (willDelete) {
      ReactGA.event({
        category: 'Link Público',
        action: 'Deletou um link público'
      })
      await Api.del(`/select/link/${linkId}`)
      toastr['success']('Link removido!')
      this.getData()
    }
  }

  async deleteCollection () {
    const willDelete = await swal({
      title: 'Deseja realmente excluir essa coleção?',
      text: 'Todas as fotos e seleções também serão removidas!',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Deletar'
      }
    })
    if (willDelete) {
      ReactGA.event({
        category: 'Seleção',
        action: 'Deletou uma coleção'
      })
      const { id } = this.state.collection
      await Api.post('/collection', { id, action: 'delete' })
      toastr['success']('Coleção removida!')
      CustomerService.refreshStats()
      this.props.router.push('/collection/list')
    }
  }

  async reEnableSelection (id) {
    const willReenable = await swal({
      title: 'Reabilitar seleção?',
      text: 'Deseja reabilitar a edição para esta seleção?',
      icon: 'warning',
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Reabilitar'
      }
    })
    if (willReenable) {
      ReactGA.event({
        category: 'Seleção',
        action: 'Reabilitou a edição de uma seleção'
      })
      await Api.post(`/select/selection/${id}/reopen`, { status: 0 }, true)
      await this.getData()
    }
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    this.setState({ isLoading: true })
    const { collection, pictures, selections, genericLinks } = await Api.get(`/select/collection/${this.props.params.id}/review`)
  
    if (collection && collection.id) {
      this.setState({
        isLoading: false,
        collection: {
          ...collection,
          customization: {
            bgColor: 'rgba(0, 0, 0, 0.5)',
            fontColor: '#333333',
            ...collection.customization
          }
        },
        pictures, selections, genericLinks
      })
      if (this.state.processingCover) {
        this.checkUnprocessedCoverTimeout()
      }
      return true
    }
    this.props.router.push('/collection/list')
  }

  checkUnprocessedCoverTimeout (processing = false) {
    let timeout = 3000
    const img = document.createElement('img')
    let processingCover
    const _this = this

    function _check() {
      img.src = _this.state.collection.cover
      processingCover = !img.width && !img.height
      _this.setState({ processingCover })
      if (processingCover) {
        setTimeout(() => _check(), timeout)
      }
    }

    _check()

  }

  async postSelectionData (data) {
    console.log(data)
    const collection_id = this.state.collection.id
    const res = await Api.post('/collection/selections', { collection_id, ...data }, true)
    this.getData()
    return res
  }

  async postGenericLinkData (data) {
    const res = await Api.post((
      data.id && +data.id
      ? `/select/link/${data.id}`
      : '/select/link'
    ), data, true)
    this.getData()
    return res
  }

  async uploadCover (file) {
    if (!file || !file.length) return
    this.setState({ isUploadingCover: true, processingCover: true })
    const id = this.state.collection.id
    await Api.upload('/collection/cover', { id }, file)
    this.setState({ isUploadingCover: false })
    toastr['success']('Upload completo!')
    this.getData()
    // this.getData()
  }

  render() {

    return (
      <div className="row">
      {
        !(this.state.collection.id) ?
        <Loading /> :
        <div>
          <CollectionSteps
            currentStep={3}
            reachedStep={3}
            onClickStep={this.goToStep}
          />
          <div className="col-md-3">
          <div className="portlet light">
              <div className="portlet-title">
                <div className="caption">
                  <i className="fa fa-folder-open-o font-green-sharp" />
                  <span className="caption-subject font-green-sharp sbold">
                    {this.state.collection.title}
                  </span>
                </div>
              </div>
              <div className='collection-cover'>
                <CoverUpload
                  isUploading={this.state.isUploadingCover}
                  onDrop={this.uploadCover}
                  processingCover={this.state.processingCover}
                  currentCover={this.state.collection.cover}
                  fileName="capa">
                </CoverUpload>
              </div>
              <ProcessingStatus pictures={this.state.pictures}></ProcessingStatus>
              <table className="table table-hover table-light">
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'left' }} colSpan={2}>
                      {
                        // <a
                        //   onClick={() => this.goToStep('info')}
                        //   className="btn btn-xs green tooltips"
                        //   data-original-title="Alterar foto da capa">
                        //   <i className="fa fa-image" />
                        // </a>
                      }
                      {/* <a
                        onClick={() => this.openModal(false, false, '#customizeModal')}
                        className="btn btn-xs green tooltips"
                        data-original-title="Personalizar coleção">
                        <i className="fa fa-pencil" />
                      </a> */}
                      <a
                        onClick={() => this.goToStep('info')}
                        className="btn btn-xs green tooltips"
                        data-original-title="Editar coleção">
                        <i className="fa fa-edit" />
                      </a>
                      <a
                        onClick={() => this.goToStep('pictures')}
                        className="btn btn-xs green tooltips"
                        data-original-title="Enviar fotos">
                        <i className="fa fa-cloud-upload" />
                      </a>
                    </td>
                  </tr>
                  <tr role="row" className="odd" style={{}}>
                    <td>
                       Criado em
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {format(this.state.collection.created_at, 'DD/MM/YYYY')}
                    </td>
                  </tr>
                  {this.state.collection.expires_in && <tr role="row" className="odd">
                    <td>
                       Expira em
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {format(this.state.collection.expires_in, 'DD/MM/YYYY')}
                    </td>
                  </tr>}
                  {/* <tr role="row" className="odd" style={{}}>
                    <td>
                      Total de fotos
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      {this.state.pictures.length}
                    </td>
                  </tr> */}
                  <tr role="row" className="odd" style={{}}>
                    <td>Clientes</td>
                    <td style={{ textAlign: 'right' }}>{this.state.selections.length}</td>
                  </tr>
                  {
                    (() => {
                      if (!this.state.selections) return null
                      const status = this.state.selections.reduce((all, current) => {
                        if (!current.open) all.blue++
                        else if (current.expired) all.red++
                        else all.yellow++
                        return all
                      }, { red: 0, yellow: 0, blue: 0 })
                      return [
                        <tr key='yellow-count' role="row" className="odd" style={{}}>
                          <td>
                            Clientes selecionando
                          </td>
                          <td style={{ textAlign: 'right' }}>{status.yellow}</td>
                        </tr>,
                        <tr key='blue-count' role="row" className="odd" style={{}}>
                          <td>
                            Seleções concluídas
                          </td>
                          <td style={{ textAlign: 'right' }}>{status.blue}</td>
                        </tr>,
                        <tr key='red-count' role="row" className="odd" style={{}}>
                          <td>
                            Seleções expiradas
                          </td>
                          <td style={{ textAlign: 'right' }}>{status.red}</td>
                        </tr>
                      ]
                    })()
                  }
                </tbody>
              </table>
            </div>
            <button className="btn red btn-outline" onClick={this.deleteCollection}>
              Excluir seleção
            </button>
          </div>
          <div className="col-md-9">
            <SelectionList
              isLoading={this.state.isLoading}
              genericLinks={this.state.genericLinks}
              selectionList={this.state.selections}
              collectionId={this.state.collection.id}
              getPDF={this.getSelectionPDF}
              sendWhatsapp={this.sendWhatsapp}
              reopen={this.reEnableSelection}
              deleteSelection={this.deleteSelection}
              deleteGenericLink={this.deleteGenericLink.bind(this)}
              location={this.props.location}
              postSelectionData={this.postSelectionData.bind(this)}
              postGenericLinkData={this.postGenericLinkData.bind(this)}
            />
            {/* <AddNewSelection
              openModal={this.openModal}
              isFirst={!this.state.selections.length}
            /> */}
          </div>
        </div>
      }
      </div>
    )
  }
}

export default ReviewCollection
