import React from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'

import UploadContext from './context'

export default function UploadButtonModal () {
  return (
    <UploadContext.Consumer>
    {(upload) => (
      <div className="col-md-10 col-md-offset-1">
        <div className="portlet light" style={{backgroundColor: upload.isUploading ? '#32c5d2' : '#26C281'}}>
        {upload.isUploading ? <IsUploading /> : <UploadArea onDrop={upload.onDrop} />}
        </div>
      </div>
    )}
    </UploadContext.Consumer>
  )
}

function IsUploading () {
  return (
    <div
      className="portlet-body form"
      style={{display: 'table', margin: 'auto', color: '#FFFFFF'}}>
      <div style={{display: 'table-cell', verticalAlign: 'middle', height: 160, textAlign: 'center'}}>
        <i
          className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
          style={{lineHeight: '40px'}} />
          <br />
          <br />
          Enviando foto
      </div>
    </div>
  )
}

function UploadArea (props) {
  return (
    <Dropzone onDrop={props.onDrop} accept="image/jpeg, image/png" style={{cursor: 'pointer'}}>
      <div
        className="portlet-body form"
        style={{display: 'table', margin: 'auto', color: '#FFFFFF'}}>
        <div style={{display: 'table-cell', verticalAlign: 'middle', height: 160, textAlign: 'center'}}>
          <i
            className="fa fa-cloud-upload fa-3x"
            style={{lineHeight: '40px'}} />
          <br />
          Clique aqui para enviar a nova versão da lâmina
        </div>
      </div>
    </Dropzone>
  )
}


UploadArea.propTypes = {
  onDrop: PropTypes.func
}
