import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll'
import moment from 'moment'
import 'moment/locale/pt-br'
import swal from 'sweetalert'

import Api from '~/modules/Api'
import Auth from '~/modules/Auth'
import WS from './Websocket'
import Loading from '~/widgets/Loading'
import GalleryService from './GalleryService'
import UploadService from './UploadService'
import CustomerService from '~/modules/CustomerService'
import GalleryContext from './GalleryContext'
import GalleryAdder from './AdderDialog/GalleryAdder'
import CoverItem from './Cover/CoverItem'
import GalleryShare from './Share/Share'
import PicturesPage from './PicturePage/PicturesPage'

import ReachedLimitDialog from '../../widgets/ReachedLimitDialog/ReachedLimitDialog'

import './GalleryPages.scss'

export default class GalleryPage extends Component {
  gs = GalleryService
  us = UploadService
  wsSubs = null

  constructor(props, context) {
    super(props, context)

    this.state = {
      navMenu: [
        {
          title: 'Fotos',
          icon: 'icon-picture',
          route: 'pictures'
        },
        {
          title: 'Configurações',
          icon: 'icon-settings',
          route: 'settings'
        },
        // {
        //     title: 'Mobile App',
        //     icon: 'fa fa-mobile',
        //     route: 'mobile'
        // }
      ],
      gallery: null,
      links: null,
      sample: false,
      linkBase: process.env.GALLERY_SHARE_URL,

      link: () => this.state.links && this.state.links[0] ? this.state.links[0].url : ''
    }

    this.edit = this.edit.bind(this)
    this.refresh = this.refresh.bind(this)
    this.goto = this.goto.bind(this)
  }

  async componentDidMount() {
    const id = +this.props.params.id

    WS.open(Auth.userData.customer_id)

    if (!id || id === 0) {
      const { permission } = await Api.get('/collection/permission?collection_type=gallery')
      if(!permission){
        ReachedLimitDialog.open({
          title: 'Limite atingido',
          message: `O limite de ${Auth.userData.subscription.limits.col_max_collections} galerias do seu plano foi atingido.`
        })
        CustomerService.pushReachedPlanLimitLog('gallery')
        return this.props.router.push('/gallery/list')
      }

      this.setState({ gallery: { title: 'Titulo da nova galeria' }, sample: true })
      const result = await GalleryAdder.open()
      if (result && result.collection && /^\d*$/.test(result.collection.id)) {
        this.onData(result)

        this.wsSubs = WS.events.subscribe(e => {
          if (this.us.isUploading) {
            return
          }
          if (e.type === 'CoverReady' && e.collection_id === result.collection.id) {
            this.refresh()
          }
        })

        CustomerService.refreshStats()
        this.props.router.push(`/gallery/${result.collection.id}/pictures`)
      }
      else if (result === 'cancel') {
        return
      }
      else {
        this.props.router.push('/gallery/list')
      }
      return
    }
    GalleryService.getGallery(id).subscribe(data => this.onData(data))

    this.wsSubs = WS.events.subscribe(e => {
      if (this.us.isUploading) {
        return
      }
      if (e.type === 'CoverReady' && e.collection_id === id) {
        this.refresh()
      }
    })
  }

  componentWillUnmount() {
    if (swal.getState().isOpen) {
      WS.close()
      swal.setActionValue({ cancel: 'cancel' })
      swal.close('cancel')
    }
    if (this.wsSubs) {
      this.wsSubs.unsubscribe()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onData(data) {
    if (!data || !data.collection) {
      this.props.router.replace('/gallery/list')
      return
    }

    this.setState({ gallery: data.collection, links: data.links, sample: false })
  }

  onRouteChanged() {
    const { id } = this.props.params
    if (this.state.gallery && !id) {
      this.componentDidMount()
    }
  }

  refresh() {
    const { id } = this.props.params
    GalleryService.getGallery(id).subscribe(data => this.onData(data))
  }

  async edit() {
    const result = await GalleryAdder.open(this.state.gallery)
    if (result && result.collection && result.collection.id) {
      this.setState({ gallery: result.collection })
    }
  }

  isActive(routeName) {
    if (routeName.endsWith('/pictures') && this.state.sample) {
      return true
    }
    return this.props.location.pathname.includes(routeName)
  }

  goto(page) {
    this.props.router.push(`/gallery/${this.props.params.id}/${page}`)
    scroller.scrollTo('header-info', { offset: -68, duration: 3e2, smooth: true })
  }

  navTo(url) {
    this.props.router.push(`/gallery/${this.props.params.id}/${url}`)
  }

  render() {
    if (!this.state.gallery) return <Loading />

    return (
      <GalleryContext.Provider value={{
        gallery: this.state.gallery,
        refresh: this.refresh,
        goto: this.goto,
        link: this.state.link
      }}>
        <div className="gallery-page">
          <div className="gallery-header">
            {/* Cover */}
            {this.state.gallery.cover_key &&
              <CoverItem gallery={this.state.gallery} />
            }
            <div className='header-cols'>
              {/* Info */}
              <div id='header-info' className="basic-info">
                <div>
                  <h2>{this.state.gallery.title}</h2>
                  <button className='btn clear-btn' onClick={this.edit}>
                    <i className='fa fa-edit' />
                    editar
                                </button>
                </div>
                <div className='info-date'>
                  {this.state.gallery.event_date ?
                    moment(this.state.gallery.event_date).format('DD/MM/YYYY') : ''}
                </div>
              </div>
              {/* Share Link */}
              {Array.isArray(this.state.links) &&
                this.state.links.map((l, id) => <GalleryShare link={l} key={`gsl-${id}`} />)}
            </div>
            {/* Nav Menu */}
            <div className="gallery-nav">
              <div className="gallery-nav-tabs">
                {
                  this.state.navMenu.map((i) =>
                    <button
                      key={i.route}
                      className={'gallery-nav-item' + (this.isActive(i.route) ? ' active' : '')}
                      onClick={() => this.goto(i.route)}
                    >
                      <i className={i.icon}></i>
                      <span>{i.title}</span>
                    </button>
                  )
                }
              </div>
              {this.state.gallery.expires_in &&
                <div className='expire-date'>
                  expira em: {moment(this.state.gallery.expires_in).format('DD/MM/YYYY')}
                </div>
              }
            </div>
          </div>


          <div className='gallery-outlet'>
            {
              this.state.sample ? <PicturesPage sample={true} /> : this.props.children
            }
          </div>
        </div>
      </GalleryContext.Provider>
    )
  }
}

GalleryPage.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any,
  params: PropTypes.any,
  router: PropTypes.any
}
