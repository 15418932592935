// import range from 'lodash/range'
import PropTypes from 'prop-types'

Pagination.propTypes = {
  currentPage: PropTypes.any,
  pages: PropTypes.number,
  changePage: PropTypes.func,
  picturesCount: PropTypes.number,
  pageSize: PropTypes.number
}

export default function Pagination(props) {
  const isOnFirst = props.currentPage === 1
  const isOnLast = props.pages === props.currentPage
  // let pgs = range(1, Math.min(props.pages, 4) + 1)
  // if (props.pages > 4) {
  //   const toend = props.pages - props.currentPage
  //   let first = toend < 4 ? props.currentPage - 4 + toend : props.currentPage - 2
  //   first = first < 1 ? 1 : first
  //   pgs = range(first, Math.min(props.pages, first + 4, props.currentPage + toend) + 1)
  // }

  function next() {
    if (isOnLast || typeof props.changePage !== 'function') {
      return
    }
    props.changePage(props.currentPage + 1)
  }

  function prev() {
    if (isOnFirst || typeof props.changePage !== 'function') {
      return
    }
    props.changePage(props.currentPage - 1)
  }

  return (
    <div className="collection-pagination">
      <span className="page-item">
        {isOnFirst ? '1' : (props.currentPage - 1) * props.pageSize - 3} {' a '}
        {(isOnFirst && isOnLast) || isOnLast
          ? props.picturesCount
          : (props.currentPage - 1) * props.pageSize + props.pageSize - 4}{' '}
        {' de '}
        {props.picturesCount}
      </span>
      <button className="page-item" disabled={isOnFirst} onClick={prev}>
        <i className="fa fa-angle-left" />
      </button>
      {/* {pgs.map((page) => {
        const disabled = props.currentPage === page
        return (
          <button
            key={'pg-' + page}
            className='page-item'
            disabled={disabled}
            onClick={() => props.changePage(page)}
          >
            {page}
          </button>
        )
      })} */}
      <button className="page-item" disabled={isOnLast} onClick={next}>
        <i className="fa fa-angle-right" />
      </button>
    </div>
  )
}
