import React, {Component} from 'react'
import reactCSS from 'reactcss'
import {SketchPicker} from 'react-color'
import PropTypes from 'prop-types'

class ColorPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      displayColorPicker: false,
      color: {
        r: '0',
        g: '0',
        b: '0',
        a: '1',
      },
      colorString: ''
    }
  }

  componentDidMount() {
    this.setState({colorString: this.props.color})
  }

  handleClick() {
    this.setState({displayColorPicker: !this.state.displayColorPicker})
  }

  handleClose () {
    this.setState({displayColorPicker: false})
  }

  handleChange(color) {
    this.setState({color: color.rgb, colorString: getColorString(color)})
    this.props.updateColor(getColorString(color))
  }

  changeInput(e) {
    this.setState({hex: e.target.value})
  }

  render() {

    const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '30px',
          borderRadius: '2px',
          background: `${ this.state.colorString }`,
        },
        swatch: {
          padding: '2px',
          background: '#ececec',
          borderRadius: '2px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '200',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    })

    return (
      <div className="input-group">
        <span className="input-group-btn">
          <div style={ styles.swatch } onClick={ () => this.handleClick() }>
            <div style={ styles.color } />
          </div>
          { this.state.displayColorPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ () => this.handleClose() }/>
            <SketchPicker color={this.state.colorString} onChange={(color) => this.handleChange(color)} />
          </div> : null }
        </span>
      </div>
    )
  }
}

function getColorString (color) {
  if (color.rgb.a === 1) return color.hex
  const {r, g, b, a} = color.rgb
  return `rgba(${r}, ${g}, ${b}, ${a})`
}

ColorPicker.propTypes = {
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  updateColor: PropTypes.func
}

export default ColorPicker
