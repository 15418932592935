import React, { Component } from 'react'

import Auth from '~/modules/Auth'

class Support extends Component {
  constructor(props, context) {
    super(props, context)
  }

  render() {
    let css_li = { marginBottom: '25px', display: 'block', clear: 'both', width: '100%' }
    let css_icon = { display: 'inline-block' }
    let css_span = { width: '2em', display: 'inline-block', textAlign: 'center' }
    let css_i = { textAlign: 'center', width: '100%' }
    let css_text = { marginTop: '-48px', marginLeft: '10px', lineHeight: '2em', verticalAlign: 'middle', fontSize: '22px', display: 'inline-block' }
    return (
      <div className="row">
        <div className="col-md-6 col-md-offset-3">
          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption">
                <i className=" icon-support"/>
                <span className="caption-subject font-dark sbold uppercase">
                  Suporte
                </span>
              </div>
            </div>
            <div className="portlet-body form row">
              <div className="col-md-12">
                <div className="support-text" style={{ fontWeight: '200', fontSize: '22px' }}>
                  Será um prazer atendê-lo, nossa equipe de suporte está a sua disposição.
                </div>
                <ul className="support-items" style={{ listStyle: 'none',display: 'block',marginTop: '50px',fontWeight: '300', padding: '0' }}>
                  <li style={css_li}>
                    <div className="icon" style={css_icon}>
                      <span className="fa-stack fa-2x" style={css_span}>
                      <i className="fa fa-square fa-stack-2x" style={css_i}></i>
                      <i className="fa fa-envelope fa-stack-1x fa-inverse" style={css_i}></i>
                      </span>
                    </div>
                    <div className="text" style={css_text}>
                      suporte@picsize.com.br
                    </div>
                  </li>
                  <li style={css_li}>
                    <div className="icon" style={css_icon}>
                    <span className="fa-stack fa-2x" style={css_span}>
                    <i className="fa fa-square fa-stack-2x"style={css_i}></i>
                    <i className="fa fa-whatsapp fa-stack-1x fa-inverse"style={css_i}></i>
                      </span>
                    </div>
                    <div className="text" style={css_text}>
                      {'(31) 9 9129-5545'}
                    </div>
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default Support
