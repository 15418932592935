import React from 'react'

import format from 'date-fns/format'
import {Dropdown, Menu} from 'antd'

import RevisionStatus from './RevisionStatus'

import './style.scss'

export default Reviewer

function Reviewer (props) {
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={() => props.openReviewerModal(props.reviewer, false)}>
          Editar prazo limite
        </a>
      </Menu.Item>
      <Menu.Item key="1">
        <a onClick={() => props.deleteReviewer(props.reviewer, false)} className="del-reviewer">
          Excluir
        </a>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1 col-sm-12 col-sm-offset-0">
    <div className="portlet light reviewer-widget">
      <div className="portlet-title">
        <div className="caption">
          <i className="fa fa-user font-green-sharp" />
          <span className="caption-subject font-green-sharp sbold">{props.reviewer.name}</span>
          <span className="caption-subject" style={{color: '#8896a0', fontSize: '14px', display: 'inline-block', marginLeft: '6px'}}>
            {props.reviewer.email}
          </span>
        </div>
        <div className="actions">
          {
            props.reviewer.state === 1 &&
            <button
              className="btn btn-sm btn-new-revision green-meadow"
              onClick={() => props.openReviewerModal({...props.reviewer, state: 0}, true)}
            >
              Solicitar nova revisão
            </button>
          }
          <div className="btn-group">
            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
              <a className="btn green-haze btn-outline btn-sm">
                Ações <i className="fa fa-angle-down" />
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
      <div className="row">
        <ReviewerInfo {...props} />
        <RevisionStatus {...props} />
      </div>
    </div>
    </div>
  )
}

function ReviewerInfo (props) {
    return (
      <div className="col-md-7 reviewer-widget__info">
        <table className="table table-hover table-light">
          <tbody>
            <tr role="row" className="odd">
              <td>Data de envio</td>
              <td className="i-value">
                {format(props.reviewer.created_at, 'DD/MM/YYYY HH:mm')}
              </td>
            </tr>
            {
              props.reviewer.limit_date.length ?
              <tr role="row" className="odd">
                <td>Prazo limite</td>
                <td className="i-value">
                  {format(props.reviewer.limit_date, 'DD/MM/YYYY')}
                </td>
              </tr>
              : null
            }
            <tr role="row" className="odd">
              <td>Link de acesso</td>
              <td className="i-value">
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.REVISE_FRONT_URL}/${props.reviewer.hash}`}>{process.env.REVISE_FRONT_URL}/{props.reviewer.hash}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
}
