import React from 'react'
import PropTypes from 'prop-types'
import round from 'lodash/round'



function ProcessingProgress ({state}) {
  const progress = state.processingProgress.done / state.processingProgress.total
  return (
<div className="col-md-12 ">
      <div className="portlet light">
        <div className="row">
          <div className="col-md-10">
            <div className="progress progress-striped progress-upload active" style={{height: '50px'}}>
              <div
                className="progress-bar progress-bar-success"
                role="progressbar"
                aria-valuenow={(progress) * 100}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{width: `${(progress) * 100}%`}}>
                <span className="sr-only"> {parseInt((progress) * 100)}% Completo (success) </span>
                <span style={{fontSize: '20px', lineHeight: '46px'}}>{parseInt((progress) * 100)}%</span>
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{paddingTop: '5px'}}>
            <small>
              <strong>{state.processingProgress.done}</strong> de <strong>{state.processingProgress.total} fotos</strong>
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          Processando arquivos...
          </div>
        </div>
      </div>
      </div>
  )
}

function UploadProgress (props) {
  if (props.isProcessing) return <ProcessingProgress state={{processingProgress: props.processingProgress}} />
  return (
    <div className="col-md-12 ">
        <div className="portlet light">
          <div className="row">
            <div className="col-md-10">
              <div className="progress progress-striped progress-upload active" style={{height: '50px'}}>
                <div
                  className="progress-bar progress-bar-success"
                  role="progressbar"
                  aria-valuenow={(props.progress) * 100}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{width: `${(props.progress) * 100}%`}}>
                  <span className="sr-only"> {parseInt((props.progress) * 100)}% Completo (success) </span>
                  <span style={{fontSize: '20px', lineHeight: '46px'}}>{parseInt((props.progress) * 100)}%</span>
                </div>
              </div>
            </div>
            <div className="col-md-2" style={{paddingTop: '5px'}}>
              <small>
                <strong>{props.sent}</strong> de <strong>{props.total} fotos</strong>
              </small>
              <br />
              <small>
                <strong>{props.sentBytes}</strong> de <strong>{props.totalBytes}</strong>
              </small>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-warning">
                <strong>Atenção!</strong>
                <br />
                - Não feche essa janela enquanto o envio não for concluído. Após a conclusão você poderá enviar mais fotos e fazer as edições necessárias
                <br />
                - Se desejar <a onClick={props.cancelUpload}>cancelar</a> o envio, os arquivos que já foram enviados não sofrerão alterações.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

UploadProgress.propTypes = {
  done: PropTypes.number,
  total: PropTypes.number
}

export default UploadProgress
