import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

function CoverUpload(props) {
  return (
    <div>
      {
        props.isUploading ?
          <div className="portlet light" style={{ backgroundColor: '#32c5d2', border: '1px solid #32c5d2' }}>
            <div
              className="portlet-body"
              style={{ display: 'table', margin: 'auto', color: '#FFFFFF' }}>
              <div style={{ display: 'table-cell', verticalAlign: 'middle', height: 160, textAlign: 'center' }}>
                <i
                  className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                  style={{ lineHeight: '50px' }} />
                <br />
                <br />
                Enviando {props.fileName}
              </div>
            </div>
          </div>
          : <Dropzone
            onDrop={props.onDrop}
            multiple={false}
            accept="image/jpeg, image/png"
            style={{ cursor: 'pointer' }}>

            {props.processingCover ?
              <div className="portlet light" style={{ border: '1px solid #EFEFEF' }}>
                <div className="portlet-body" style={{ display: 'table', margin: 'auto', textAlign: 'center' }}>
                  <div style={{ display: 'table-cell', verticalAlign: 'middle', height: '160px', textAlign: 'center' }}>
                    <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
                    <span className="sr-only">Loading...</span>
                    <br />Processando...
                  </div>
                </div>
              </div>
              :
              (props.currentCover && props.currentCover !== 'false' ?
                <div className="cover-wrap">
                  <img src={props.currentCover} />
                  <div className="cover-tip"></div>
                  <div className="cover-tip-message">
                    <i className="fa fa-cloud-upload fa-2x"></i>
                    Alterar {props.fileName}
                  </div>
                </div>
                :
                <div className="portlet light" style={{ border: '1px solid #EFEFEF' }}>
                  <div className="portlet-body" style={{ display: 'table', margin: 'auto', textAlign: 'center' }}>
                    <div style={{ display: 'table-cell', verticalAlign: 'middle', height: '160px', textAlign: 'center' }}>
                      <i className="fa fa-cloud-upload fa-3x" style={{ lineHeight: '40px' }}></i>
                      <br />Enviar {props.fileName}
                      <br />
                      <br />
                      <br />
                      <small>- Largura recomendada: <strong>1920px</strong></small><br />
                      <small>- Tipo de foto: <strong>PNG ou JPEG</strong></small><br />
                    </div>
                  </div>
                </div>
              )
            }
          </Dropzone>
      }
      {props.children}
    </div>
  )
}

CoverUpload.propTypes = {
  fileName: PropTypes.string
}

CoverUpload.defaultProps = {
  fileName: 'sel-cover'
}


export default CoverUpload
