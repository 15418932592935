import React, { Component } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import toastr from 'toastr'

import Switch from 'react-bootstrap-switch'
import ReactGA from 'react-ga'

import Auth from '~/modules/Auth'
import Api from '~/modules/Api'
import Loading from '~/widgets/Loading'
import * as FeatureUnavailableAlert from '~/widgets/Alerts/FeatureUnavailable'

import LogoUpload from './LogoUpload'
import WatermarkPosition from './WatermarkPosition'


class CollectionWatermark extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      settings: false,
      isUploadingLogo: false
    }
    this.uploadWatermark = this.uploadWatermark.bind(this)
    this.updateValue = this.updateValue.bind(this)
    this.getData = this.getData.bind(this)
    this.checkUnprocessedTimeout = this.checkUnprocessedTimeout.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async alert() {
    swal({
      text: 'As fotos que já foram enviadas NÃO sofrerão alterações.',
      title: 'Aviso',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Ok, entendi',
          value: false,
          visible: true,
          closeModal: true,
        }
      }
    })
  }

  async getData() {
    const settings = await Api.get('/customer/settings/collection_watermark')
    const processingLogo = !settings.watermark && settings.watermark_tmp
    this.checkUnprocessedTimeout(processingLogo)

    return this.setState({
      processingLogo,
      settings: {
        watermark: false,
        watermark_tmp: false,
        watermark_position: 9,
        ...settings,
        use_watermark: settings.use_watermark === 'true',
        use_antiCopy: settings.use_antiCopy === 'true',
      }
    })
  }

  checkUnprocessedTimeout(processing = false) {
    let timeout = 3000
    if (!this.state.processingLogo && !processing) return
    setTimeout(() => {
      this.getData()
    }, timeout)
  }

  async updateValue(param, value) {
    if (param === 'use_watermark' && value && (Auth.isFreemium || Auth.isTrial)) {
      return await FeatureUnavailableAlert.open()
    }

    ReactGA.event({
      category: 'Seleção',
      action: 'Alterou a marca d\'água'
    })
    const result = await Api.post('/customer/settings/collection_watermark', {
      values: {
        ...this.state.settings,
        [param]: value
      }
    })
    if (result && result.success) {
      await this.getData()
      this.alert()
      toastr['success']('Dados atualizados com sucesso!')
      return
    }
    toastr['error']('Erro ao atualizar dados')
  }

  async uploadWatermark(file) {
    if (!file || !file.length) return
    this.setState({ isUploadingLogo: true })
    await Api.upload('/customer/settings/collection_watermark', { upload: true, field: 'watermark' }, file)
    this.setState({ isUploadingLogo: false })
    toastr['success']('Upload completo!')
    this.getData()
  }

  render() {
    return (
      <div className="row">
        {
          !(this.state.settings) ?
            <Loading /> :
            <div>
              <div className="col-md-8">
                <div className="portlet light">
                  <div className="portlet-title">
                    <div className="caption">
                      <i className="fa fa-registered font-dark"></i>
                      <span className="caption-subject font-dark sbold uppercase">{"Marca d'água"}</span>
                    </div>
                    <div style={{ float: 'right' }}>
                      <Switch
                        defaultValue={this.state.settings.use_watermark}
                        onText="Ativo"
                        offText="Inativo"
                        onChange={(e, s) => this.updateValue('use_watermark', s)}
                      />
                    </div>
                  </div>
                  <div className="portlet-body row">
                    <Disableable enabled={this.state.settings.use_watermark}>
                      <div className="col-md-4">
                        <LogoUpload
                          isUploading={this.state.isUploadingLogo}
                          onDrop={this.uploadWatermark}
                          processingLogo={this.state.processingLogo}
                          fileName="marca d'água"
                          currentLogo={this.state.settings.watermark}>
                          <small>- Tamanho recomendado: <strong>360 x 180px</strong></small><br />
                          <small>- Tipo de foto: <strong>PNG transparente</strong></small><br />
                        </LogoUpload>
                      </div>
                      <div className="col-md-offset-1 col-md-6">
                        <WatermarkPosition
                          defaultPosition={this.state.settings.watermark_position}
                          onChange={(p) => this.updateValue('watermark_position', p)}
                        />
                      </div>
                    </Disableable>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="portlet light">
                  <div className="portlet-title">
                    <div className="caption">
                      <i className="fa fa-paint-brush font-dark"></i>
                      <span className="caption-subject font-dark sbold uppercase">{'Carimbo "cópia não autorizada"'}</span>
                    </div>
                  </div>
                  <div className="portlet-body form">
                    <Switch
                      defaultValue={this.state.settings.use_antiCopy}
                      onText="Ativo"
                      offText="Inativo"
                      onChange={(e, s) => this.updateValue('use_antiCopy', s)}
                    />
                  </div>
                  <Disableable enabled={this.state.settings.use_antiCopy}>
                    <label className="carimbo-label">
                      Suas novas fotos receberão um carimbo como no exemplo abaixo
                </label>
                    <div className="carimbo-example" />
                  </Disableable>
                </div>
              </div>
            </div>
        }
      </div>
    )
  }
}

function Disableable(props) {
  const disabledStyle = {
    opacity: '0.35',
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
  }
  const overlayStyle = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: '100'
  }
  return (
    <div style={props.enabled ? undefined : disabledStyle}>
      {
        props.enabled ? null : <div style={overlayStyle} />
      }
      {props.children}
    </div>
  )
}

Disableable.propTypes = {
  enabled: PropTypes.any,
  children: PropTypes.any
}

export default CollectionWatermark
