import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import './FeatureItem.scss'

export default class FeaturesItem extends Component {
  render() {
    const { data } = this.props

    return (
      <div className="scp-wizard-feature-item">
        <div className="feature-icon">{data.icon()}</div>

        <h4 className="feature-title">{data.title}</h4>

        <div className="feature-text">
          <p>{data.text}</p>
        </div>

        {data.actions && data.actions[0] && (
          <Link className="feature-action-btn primary" to={data.actions[0].url} onClick={data.actions[0].cb}>
            {data.actions[0].text}
          </Link>
        )}
        {data.actions && data.actions[1] && (
          <Link className="feature-action-btn secondary" to={data.actions[1].url} onClick={data.actions[1].cb}>
            {data.actions[1].text}
          </Link>
        )}
      </div>
    )
  }
}

FeaturesItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.func,
    text: PropTypes.string,
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
        cb: PropTypes.func
      })
    )
  })
}
