import React from 'react'
import PropTypes from 'prop-types'

import {Tooltip} from 'antd'

import imageCoverNotFount from 'img/cover-not-found.jpg'

import './style.scss'


function CollectionListItem (props) {
  return (
    <tr role="row" className="odd" style={{}}>
      <td className="sorting_1">
      {
        props.album.cover ?
        <a onClick={() => props.goToStep(props.album.id, 'sheets')}>
          <img
            className="media-object"
            src={props.album.cover}
            data-src={props.album.cover}
            style={{width: 180}} />
        </a>
        :
        <a onClick={() => props.goToStep(props.album.id, 'sheets')}>
          <img
            className="media-object"
            src={imageCoverNotFount}
            data-src={imageCoverNotFount}
            style={{width: 180}} />
        </a>
      }
      </td>
      <td>
      <a
        onClick={() => props.goToStep(props.album.id, 'review')} style={{color: '#8896a0'}}>
          {props.album.title}
      </a>
      </td>
      <td>
        <StatusBadge count={props.album.status.expired} title="Expiradas" type="expired" />
        <StatusBadge count={props.album.status.pendingReview} title="Em aberto" type="pending-review" />
        <StatusBadge count={props.album.status.approved} title="Aprovadas" type="approved" />
        <StatusBadge count={props.album.status.waitingChanges} title="Aguardando alterações" type="waiting-changes" />
      </td>
    <td style={{textAlign: 'center'}}>
      <Shortcut {...props} step="info">
        <ActionButton title="Editar dados" icon="edit" />
      </Shortcut>
      <Shortcut {...props} step="sheets">
        <ActionButton title="Enviar lâminas" icon="cloud-upload" />
      </Shortcut>
      <Shortcut {...props} step="review">
        <ActionButton title="Acompanhar revisão" icon="eye" />
      </Shortcut>
    </td>
  </tr>
  )
}

function StatusBadge (props) {
  if (!props.count) return null
  return (
    <Tooltip title={props.title}>
      <span className={`badge badge-status badge-status--${props.type}`} style={{marginRight: '4px'}}>
        {props.count > 1 ? props.count : (<i>&nbsp;&nbsp;</i>)}
      </span>
    </Tooltip>
  )
}

function ActionButton (props) {
  return (
    <Tooltip title={props.title}>
      <button className="btn btn-xs green">
        <i className={`fa fa-${props.icon}`} />
      </button>
    </Tooltip>
  )
}

function Shortcut (props) {
  return (
    <a onClick={() => props.goToStep(props.album.id, props.step)}>
      {props.children}
    </a>
  )
}

CollectionListItem.propTypes = {
  album: PropTypes.object,
  goToStep: PropTypes.func
}

export default CollectionListItem
