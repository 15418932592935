import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import toastr from 'toastr'
import { Form, Input, Button, DatePicker, Icon } from 'antd'
import locale from 'antd/lib/date-picker/locale/pt_BR'
import moment from 'moment'

import GalleryService from '../GalleryService'

import './GalleryAdder.scss'

class GalleryAdder extends Component {
  gallery = null
  editing = false
  modelHasChange = false
  failCounter = 0

  constructor(props, context) {
    super(props, context)

    this.gallery = props.gallery || {}
    this.editing = props.editing === true

    this.state = {
      busy: false,
      title: this.gallery.title || '',
      event_date: this.gallery.event_date || '',
      valid: false,
      pristine: true
    }

    this.save = this.save.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  componentDidUpdate() {
    if (this.modelHasChange) {
      this.modelHasChange = false
      this.validate()
    }
  }

  onChange(k, v) {
    this.setState({ [k]: v, pristine: false })
    this.modelHasChange = true
  }

  validate() {
    const { title, pristine } = this.state
    let valid = typeof title === 'string' && /^.{3,48}$/.test(title)
    valid = valid && !pristine
    this.setState({ valid })
  }

  cancel() {
    swal.close()
  }

  async save() {
    if (this.state.busy || !this.state.valid) return
    this.setState({ busy: true })
    const { title, event_date } = this.state
    const model = { title, event_date }
    let res
    if (this.editing) {
      model.id = this.props.gallery.id
      res = await GalleryService.updateGallery(model)
      if (res && res.success && res.collection && res.collection[0]) {
        swal.setActionValue({ confirm: { collection: { ...this.gallery, ...model } } })
        swal.close('confirm')
        toastr['success']('As alterações foram salvas!')
        return
      }
    }
    else {
      res = await GalleryService.createGallery(model)
      if (res && res.success && res.collection && (res.collection.id || +res.collection.id === 0)) {
        swal.setActionValue({ confirm: res })
        swal.close('confirm')
        toastr['success']('Nova galeria adicionada!')
        return
      }
    }

    toastr['warning']('Algo deu errado, tente novamente mais tarde ou entre em contato com o suporte.')
    setTimeout(this.setState.bind(this, { busy: false }), 5e2)
    console.warn(res)
    if (this.failCounter++ >= 3) {
      // eslint-disable-next-line no-undef
      Raven.captureException(res)
    }
  }

  render() {
    return (
      <div key='adder-dialog'>
        <div className="ant-modal-header">
          <div className="ant-modal-title" id="rcDialogTitle0">
            {this.editing ? 'Atualizar informações da galeria' : 'Criar galeria para entrega'}
          </div>
        </div>
        <button aria-label="Close" className="ant-modal-close" onClick={this.cancel}>
          <span className="ant-modal-close-x">
            <Icon type='close' />
          </span>
        </button>
        <div className={`ant-modal-body${this.editing ? '' : ' add-new-form'}`}>
          <Form layout="horizontal">
            <FormItem label='Nome da galeria' key='gallery-title-item'
              extra='Ex.: Casamento Guilherme & Camilla'>
              <Input placeholder="Dê um nome para a galeria" key='gallery-title-input' autoFocus
                value={this.state.title} onChange={e => this.onChange('title', e.target.value)} />
            </FormItem>
            {this.editing &&
              <FormItem label="Data do evento">
                <DatePicker
                  format="DD/MM/YYYY"
                  locale={locale}
                  value={this.state.event_date ? moment(this.state.event_date) : null}
                  popupStyle={{ zIndex: 99995 }}
                  onChange={m => this.onChange('event_date', m ? m.toISOString() : null)}
                />
              </FormItem>
            }
          </Form>
        </div>
        <div className="ant-modal-footer">
          <div>
            <Button onClick={this.cancel}>Cancelar</Button>
            <Button type="primary" onClick={this.save} disabled={this.state.busy || !this.state.valid}>
              {this.state.busy ? <i className='fa fa-spinner fa-pulse'></i> : <Icon type='check' />}
              {this.editing ? 'Salvar' : 'Criar Galeria'}
            </Button>
          </div>
        </div>

      </div>
    )
  }

}

GalleryAdder.propTypes = {
  gallery: PropTypes.object,
  editing: PropTypes.bool,
}

const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 10 }
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 12 }
  },
  colon: false,
  className: 'ps-ant-formItem'
}

const FormItem = props => <Form.Item {...formItemLayout} {...props} />

export default {
  open(gallery) {
    const content = document.createElement('div')
    ReactDOM.render(<GalleryAdder editing={!!gallery} gallery={gallery} />, content)
    return swal({
      className: 'gallery-dialog',
      content,
      buttons: false,
      closeOnClickOutside: false
    })
  }
}
