import React, { Component } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import toastr from 'toastr'
import ReactGA from 'react-ga'

import Gallery from '~/widgets/react-grid-gallery/Gallery'

import Api from '~/modules/Api'
import GalleryService from '../GalleryService'
import './GalleryPictures.scss'

export default class GalleryPictures extends Component {

  constructor(props, context) {
    super(props, context)

    this.state = {
      pictures: props.pictures,
      images: [],
      pageKey: 'ps-pl-' + Math.random(),
    }

    this.onAction = this.onAction.bind(this)
    this.onSelectImage = this.onSelectImage.bind(this)
    this.allImagesSelected = this.allImagesSelected.bind(this)
  }

  componentDidMount() {
    this.setupImages()
    if (this.props.$actions && typeof this.props.$actions.subscribe === 'function') {
      this.props.$actions.subscribe(this.onAction)
    }

  }

  componentWillUpdate(props) {
    if (this.state.pictures !== props.pictures) {
      this.setState({ pictures: props.pictures })
      this.setupImages()
    }
    // this.setupImages()
  }

  onAction(a) {
    switch (a) {
      case 'selectAll': return this.onClickSelectAll()
      case 'deleteSelection': return this.deletePictures(this.getSelected(this.state.images), this.getSize(this.state.images))
      default: console.warn('UnknowToolbarAction', a)
    }
  }

  setupImages() {
    // TODO: Mover objetos de estilo para o css
    const captionStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      maxHeight: '240px',
      position: 'absolute',
      wordWrap: 'break-word',
      bottom: '0',
      width: '100%',
      color: 'white',
      padding: '2px 8px',
      fontSize: '90%',
      zIndex: '9'
    }
    const toolsStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: '0',
      right: '0px',
      color: 'white',
      padding: '5px 0',
      width: '30px',
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '16px',
      zIndex: '11',
    }
    const processingStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: '0',
      right: '0',
      color: 'white',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      zIndex: '5'
    }

    this.setState({
      pageKey: 'ps-pl-' + Math.random(),
      images: this.props.pictures ? this.props.pictures.map((p) => {
        const img = {
          id: p.id,
          size: p.file_size,
          src: p.image,
          thumbnail: p.thumb || p.image,
          thumbnailWidth: 600,//p.thumb ? 600 : p.original_width,
          thumbnailHeight: 600,//p.thumb ? 600 : p.original_height,
          caption: p.title,
          isSelected: false,
          thumbnailStyle: p.image_processing ? {
            filter: 'blur(2px)'
          } : false,
          thumbnailCaption: null,
          _thumbnailCaption: i => i.thumbnailCaption = (
            <div>
              <div style={toolsStyle} key={'delete-' + p.id}>
                <i className="fa fa-trash" style={{ cursor: 'pointer' }}
                  onClick={() => this.deletePictures([p.id], [p.file_size])}></i>
              </div>
              {
                !p.image_processing ? null :
                  <div style={processingStyle} key={'processing-' + p.id}>
                    <div style={{ position: 'absolute', top: '40%', width: '100%' }}>
                      <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
                      <span className="sr-only">Loading...</span>
                      <span style={{ display: 'block', clear: 'both', marginTop: '12px', fontSize: '11px' }}>
                        Processando foto
                      </span>
                    </div>
                  </div>
              }
            </div>
          ),
          customOverlay: (
            <div style={captionStyle}>{p.title}</div>
          )
        }
        img._thumbnailCaption(img)
        return img
      }) : []
    })
  }

  async deletePictures(pictures, pictures_size) {
    const willDelete = await swal({
      title: `Deletar ${pictures.length > 1 ? 'fotos' : 'foto'}?`,
      text: `Tem certeza que deseja deletar ${pictures.length > 1 ?
        pictures.length + ' fotos' : 'a foto'} da coleção?`,
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: `Deletar ${pictures.length > 1 ? 'fotos' : 'foto'}`
      }
    })
    if (willDelete) {
      const { id } = this.props.params
      await GalleryService.deletePictures(id, pictures).toPromise()
      toastr['success']('Imagens removidas!')

      ReactGA.event({
        category: 'Seleção',
        action: 'Fotos deletadas na coleção',
        value: pictures.length
      })
    }
    this.setState({
      images: this.state.images.map((i) => ({ ...i, isSelected: false })),
    })

    Api.get(`/collection/info?collection_id=${this.props.params.id}`)
    Api.post('/collection/patch/decrement', { 
      collection_type: 'gallery', 
      pictures_count: pictures.length,
      pictures_size: pictures_size.reduce((total, size) => (+total) + (+size), 0)/1024/1024
    })
    this.shareState({ selectAllChecked: false, anySelected: false })
  }

  onSelectImage(index) {
    const images = this.state.images.slice()
    const img = images[index]

    img.isSelected = img.hasOwnProperty('isSelected') ? !img.isSelected : true
    this.setState({ images })

    if (this.allImagesSelected(images)) {
      return this.shareState({ anySelected: true, selectAllChecked: true })
    } else if (this.getSelected(images).length > 0) {
      return this.shareState({ anySelected: true, selectAllChecked: false })
    }

    return this.shareState({ anySelected: false, selectAllChecked: false })
  }

  onClickSelectAll() {
    const selectAllChecked = !this.state.selectAllChecked
    const anySelected = !!selectAllChecked
    this.setState({
      images: this.state.images.map((i) => ({ ...i, isSelected: selectAllChecked })),
      pageKey: 'ps-pl-' + Math.random(),
    })
    this.shareState({ selectAllChecked, anySelected })
  }

  allImagesSelected(images) {
    return images.filter((i) => i.isSelected).length === images.length
  }

  getSelected(images) {
    return images.filter((i) => i.isSelected).map((i) => i.id)
  }

  getSize(images) {
    return images.filter(image => image.isSelected).map(image => image.size)
  }

  shareState(state) {
    this.setState(state)
    this.props.upState(state)
  }

  render() {
    if (!this.props.pictures) return <h3>Loading...</h3>

    return (
      <div className='gallery-pictures'>
        <Gallery
          key={this.state.pageKey}
          images={this.state.images}
          enableLightbox={true}
          enableImageSelection={true}
          onSelectImage={this.onSelectImage}
        />
        <div className='clear-fix'></div>
      </div>
    )
  }
}

GalleryPictures.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any,
  params: PropTypes.any,
  router: PropTypes.any,
  pictures: PropTypes.array,
  upState: PropTypes.func,
  $actions: PropTypes.any
}
