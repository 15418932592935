import React, {Component} from 'react'
import PropTypes from 'prop-types'

import SortableGallery from './Sortable'
import DetailedGallery from './Detailed'


function AlbumSheetsGallery (props) {
  if (!props.items || !props.items.length) return null
  if (!props.isSortable) {
    return (<DetailedGallery {...props} />)
  }
  return (<SortableGallery {...props} />)
}

AlbumSheetsGallery.propTypes = {
  isSortable: PropTypes.bool,
  items: PropTypes.array,
  onMoveItem: PropTypes.func
}

export default AlbumSheetsGallery
