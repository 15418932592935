import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Tooltip} from 'antd'


class CollectionInfoForm extends Component {
  componentDidMount() {
  }

  render() {
    return (
      <form className="form-horizontal" role="form" onSubmit={this.props.onSubmit}>
        <div className="form-body">
          <div className="form-group">
            <label className="col-md-3 control-label">
              <Tooltip title="Opte por um nome de fácil identificação futuramente. Por exemplo 'Casamento Guilherme e Camilla'">
                Nome da seleção <i className="fa fa-question-circle"/>
              </Tooltip>
            </label>
          <div className="col-md-9">
            <input defaultValue={this.props.title} onChange={this.props.handleChange} name="title" type="text" className="form-control" />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <Tooltip title="Aqui você pode inserir uma mensagem de boas vindas para o seu cliente, o que torna o seu atendimento mais personalizado. Deixar esse campo em branco não é nenhum problema">
              Texto de boas vindas <i className="fa fa-question-circle"/>
            </Tooltip>
          </label>
          <div className="col-md-9">
            <textarea defaultValue={this.props.description} onChange={this.props.handleChange} name="description" className="form-control" rows={3} />
          </div>
      </div>
      </div>
      <div className="form-actions right">
        <Button htmlType="submit" type="primary" icon="check" loading={this.props.isSubmiting}>
        {`Salvar ${this.props.isNew ? ' e continuar' : ''}`}
        </Button>
      </div>
    </form>
    )
  }
}

CollectionInfoForm.propTypes = {
  onSubmit: PropTypes.func,
  isSubmiting: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  isNew: PropTypes.bool,
  handleChange: PropTypes.func
}

export default CollectionInfoForm
