import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import { Icon } from 'antd'

import './FinishedUploadDialog.scss'

function FinishedUploadDialog({ msg }) {
  function share() {
    swal.setActionValue({ share: 'share' })
    swal.close('share')
  }

  function config() {
    swal.setActionValue({ config: 'config' })
    swal.close('config')
  }

  function close() {
    swal.close()
  }

  return (
    <div className='finished-upload-dialog'>
      <div className='dialog-header'>
        <div className='dialog-title'>Envio de fotos concluído com sucesso!</div>
        <button autoFocus className='dialog-close-btn' onClick={close}>
          <Icon type='close' />
        </button>
      </div>
      <div className='dialog-body'>
        <div>{msg}</div>
      </div>
      <div className='dialog-footer'>
        <h4>AGORA VOCÊ PODE:</h4>
        <button className='settings-btn' onClick={config}>Configurar Galeria</button>
        <button className='share-btn' onClick={share}>Compartilhar</button>
      </div>
    </div>
  )

}

FinishedUploadDialog.propTypes = {
  msg: PropTypes.string,
}


export default {
  open(props) {
    const content = document.createElement('div')
    ReactDOM.render(<FinishedUploadDialog {...props} />, content)

    return swal({
      icon: 'success',
      content,
      buttons: false,

    })
  }
}
