import Main from './layouts/Main'
import BasicLayout from '~/layouts/Basic'
import Auth from './modules/Auth'
import CustomerService from './modules/CustomerService'

import LoginView from './pages/Login'

import CollectionList from './pages/Collection/List'
import NewCollection from './pages/Collection/New'
import CollectionInfo from './pages/Collection/Info'
import CollectionPictures from './pages/Collection/Pictures'
import ReviewCollection from './pages/Collection/Review'
import CollectionSettings from './pages/Collection/Settings'

import GalleryListPage from './pages/Gallery/ListPage/GalleryListPage'
import GalleryPage from './pages/Gallery/GalleryPage'
import GalleryPicturesPage from './pages/Gallery/PicturePage/PicturesPage'
import GalleryMobilePage from './pages/Gallery/MobilePage/MobilePage'
import GallerySettingsPage from './pages/Gallery/SettingsPage/SettingsPage'

import AlbumList from './pages/Album/List'
import NewAlbum from './pages/Album/New'
import AlbumInfo from './pages/Album/Info'
import AlbumSheets from './pages/Album/Sheets'
import AlbumReview from './pages/Album/Review'

import KnowledgePage from './pages/Knowledge/KnowledgePage'

import CustomerClients from './pages/Clients'

import Home from './pages/Home'
import { WizardPage } from './pages/Wizard'
import Support from './pages/Support'

const routes = {
  childRoutes: [
    {
      path: '/wizard',
      component: BasicLayout,
      onEnter(nextState, replace, calback) {
        CustomerService.stats.subscribe(() => calback())
      },
      childRoutes: [
        {
          path: '*',
          component: WizardPage
        }
      ]
    },
    {
      path: '/',
      component: Main,
      indexRoute: {
        onEnter: (nextState, replace) => {
          replace('/home')
        }
      },
      onEnter: (nextState, replace, calback) => {
        if (!Auth.isAuthenticated) {
          return replace('/login')
        }
        calback()
        // CustomerService.stats.subscribe(s => {
        // if (s.collectionInfo.count < 1 && s.galleryInfo.count < 1 && Auth.isFreemium) {
        // replace('/wizard/step-one')
        // }
        // calback()
        // })
      },
      childRoutes: [
        { path: 'collection/list', component: CollectionList },
        { path: 'collection/new', component: NewCollection },
        { path: 'collection/:id/info', component: CollectionInfo },
        { path: 'collection/:id/pictures', component: CollectionPictures },
        { path: 'collection/:id/review', component: ReviewCollection },
        { path: 'settings/customize', component: CollectionSettings.Customize },
        { path: 'settings/watermark', component: CollectionSettings.Watermark },
        { path: 'clients', component: CustomerClients },
        { path: 'gallery/list', component: GalleryListPage },
        { path: 'knowledge/videos', component: KnowledgePage },
        {
          path: 'gallery',
          component: GalleryPage,
          childRoutes: [
            { path: ':id', component: GalleryPicturesPage },
            { path: ':id/pictures', component: GalleryPicturesPage },
            { path: ':id/mobile', component: GalleryMobilePage },
            { path: ':id/settings', component: GallerySettingsPage }
          ]
        },
        {
          path: 'album/',
          indexRoute: { onEnter: (nextState, replace) => replace('/album/list') },
          childRoutes: [
            { path: 'list', component: AlbumList },
            { path: 'new', component: NewAlbum },
            { path: ':id/info', component: AlbumInfo },
            { path: ':id/sheets', component: AlbumSheets },
            { path: ':id/review', component: AlbumReview }
          ]
        },
        { path: 'home', component: Home },
        { path: 'support', component: Support },
        {
          path: '/logout',
          onEnter: (nextState, replace) => {
            Auth.logout()
            replace('/')
          }
        }
      ]
    },
    {
      path: '/login',
      // onEnter: (nextState, replace) => {
      //   if (Auth.isAuthenticated) {
      //     return replace('/collection/list')
      //   }
      // },
      component: LoginView
    }
  ]
}

export default routes
