import React, { Component } from 'react'

import swal from 'sweetalert'

class SendWhatsappBox extends Component {
  constructor(props) {
    super(props)

    this.state = {
      message:
        (this.props.extradata || {}).client_message ||
        'As suas fotos já estão disponíveis!\nEstou te enviando o link de acesso para escolher as que você mais gostar',
      tel: '',
      url: ''
    }

    this.changeText = this.changeText.bind(this)
  }

  changeText(e) {
    const hash = this.props.hash
    this.setState({
      [e.target.name]: e.target.value
    })

    setTimeout(() => {
      this.setState({
        url: buildURL(this.state)
      })
    }, 200)

    function buildURL(s) {
      const tel = '55' + s.tel.toString().replace(/\D+/g, '')
      const message = `${s.message}\n\n${
        process.env.SELECTION_FRONT_URL
      }/${hash}`
      return `https://web.whatsapp.com/send?phone=${tel}&text=${encodeURIComponent(
        message
      )}`
    }
  }
  render() {
    return (
      <div className="row" style={{ fontFamily: 'Open Sans' }}>
        <div className="portlet-body form">
          <div className="form-body" id="modal-form">
            <div className="form-group">
              <label className="col-md-5 control-label">
                Número do celular
              </label>
              <div className="col-md-7">
                <input
                  type="text"
                  name="tel"
                  className="form-control"
                  placeholder="(31) 9 9999-9999"
                  value={this.state.tel}
                  onChange={this.changeText}
                />
              </div>
            </div>
            <br />
            <br />
            <div className="form-group">
              <label className="col-md-5 control-label">
                Mensagem
                <br />
                <span style={{ fontSize: '12px' }}>
                  (o link da seleção será adicionado no fim da mensagem)
                </span>
              </label>
              <div className="col-md-7">
                <textarea
                  name="message"
                  rows="10"
                  className="form-control"
                  value={this.state.message}
                  onChange={this.changeText}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-md-12">
                <hr />
                <a
                  className="btn green"
                  style={{ display: 'inline-block', float: 'right' }}
                  href={this.state.url}
                  target="_blank"
                >
                  <i className="fa fa-whatsapp" /> Enviar
                </a>
                <button
                  type="button"
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    marginRight: '10px'
                  }}
                  className="btn dark btn-outline"
                  onClick={() => swal.close()}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SendWhatsappBox
