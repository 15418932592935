import React from 'react'
import PropTypes from 'prop-types'

import format from 'date-fns/format'
import {Dropdown, Menu} from 'antd'

import Modal from './Modal'

import './style.scss'
const SHEET_STATES = {
  0: 'Aguardando revisão',
  1: 'Alterações pendentes',
  2: 'Aprovada',
  3: 'Não revisada',
}

function DetailedGallery (props) {
  let items = props.items
  if (props.filter !== false) {
    items = items.filter((item) => item.state === props.filter)
  }
  const openModal = (sheet, type) => {
    Modal.open({sheet, reviewers: props.reviewers, updateGallery: props.updateGallery}, type)
  }
  return (
    <Container>
      {
        //<Filter setFilter={props.setFilter} current={props.filter} />
      }
      {items.map((value, index) => (
        <Item
          key={`dg-${index}`}
          index={index}
          value={value}
          enableEdit={props.enableEdit}
          openModal={openModal}
        />
      ))}
    </Container>
  )
}

function Container ({children}) {
  return (
    <div className="album-sheets-gallery__detailed">
      {children}
    </div>
  )
}

function Filter ({setFilter, current}) {
  return (
    <div className="col-md-12">
      <div className="album-sheets-gallery__detailed__filter">
        <span>
          Mostrar:
        </span>
        <FilterItem id={false}>
          Todas
        </FilterItem>
        <FilterItem id={0}>
          Aguardando revisão
        </FilterItem>
        <FilterItem id={1}>
          Alterações pendentes
        </FilterItem>
        <FilterItem id={2}>
          Aprovadas
        </FilterItem>
      </div>
    </div>
  )

  function FilterItem ({id, children}) {
    let className = 'album-sheets-gallery__detailed__filter__item'
    const isCurrent = current === id
    if (isCurrent) {
      className = `${className} ${className}--current`
    }
    return (
      <span
        className={className}
        onClick={() => {
          if (isCurrent) return
          setFilter(id)
        }}
        children={children}
      />
    )
  }
}

function Item (props) {
  const className = 'album-sheets-gallery__detailed__item'
  return (
    <div className="col-md-6">
      <div className={`${className} ${className}--state${props.value.state || 0}`}>
        <ItemImage src={props.value.thumb_url || props.value.url} />
        <ItemDetails value={props.value} enableEdit={props.enableEdit} />
        <ItemActions {...props} />
      </div>
    </div>
  )
}

function ItemImage ({src, title}) {
  return (
    <div className="album-sheets-gallery__detailed__item__image">
      <img src={src} alt={title} />
    </div>
  )
}

function ItemDetails ({value, enableEdit}) {
  return (
    <div className="album-sheets-gallery__detailed__item__details">
      <h4>
        #{value.position + 1}
      </h4>
      <ul>
        <li>
          <span>Status:</span>
          <ItemDetailsStatus index={value.state || 0} enableEdit={enableEdit} />
        </li>
        <li>
          <span>Versão da lâmina:</span>
          <span>{value.version}</span>
        </li>
        {
          // <li>
          //   <span>Data de upload:</span>
          //   <span>{format(value.created_at, 'DD/MM/YYYY HH:mm')}</span>
          // </li>
        }
      </ul>
    </div>
  )
}

function ItemDetailsStatus ({index: stateIndex, enableEdit}) {
  if (!enableEdit && !stateIndex) {
    // quando uma lâmina não foi revisada, mas o álbum
    // já está em outra versão
    stateIndex = 3
  }
  return (
    <span className={`status status--state${stateIndex}`}>
      {SHEET_STATES[stateIndex]}
    </span>
  )
}

function ItemActions ({value, openModal, enableEdit}) {
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <Action onClick={() => openModal(value, 'upload')}>
            Enviar nova versão
        </Action>
      </Menu.Item>
      <Menu.Item key="1">
        <Action onClick={() => openModal(value, 'delete')}>
          Excluir
        </Action>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <Action>
          Ver versões anteriores
        </Action>
      </Menu.Item> */}
    </Menu>
  )

  return (
    <div className="album-sheets-gallery__detailed__item__actions-dropdown">
      {value.state === 1 &&
      <button className="btn btn-sm btn-comments" onClick={() => openModal(value, 'comments')}>
        <i className="fa fa-bubble" />
        Ver comentários
      </button>}
      {enableEdit && (<div className="btn-group">
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <button className="btn btn-sm">
            Editar <i className="fa fa-angle-down" />
          </button>
        </Dropdown>
      </div>)}
    </div>
  )
}

function Action (props) {
  return (
    <a className="album-sheets-gallery__detailed__item__action" {...props} />
  )
}

DetailedGallery.propTypes = {
  isSortable: PropTypes.bool,
  items: PropTypes.array,
  onMoveItem: PropTypes.func
}

export default DetailedGallery
