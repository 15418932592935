import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import range from 'lodash/range'
import { Dropdown, Menu, Tooltip } from 'antd'

import Gallery from '~/widgets/react-grid-gallery/Gallery'
import Loading from '~/widgets/Loading'

class PicturesList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      pageKey: 'ps-pl-' + Math.random(),
      images: false,
      selectAllChecked: false,
      anySelected: false
    }
    this.allImagesSelected = this.allImagesSelected.bind(this)
    this.onSelectImage = this.onSelectImage.bind(this)
    this.onClickSelectAll = this.onClickSelectAll.bind(this)
    this.suggest = this.suggest.bind(this)
  }
  componentDidMount() {
    // TODO: Mover objetos de estilo para o css
    const captionStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      maxHeight: '240px',
      position: 'absolute',
      wordWrap: 'break-word',
      bottom: '0',
      width: '100%',
      color: 'white',
      padding: '2px 8px',
      fontSize: '90%',
      zIndex: '9'
    }
    const toolsStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: '0',
      right: '0px',
      color: 'white',
      padding: '5px 0',
      width: '30px',
      textAlign: 'center',
      fontSize: '16px',
      lineHeight: '16px',
      zIndex: '11',
    }
    const starStyle = {
      ...toolsStyle,
      top: '26px'
    }
    const processingStyle = {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      top: '0',
      right: '0',
      color: 'white',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      zIndex: '5'
    }

    this.setState({
      images: this.props.pictures ? this.props.pictures.map((p) => {
        const img = {
          id: p.id,
          src: p.image,
          size: p.file_size,
          thumbnail: p.thumb || p.image,
          thumbnailWidth: 600,//p.thumb ? 600 : p.original_width,
          thumbnailHeight: 600,//p.thumb ? 600 : p.original_height,
          caption: p.title,
          isSelected: false,
          thumbnailStyle: p.image_processing ? {
            filter: 'blur(2px)'
          } : false,
          thumbnailCaption: null,
          _thumbnailCaption: i => i.thumbnailCaption = (
            <div>
              <div style={toolsStyle} key={'delete-' + p.id}>
                <i className="fa fa-trash" style={{ cursor: 'pointer' }} onClick={() => this.props.deletePictures([p.id], [p.file_size])}></i>
              </div>
              <div style={starStyle} key={'suggest-' + p.id}>
                <Tooltip placement='bottom' title={p.suggested ? 'Desfazer sugestão' : 'Sugira a foto para o seu cliente'}>
                  <i className="fa fa-star" style={{ cursor: 'pointer', color: p.suggested ? '#f0d56f' : '#fff' }}
                    onClick={() => this.suggest(p)}>
                  </i>
                </Tooltip>
              </div>
              {
                !p.image_processing ? null :
                  <div style={processingStyle} key={'processing-' + p.id}>
                    <div style={{ position: 'absolute', top: '40%', width: '100%' }}>
                      <i className="fa fa-cog fa-spin fa-3x fa-fw"></i><span className="sr-only">Loading...</span>
                      <span style={{ display: 'block', clear: 'both', marginTop: '12px', fontSize: '11px' }}>
                        Processando foto
                    </span>
                    </div>
                  </div>
              }
            </div>
          ),
          customOverlay: (
            <div style={captionStyle}>{p.title}</div>
          )
        }
        img._thumbnailCaption(img)
        return img
      }) : []
    })
  }

  onClickSelectAll() {
    const selectAllChecked = !this.state.selectAllChecked
    const anySelected = !!selectAllChecked
    this.setState({
      images: this.state.images.map((i) => ({ ...i, isSelected: selectAllChecked })),
      selectAllChecked,
      anySelected,
      pageKey: 'ps-pl-' + Math.random(),
    })
  }

  allImagesSelected(images) {
    return images.filter((i) => i.isSelected).length === images.length
  }

  getSelected(images) {
    return images.filter((i) => i.isSelected).map((i) => i.id)
  }

  getSize(images) {
    return images.filter(image => image.isSelected).map(image => image.size)
  }

  onSelectImage(index/*, image*/) {
    const images = this.state.images.slice()
    const img = images[index]

    img.isSelected = img.hasOwnProperty('isSelected') ? !img.isSelected : true
    this.setState({ images })

    if (this.allImagesSelected(images)) {
      return this.setState({ anySelected: true, selectAllChecked: true })
    } else if (this.getSelected(images).length > 0) {
      return this.setState({ anySelected: true, selectAllChecked: false })
    }

    return this.setState({ anySelected: false, selectAllChecked: false })
  }

  suggest(p) {
    const images = this.state.images
    const img = images.find(i => i.id === p.id)
    if (!img) return

    img.suggested = p.suggested = !p.suggested
    this.props.suggest(p)
    img._thumbnailCaption(img)

    this.setState({ images })
  }

  render() {

    return (
      <div>
        {
          this.state.images.length > 0 ?
            <div className="portlet light">
              <div className="row">
                <div className="col-md-8">
                  <span>
                  </span>
                </div>
                <div
                  className="col-md-4"
                  style={{ textAlign: 'right' }}>
                  <button className="btn green" onClick={() => this.props.goToStep('review')}>
                    <i className="fa fa-check" /> Continuar
                </button>
                </div>
              </div>
            </div> : null
        }
        <div className="portlet light ">
          {
            this.state.images.length > 0 ?
              <div className="portlet-title">
                <Sort {...this.props} />
                <div className="caption" style={{ marginLeft: '15px' }}>
                  <button
                    type="button"
                    className={'btn green btn-outline ' + (this.state.selectAllChecked ? 'active' : '')}
                    onClick={this.onClickSelectAll}>
                    <i className="fa fa-check-circle" aria-hidden /> Selecionar todas
                  </button>
                </div>
                {
                  this.state.anySelected ?
                    <div className="caption" style={{ marginLeft: '15px' }}>
                      <button
                        type="button"
                        className={'btn red'}
                        onClick={() => this.props.deletePictures(this.getSelected(this.state.images), this.getSize(this.state.images))}>
                        <i className="fa fa-trash" aria-hidden /> Deletar fotos
                    </button>
                    </div>
                    :
                    <div className="caption" style={{ marginLeft: '8px' }}>
                      <button
                        type="button"
                        className={'btn blue btn-outline'}
                        onClick={() => this.props.batchSuggest()}>
                        <i className="fa fa-star" aria-hidden /> Sugerir em lote
                    </button>
                    </div>
                }
                <Pagination {...this.props} />
              </div> : <div className="container-fluid">
                <div className="portlet light text-center">

                  <div className="clearfix"></div>
                  <i className="fa fa-star-half-o fa-4x margin-top-40"></i>
                  <h3>Esta seleção está vazia!</h3>
                </div>
              </div>
          }
          <div className="portlet-body form">
            {!(this.state.images) ?
              <Loading /> :
              <div style={{
                display: 'table',
                minHeight: '1px',
                width: '100%',
                border: '',
                overflowY: 'auto',
                overflow: 'auto',
              }}>
                <Gallery
                  key={this.state.pageKey}
                  images={this.state.images}
                  enableLightbox={true}
                  enableImageSelection={true}
                  onSelectImage={this.onSelectImage}
                />
              </div>}
          </div>
        </div>
      </div>
    )
  }
}

PicturesList.propTypes = {
  batchSuggest: PropTypes.func,
  deletePictures: PropTypes.func,
  goToStep: PropTypes.func,
  pictures: PropTypes.array,
  suggest: PropTypes.func,
  title: PropTypes.string,
}


function Sort(props) {
  const sorts = [
    { id: 7, name: 'Numeração da foto [0-9]' },
    { id: 8, name: 'Numeração da foto [9-0]' },
    { id: 3, name: 'Nome do arquivo [A-Z]' },
    { id: 4, name: 'Nome do arquivo [Z-A]' },
    { id: 5, name: 'Data de criação da foto [A-Z]' },
    { id: 6, name: 'Data de criação da foto [Z-A]' },
    { id: 1, name: 'Data de envio [A-Z]' },
    { id: 2, name: 'Data de envio [Z-A]' },
  ]

  const menu = (
    <Menu>
      {sorts.map((s, k) => [
        k > 0 && k % 2 === 0 && k != sorts.length - 1 ? <Menu.Divider key={'divider-' + s.id} /> : null,
        <Menu.Item key={'sort-' + s.id}>
          <a onClick={() => props.changeSort(s.id)}>{s.name}</a>
        </Menu.Item>
      ])}
    </Menu>
  )

  return (<div className="caption">
    <div className="btn-group">
      <button type="button" disabled style={{ cursor: 'default' }} className="btn btn-default">
        <strong>Ordenação:</strong> {sorts.filter(s => s.id === props.currentSort)[0].name}
      </button>

      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <button type="button" className="btn btn-default">
          <i className="fa fa-angle-down" />
        </button>
      </Dropdown>
    </div>
  </div>)
}

Sort.propTypes = {
  changeSort: PropTypes.func,
  currentSort: PropTypes.any
}


function Pagination(props) {
  const isOnFirst = props.currentPage === 1
  const isOnLast = props.pages === props.currentPage
  // let pgs = range(1, Math.min(props.pages, 4) + 1)
  // if (props.pages > 4) {
  //   const toend = props.pages - props.currentPage
  //   let first = toend < 4 ? props.currentPage - 4 + toend : props.currentPage - 2
  //   first = first < 1 ? 1 : first
  //   pgs = range(first, Math.min(props.pages, first + 4, props.currentPage + toend) + 1)
  // }

  return (<div className="actions">
    <ul className="pagination bootpag pagination-fotos-colecao">
      <li className="disabled">
        <a>
          {isOnFirst ? '1' : (props.currentPage - 1) * props.pageSize} {' a '}
          {isOnFirst && isOnLast || isOnLast ? props.picturesCount :
            ((props.currentPage - 1) * props.pageSize) + props.pageSize}  {' de '}
          {props.picturesCount}
        </a>
      </li>
      <li className={'prev ' + (isOnFirst ? 'disabled' : '')}>
        <a onClick={!isOnFirst ? () => props.changePage(props.currentPage - 1) : undefined}>
          <i className="fa fa-angle-left" />
        </a>
      </li>
      {/*pgs.map((page) => {
        const disabled = props.currentPage === page
        return (
          <li
            key={'pg-' + page}
            className={disabled ? 'disabled' : ''}
            onClick={!disabled ? () => props.changePage(page) : undefined}>
            <a>{page}</a>
          </li>
        )
      })*/}
      <li className={'next ' + (isOnLast ? 'disabled' : '')}>
        <a onClick={!isOnLast ? () => props.changePage(props.currentPage + 1) : undefined}>
          <i className="fa fa-angle-right" />
        </a>
      </li>
    </ul>
  </div>)
}

Pagination.propTypes = {
  currentPage: PropTypes.number,
  changePage: PropTypes.func,
  pages: PropTypes.number,
  pageSize: PropTypes.number,
  picturesCount: PropTypes.number
}

export default PicturesList
