import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import {
  Form, Input, Tooltip, Icon, Cascader, Select, Row, Col, Checkbox, Button, AutoComplete,
} from 'antd';

import Loading from '~/widgets/Loading'
import Api from '~/modules/Api'

const { Option } = Select;
const AutoCompleteOption = AutoComplete.Option;


class CadForm extends Component {

  state = {
    number: '',
    email: '',
    pass: '',
    name: '',
    helpEmail: '',
    validateStatusEmail: '',
    buttonState: true,
    stateError: ''
  };

  inputValues(e, state) {
    state === 'number' ? this.setState({ [state]: this.maskPhone(e.target.value) }) :
      state === 'email' ? this.validateEmail(e.target.value) :
        this.setState({
          [state]: e.target.value
        })
  }

  validateEmail(email) {
    this.setState({ email: email })
    var re = /\S+@\S+\.\S+/;
    re.test(email) ? this.setState({ helpEmail: '', validateStatusEmail: '', buttonState: false }) : this.setState({ helpEmail: 'E-mail inválido', validateStatusEmail: 'error', buttonState: true })
  }

  maskPhone(v) {
    let r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 11) {
      // 12+ digitos. Formata como +55 (31) 9999-9999
      r = r.replace(/^(\d{2})(\d\d)(\d{5})(\d{4}).*/, "+$1 ($2) $3-$4");
    }
    else if (r.length > 5) {
      // 6...11 digitos. Formata como (31) 9999-9999
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
    }
    else if (r.length > 2) {
      // 3...5 digitos. Formata como (31) 9999
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    }
    else {
      // 0...2 digitos. Apenas adiciona o primeiro parêntese
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const result = await Api.singUpClient({
      name: this.state.name,
      tel: this.state.number,
      email: this.state.email,
      password: this.state.pass,
    })

    //console.log(result)

    if (result.success) {
      this.props.reload()
      return this.props.close()
    }

    if (result.error === 'CLIENT_EXISTS') {
      return this.setState({ validateStatusEmail: 'error', helpEmail: 'Cliente já cadastrado!' })
    }

    if (result.error === 'INVALID_PASSWORD') {
      return this.setActionValidation('signup', {
        password: { validateStatus: 'error', help: 'Senha muito curta! A senha deve ter ao menos 4 caracteres!' },
      })
    }

    if (result.error === 'INVALID_NAME') {
      return this.setActionValidation('signup', {
        name: { validateStatus: 'error', help: 'É necessário informar o seu nome!' },
      })
    }

  }

  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };
    const formItemLayout2 = {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      className: 'ps-ant-formItem'
    }
    return (

    <Form onSubmit={this.handleSubmit}> { /* {...formItemLayout}*/ }
        <Form.Item
          label="Nome"
          {...formItemLayout2}
        >
          <Input onChange={(e) => this.inputValues(e, 'name')} value={this.state.name} />
        </Form.Item>
        <Form.Item
          label="Número"
          {...formItemLayout2}
        >
          <Input onChange={(e) => this.inputValues(e, 'number')} value={this.state.number} />
        </Form.Item>
        <Form.Item
          label="E-mail"
          {...formItemLayout2}
          help={this.state.helpEmail} validateStatus={this.state.validateStatusEmail}
        >
          <Input onChange={(e) => this.inputValues(e, 'email')} value={this.state.email} />
        </Form.Item>
        <Form.Item
          label="Senha"
          {...formItemLayout2}
        >
          <Input type="password" onChange={(e) => this.inputValues(e, 'pass')} value={this.state.pass} />
        </Form.Item>
        <div style={{width: "100%", height: 50, display: "flex", flexDirection: "row-reverse"}}>
            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" style={{}}>Cadastrar</Button>
            </Form.Item>
        </div>
      </Form>

    )
  }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(CadForm);

export default WrappedRegistrationForm