import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Auth from '~/modules/Auth'
import CustomerService from '~/modules/CustomerService'

import ContactButton from '~/widgets/ContactButton'
import * as ExitPopup from '~/widgets/ExitPopup'

import Sidebar from './Sidebar'
import TopHeader from './TopHeader'
import Helpers from './Helpers'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-switch/css/bootstrap-switch.min.css'
import 'styles/metronic/layout.min.css'
import 'styles/metronic/blue.min.css'
import 'styles/metronic/custom.min.css'

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      style: {}
    }
    this.updateHeight = this.updateHeight.bind(this)
  }

  componentDidMount() {
    checkPermission(this.props.location.pathname)
    document.body.classList.remove('login')
    this.updateHeight()
    window.addEventListener('resize', this.updateHeight)
    // Helpers.enableSidebarToggler()
    document.body.classList.add('page-header-fixed')
    document.body.classList.add('page-sidebar-closed-hide-logo')
    document.body.classList.add('page-container-bg-solid')
    document.body.classList.add('page-sidebar-closed')
    this.addGTMMetaTags()
    this.trapExitPopup()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateHeight)
  }

  updateHeight() {
    this.setState({
      style: {
        minHeight: Helpers.viewport.height - 68
      }
    })
  }

  render() {
    return (
      <div>
        <div className="page-header navbar navbar-fixed-top">
          <TopHeader />
        </div>
        <div className="clearfix" />
        <div className="page-container">
          <Sidebar location={this.props.location} />
          <div className="page-content-wrapper">
            <div className="page-content" style={this.state.style}>
              {this.props.children}
            </div>
          </div>
        </div>
        <ContactButton />
      </div>
    )
  }

  addGTMMetaTags() {
    if (!document.querySelector('meta[name="auth-method"]')) {
      const m = document.createElement('meta')
      m.name = 'auth-method'
      m.content = Auth.isPersonificated ? 'admin-cp' : 'login'
      document.head.appendChild(m)
    }
    if (!document.querySelector('meta[name="freemium-subscription"]')) {
      const m = document.createElement('meta')
      m.name = 'freemium-subscription'
      m.content = Auth.isFreemium
      document.head.appendChild(m)
    }
    if (!document.querySelector('meta[name="first-access"]')) {
      const firstAccess = Auth.userData && Auth.userData.firstAccess
      const m = document.createElement('meta')
      m.name = 'first-access'
      m.content = firstAccess || false
      document.head.appendChild(m)
    }
  }

  trapExitPopup() {
    if (screen.width < 768) {
      return
    }
    CustomerService.stats.subscribe(s => {
      if (s.collectionInfo.count < 1 && s.galleryInfo.count < 1 && Auth.isFreemium) {
        ExitPopup.trap({
          createGal: () => this.props.router.push('/gallery'),
          createSel: () => this.props.router.push('/collection/new'),
        })
      }
    })
  }
}

function checkPermission(path) {
  const { subscription } = Auth.userData
  if (!subscription) return
  if (!path.includes('/album/')) return
  if (subscription.limits && !subscription.limits.rev) {
    window.location = '/'
  }
}

Main.propTypes = {
  router: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.element
}

export default Main
