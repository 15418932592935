import { Subject } from 'rxjs'
import sum from 'lodash/sum'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import ReactGA from 'react-ga'

import Api from 'app/modules/Api'

const CHUNK_SIZE = 6

class UploadService {
  originalTitle
  state = {
    files: [],
    progress: {},
    uploading: false
  }
  $state = new Subject()

  constructor() {
    this.originalTitle = document.title
  }

  setState(state) {
    this.state = { ...this.state, ...state }
    this.$state.next(this.state)
  }

  async canUpload(pictures, gallery) {
    const space = await this.checkSpace(pictures, gallery)
    if (!space.hasEnough) {
      return Promise.reject({ error: 'NotEnoughLimitsError', space })
    }

    if (space.overcome) {
      return Promise.reject({ error: 'NotEnoughSpaceError', space })
    }

    return true
  }

  async startUpload(files, id) {
    if (!Array.isArray(files) || !files.length) {
      return
    }

    let totalBytes = sum(map(files, ({ size }) => size))
    let sentBytes = {}

    const mainProgress = { total: files.length, totalBytes: formatBytes(totalBytes, 2), progress: 0, sent: 0, sentBytes: 0, pictures_size: totalBytes, allChunksSent: false }
    this.updateProgress(mainProgress)
    this.setState({ files })

    ReactGA.event({
      category: 'Seleção',
      action: 'Upload de fotos iniciado',
      value: files.length
    })

    const chunks = chunk(files, CHUNK_SIZE)
    let succ = 0, fail = 0
    for (let chunkIndex in chunks) {
      let chunk = chunks[chunkIndex]
      const chunkPics = chunk.map(async (picture, picIndex) => {
        const res = await Api.upload('/collection/pictures', { id, setCover: picture.setCover }, [picture], (bytes) => {
          sentBytes[chunkIndex + '-' + picIndex] = bytes
          mainProgress.sentBytes = sum(map(sentBytes, (sent) => sent))
          mainProgress.progress = mainProgress.sentBytes / totalBytes
          document.title = `(${parseInt(mainProgress.progress * 100)}%) ${this.originalTitle}`
          mainProgress.sentBytes = formatBytes(mainProgress.sentBytes, 2)
          return this.updateProgress(mainProgress)
        })
        mainProgress.sent = mainProgress.sent + 1
        return res
      })
      const results = await Promise.all(chunkPics)
      const [s, f] = results
        .filter(r => typeof r === 'object')
        .reduce((p, c) => [p[0] + (c.success || 0), p[1] + (c.fail || 0)], [0, 0])
      succ += s
      fail += f
      mainProgress.done = mainProgress.sent
      this.updateProgress(mainProgress)
    }
    document.title = this.originalTitle.toString()
    this.updateProgress({ allChunksSent: true })

    ReactGA.event({
      category: 'Seleção',
      action: 'Upload de fotos finalizado',
      value: files.length
    })

    Api.get(`/collection/info?collection_id=${id}`)
    Api.post('/collection/patch/increment', { 
      collection_type: 'gallery', 
      pictures_count: this.state.progress.total,
      pictures_size: this.state.progress.pictures_size/1024/1024
    })

    this.setState({
      uploading: false,
      progress: {},
      files: []
    })
        
    return { succ, fail }
  }

  updateProgress(progress) {
    progress = Object.assign(this.state.progress, progress)
    this.setState({
      uploading: (progress.total !== progress.sent) || (progress.total > 0 && !progress.allChunksSent),
      progress
    })
  }

  async checkSpace(files, gallery) {
    const requested = files.length
    const stats = await Api.get('/customer/stats')

    const limitValue = +stats.subscription.limits.col_max_picture_size
    const max_picture_size = limitValue * 1048576

    const invalidFiles = files.filter(f => f.size > max_picture_size)
    const overcome = !!invalidFiles.length

    const limit = (+stats.subscription.limits.col_max_pictures) || 100000000

    const used = +stats.usage.sel_pictures
    const available = (limit - used) > 0 ? limit - used : 0

    if (overcome) {
      const params = {
        service: 'gallery',
        limitKey: 'col_max_picture_size',
        limitValue,
        moreInfo: {
          gallery_id: gallery.id,
          fileSizes: invalidFiles.map(i => (i.size / 1048576))
        }
      }
      Api.post('/account/logReachedLimit', params, true).catch(e => console.warn(e))
    }

    return {
      requested,
      max_picture_size,
      max_picture_dimension: +stats.subscription.limits.col_max_picture_dimension,
      limit,
      used,
      available,
      hasEnough: available >= requested,
      overcome,
      invalidFiles
    }
  }

  get isUploading() {
    return this.state && this.state.uploading
  }

}

export default new UploadService()

function formatBytes(bytes, decimals) {
  if (bytes == 0) return '0 Bytes'
  let k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
