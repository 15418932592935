import { Subject } from 'rxjs'

// import GalleryService from './GalleryService'

function WS() {
  const _this = this
  let con
  let last = 0
  const $events = new Subject()

  this.events = $events.asObservable()

  // eslint-disable-next-line no-func-assign
  check = check.bind(this)

  this.open = function (customer_id) {
    if (!process.env.WSS_URL) {
      return
    }

    if (con && [con.OPEN, con.CONNECTING, con.CLOSING].indexOf(con.readyState) !== -1) {
      return
    }

    // console.log('conecting...')
    con = new WebSocket(process.env.WSS_URL)

    con.onopen = () => {
      last = Date.now()
      send(JSON.stringify({ cmd: 'con', page: 'GalleryPage', customer_id }))
      check(customer_id)
      // console.log('conected!')
    }

    con.onerror = function (error) {
      if (con.readyState !== con.OPEN) {
        setTimeout(() => _this.open(customer_id), 5e3)
      }
      console.warn('WebSocketError ', error)
    }

    con.onmessage = e => {
      last = Date.now()

      // Pong
      if (e.data.length === 2 && +e.data === 0xa) {
        // console.timeEnd('ping')
        return
      }

      try {
        handler(JSON.parse(e.data))
      }
      catch (ex) {
        console.warn('WSOnMessageError', ex, e)
      }
    }

    con.onclose = e => {
      setTimeout(() => _this.open(customer_id), 5e3)
      console.warn('close', e)
    }
  }

  this.close = function () {
    if (!con) {
      return
    }
    con.close()
  }

  function handler(pack) {
    if (!pack || !pack.cmd) {
      console.warn('WSUnexpectedData', pack)
      return
    }

    switch (pack.cmd) {
      case 'PictureReady':
        // eslint-disable-next-line no-case-declarations
        const { collection_id, picture_id } = pack
        $events.next({ type: 'PictureReady', collection_id, picture_id })
        break
      case 'CoverReady':
        $events.next({ type: 'CoverReady', collection_id: pack.collection_id })
        break
      default: console.warn('WSUnhandledCmd', pack)
    }
  }

  function check(customer_id) {
    if (con.readyState === con.OPEN && !last || Date.now() - last > 3e4) {
      ping()
    }

    setTimeout(() => check(customer_id), 3e3)
  }

  function ping() {
    // console.time('ping')
    send(0x9)
  }

  function send(pack) {
    if (con && con.readyState === con.OPEN) {
      con.send(pack)
    }
    else {
      console.warn('Defer WS pack send')
      setTimeout(send.bind(this, pack), 1e3)
    }
  }
}

export default new WS()
