import PropTypes from 'prop-types'
import {Tooltip} from 'antd'

function ReviewersBox ({children, openReviewerModal}) {
  return (
    <div className="col-md-12">
      <div className="portlet light" style={{maxWidth: '1380px', margin: 'auto'}}>
        <div className="portlet-title" id="album-reviewers-box">
          <div className="caption">
            <i className="fa fa-eye font-dark" />
            <span className="caption-subject font-dark sbold uppercase">
              Clientes
            </span>
            <span className="caption-subject" style={{display: 'inline-block', marginLeft: '5px'}}>
              <Tooltip title="Ao adicionar clientes como revisores, eles receberão um link exclusivo para poder aprovar ou adicionar solicitações de alteração em cada uma das lâminas do álbum. Você poderá acompanhar o estado da revisão por este painel.">
              <i className="fa fa-question-circle" />
              </Tooltip>
            </span>
          </div>
          <div className="tools">
            <div className="actions">
            <span className="btn btn-primary green" onClick={() => openReviewerModal(false, true)}>
              <i className="fa fa-user-plus"></i> Enviar para um cliente
            </span>
           </div>
          </div>
        </div>
        <div className="portlet-body row">
          {children}
        </div>
      </div>
    </div>
  )
}

ReviewersBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ]),
  openReviewerModal: PropTypes.func
}

export default ReviewersBox
