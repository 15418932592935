import React from 'react'
import PropTypes from 'prop-types'

import {
  List,
  Button,
  Tag,
} from 'antd'

import SelectionItem from './SelectionItem'
import SelectionLink from './SelectionLink'
import SelectionModal from './SelectionModal'
import GenericLinkModal from './GenericLinkModal'

import './style.scss'

export class SelectionList extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      showSelectionModal: false,
      selectionModalType: 'new',
      currentSelectionData: false,
      currentSelectionId: null,

      showGenericLinkModal: false,
      currentGenericLinkData: false,
      currentGenericLinkId: false
    }

    this.showSelectionModal = this.showSelectionModal.bind(this)
  }

  componentDidMount() {
    if (this.props.location.query && this.props.location.query.modal) {
      if (this.props.location.query.modal === 'send') {
        this.showSelectionModal()
      }
    }
  }

  showSelectionModal(selectionModalType = 'new', data = null) {
    return this.setState({
      showSelectionModal: true,
      currentSelectionData: data && { ...data },
      currentSelectionId: parseInt((data || {}).id),
      selectionModalType
    })
  }

  showGenericLinkModal(data = null) {
    return this.setState({
      showGenericLinkModal: true,
      currentGenericLinkData: data && { ...data },
      currentGenericLinkId: parseInt((data || {}).id)
    })
  }

  hideSelectionModal() {
    return this.setState({
      showSelectionModal: false,
      currentSelectionData: false,
      currentSelectionId: null
    })
  }

  hideGenericLinkModal() {
    return this.setState({
      showGenericLinkModal: false,
      currentGenericLinkData: false,
      currentGenericLinkId: null
    })
  }

  execAction(action, item) {
    switch (action) {
      case 'edit':
        this.showSelectionModal('edit', item)
        break
      case 'resend':
        this.showSelectionModal('resend', item)
        break
      case 'reopen':
        this.props.reopen(item.id)
        break
      case 'delete':
        this.props.deleteSelection(item.id)
        break
      case 'whatsapp':
        this.props.sendWhatsapp(item.hash, item.extradata)
        break
      case 'getPDF':
        this.props.getPDF(item.hash)
        break
    }
  }

  execLinkAction(action, item) {
    switch (action) {
      case 'edit':
        this.showGenericLinkModal(item)
        break
      case 'delete':
        this.props.deleteGenericLink(item.id)
        break
    }
  }

  render () {
    if (!this.props.selectionList) return null
    
    const hasGenericLinks =
      this.props.genericLinks && !!this.props.genericLinks.length
    const hasSelections =
      this.props.selectionList && !!this.props.selectionList.length
    const isFirst = !hasGenericLinks && !hasSelections

    const NewGenericLinkButton = (
      <Button
        type="primary"
        style={{ width: 'auto', margin: '14px auto', display: 'block' }}
        icon="link"
        onClick={() => this.showGenericLinkModal()}
      >
        {'Gerar link para enviar'}
      </Button>
    )

    const NewSelectionButton = (
      <Button
        type="primary"
        style={{ width: 'auto', margin: '14px auto', display: 'block' }}
        icon="mail"
        onClick={() => this.showSelectionModal()}
      >
        {hasSelections ? 'Enviar para outro cliente' : 'Cadastrar e enviar'}
      </Button>
    )

    return (
      <div className="selection-list">
        {isFirst && (
          <div className="portlet light ">
          <div className="container-fluid">
            <div className="portlet light text-center">
              <div className="clearfix" />
              <i className="fa fa-send fa-4x margin-top-40" aria-hidden="true" />
              <h3>Como você deseja enviar?</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-11 col-md-offset-1">
              <div className="col-md-5">
                <div className="note note-warning" style={{ lineHeight: '20px', textAlign: 'center', borderLeft: '0px solid #eee', padding: '0px 0px 0px 0px' }}>
                  <div>
                    <a onClick={() => this.showSelectionModal()} className="btn yellow-crusta tooltips" style={{ width: '90%', lineHeight: '50px', marginTop: '15px' }}>
                      <i className="fa fa-envelope" /> Cadastrar e enviar
                    </a>
                  </div>
                  <div style={{ padding: '15px' }}>
                    <h4 className="block" style={{ fontWeight: 'bold', color: '#a78500' }}>Você cadastra os clientes</h4>
                    <p> Ideal para você que já sabe o contato de todos os clientes que farão a seleção. Indicado para ocasiões privativas, como por exemplo: Casamentos, Newborn, Gestantes, etc.</p>
                    <p style={{ marginTop: '15px' }}> Você fará o cadastro dos clientes que poderão fazer a seleção, logo eles serão notificados por e-mail para que possam iniciar a escolha.<br /><br /></p>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="note note-success" style={{ lineHeight: '20px', textAlign: 'center', borderLeft: '0px solid #eee', padding: '0px 0px 0px 0px' }}>
                  <div>
                    <a onClick={() => this.showGenericLinkModal()} className="btn green tooltips" style={{ width: '90%', lineHeight: '50px', marginTop: '15px' }}>
                      <i className="fa fa-link" /> Gerar link e enviar
                    </a>
                  </div>
                  <div style={{ padding: 15 }}>
                    <h4 className="block" style={{ fontWeight: 'bold', color: '#1e7982' }}>O próprio cliente se cadastra <Tag color="orange">Novo!</Tag></h4>
                    <p> Ideal para você que ainda não sabe quais clientes irão selecionar. Indicado para eventos como: Formaturas, Apresentações, Eventos Esportivos, etc.</p>
                    <p style={{ marginTop: 15 }}> Será gerado um link para você compartilhar com os clientes, em um grupo de Whatsapp por exemplo. Através dele, o próprio cliente se cadastra e inicia a seleção.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
        {!isFirst && (
          <div>
            {hasGenericLinks && (
              <List
                loading={this.props.isLoading}
                itemLayout="horizontal"
                dataSource={[...this.props.genericLinks]}
                rowKey={item => item.hash.concat(item.updated_at)}
                renderItem={link =>
                  SelectionLink(this.execLinkAction.bind(this), link)
                }
              />
            )}
            {hasSelections && (
              <List
                loading={this.props.isLoading}
                itemLayout="horizontal"
                dataSource={[...this.props.selectionList]}
                rowKey={item => item.hash.concat(item.updated_at)}
                renderItem={(item) =>
                  SelectionItem(this.execAction.bind(this), item)
                }
              />
            )}
            {hasGenericLinks && !hasSelections && (
              <div className="portlet light ">
                <div className="container-fluid">
                  <div className="portlet light text-center">
                    <div className="clearfix" />
                    <i
                      className="fa fa-users fa-4x margin-top-40"
                      aria-hidden="true"
                    />
                    <h3>Clientes</h3>
                    <h4 className="margin-bottom-40">
                      Os clientes que se cadastrarem para fazer a seleção serão
                      listados abaixo!
                    </h4>
                  </div>
                </div>
                <div className="portlet-body form">
                  <div
                    style={{
                      display: 'table',
                      minHeight: '1px',
                      width: '100%',
                      overflow: 'auto'
                    }}
                  >
                    <div id="ReactGridGallery" className="ReactGridGallery" />
                  </div>
                </div>
              </div>
            )}
            {!hasGenericLinks && NewSelectionButton}
          </div>
        )}
        {this.state.showSelectionModal && (
          <SelectionModal
            postData={this.props.postSelectionData}
            hide={this.hideSelectionModal.bind(this)}
            type={this.state.selectionModalType}
            data={this.state.currentSelectionData}
            collectionId={this.props.collectionId}
          />
        )}
        {this.state.showGenericLinkModal && (
          <GenericLinkModal
            postData={this.props.postGenericLinkData}
            hide={this.hideGenericLinkModal.bind(this)}
            data={this.state.currentGenericLinkData}
            collectionId={this.props.collectionId}
          />
        )}
      </div>
    )
  }
}

SelectionList.propTypes = {
  title: PropTypes.string
}

export default SelectionList
