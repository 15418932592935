import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'

import UploadButtonModal from '../../../Upload/ButtonModal'
import UploadProvider from '../../../Upload/Provider'

function UploadNew ({data}) {
  const {sheet, reviewers, updateGallery} = data
  const uploadParams = {
    fileCategory: 'sheet',
    uploadPath: `/revise/sheet/${sheet.id}`,
    uploadVariables: {
      album_id: sheet.rev_album_id
    },
    statsKeys: {
      file_maxsize: 'rev_file_maxsize',
      space: 'rev_space'
    }
  }
  function onFinish () {
    setTimeout(() => {
      updateGallery()
      swal.close()
    }, 450)
  }
  return (
    <div className="sheets-modal__upload">
      <UploadProvider params={uploadParams} currentFiles={[]} onFinish={onFinish}>
        <UploadButtonModal />
      </UploadProvider>
    </div>
  )
}

UploadNew.propTypes = {
  data: PropTypes.any
}

export default UploadNew
