import React from 'react'
import PropTypes from 'prop-types'

import {Tooltip} from 'antd'

function LabelHint (props) {
  return (
    <Tooltip title={props.children} overlayStyle={{zIndex: 999900}}>
      <i className="fa fa-question-circle" />
    </Tooltip>
  )
}

LabelHint.propTypes = {
  children: PropTypes.string.isRequired,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  title: PropTypes.string
}

LabelHint.defaultProps = {
  title: '',
  trigger: 'hover',
  placement: 'right'
}

export default LabelHint
