import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown } from 'antd'

Sort.propTypes = {
  currentSort: PropTypes.func,
  changeSort: PropTypes.func,
}

export default function Sort({ currentSort, changeSort }) {
  const sorts = [
    { id: 7, name: 'Numeração da foto [0-9]' },
    { id: 8, name: 'Numeração da foto [9-0]' },
    { id: 3, name: 'Nome do arquivo [A-Z]' },
    { id: 4, name: 'Nome do arquivo [Z-A]' },
    { id: 5, name: 'Data de captura da foto [A-Z]' },
    { id: 6, name: 'Data de captura da foto [Z-A]' },
    { id: 1, name: 'Data do upload [A-Z]' },
    { id: 2, name: 'Data do upload [Z-A]' },
  ]

  const menu = (
    <Menu>
      {sorts.map((s, k) => [
        k > 0 && k % 2 === 0 && k != sorts.length - 1 ? <Menu.Divider key={'divider-' + s.id} /> : null,
        <Menu.Item key={'sort-' + s.id}>
          <a onClick={() => changeSort(s.id)}>{s.name}</a>
        </Menu.Item>
      ])}
    </Menu>
  )

  return (
    <div className="collection-sort btn-group">
      <button type="button" disabled style={{ cursor: 'default' }} className="btn btn-default">
        <strong>Ordenação:</strong> {(sorts.find(s => s.id === currentSort) || {}).name}
      </button>

      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <button type="button" className="btn btn-default">
          <i className="fa fa-angle-down" />
        </button>
      </Dropdown>
    </div>
  )
}
