import React, { Component } from 'react'

export default class WizardBg extends Component {
  draw = null
  eLT = null
  eRT = null
  eRB = null
  eLB = null
  style = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    zIndex: 0
  }

  constructor(props, context) {
    super(props, context)
    this.mount = this.mount.bind(this)
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    this.draw = SVG('target').size('100%', '100%')

    this.mount()
    this.update()

    window.addEventListener('resize', this.update)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.update)
  }

  mount() {
    const color = '#f7f7f7'

    this.eLT = this.draw
      .rect(30, 10)
      .fill({ opacity: 0 })
      .stroke({ color, width: 1 })

    this.eRT = this.draw.path('m 0,0 h 20 0 v 0 10 h -10 0 v 0 10 h -10 0 z').fill({ color })

    this.eRB = this.draw.rect(10, 20).fill({ color })

    this.eLB = this.draw
      .rect(15, 10)
      .fill({ opacity: 0 })
      .stroke({ color, width: 1 })
  }

  update() {
    const target = document.getElementById('target')

    if (!target) {
      return
    }

    const targetWidth = target.clientWidth
    const targetHeight = target.clientHeight

    const factor =
      targetWidth * (targetWidth < 500 ? 0.18 : targetWidth < 700 ? 0.14 : targetHeight < 450 ? 0.1 : 0.12)
    const x1 = Math.round(factor * 1)
    const x1_5 = Math.round(factor * 1.5)
    const x2 = Math.round(factor * 2)
    const x3 = Math.round(factor * 3)

    this.eLT.size(x3, x1).move(-1, -1)
    this.eRT.size(x2, x2).translate(targetWidth - x2)
    this.eRB.size(x1, x2).move(`calc(100% - ${x2}px)`, `calc(100% - ${x2}px)`)
    this.eLB.size(x1_5, x1).move(-1, `calc(100% - ${x1 - 1}px)`)
  }

  render() {
    return <div id="target" style={this.style}></div>
  }
}
