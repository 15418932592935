import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Form, notification } from 'antd'
import swal from 'sweetalert'

import Api from 'app/modules/Api'

export default class SendEmail extends Component {

    constructor(props, context) {
        super(props, context)

        this.state = {
            clientName: '',
            clientEmail: '',
            clientMessage: '',
            isValidEmail: true,
            ...props.data
        }

        this.onChange = this.onChange.bind(this)
        this.validate = this.validate.bind(this)
        this.send = this.send.bind(this)
        props.$send.subscribe(this.send)
    }

    onChange(value, key) {
        if (!key) {
            key = value.target.name
            value = value.target.value
        }

        const newState = { [key]: value }

        if (key === 'clientEmail') {
            newState[key] = newState[key].trim()
            newState.isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        }

        this.setState(newState)
        this.validate({ ...this.state, ...newState })
    }

    validate(state) {
        let valid = state.isValidEmail && state.clientName && state.clientMessage
        this.setState({ valid })
        this.props.upState({ valid, clientName: state.clientName, clientMessage: state.clientMessage })
    }

    async send() {
        if (!this.state.valid) {
            return
        }

        const res = await Api.post('/gallery/share/email', {
            galleryLink: this.state.link,
            toEmails: [this.state.clientEmail],
            clientName: this.state.clientName,
            message: this.state.clientMessage
        }, true)

        if (res && res.success) {
            swal.close()
            notification.success({
                message: 'E-mail enviado!'
            })
        }
        else {
            console.warn(res)
        }
    }

    render() {
        const emailValidation = (
            this.state.clientEmail.length && !this.state.isValidEmail ? {
                help: 'E-mail inválido',
                validateStatus: 'error'
            } : {}
        )
        return (
            <Form layout="horizontal" onSubmit={this.props.onSubmit}>
                <FormItem label="Nome do cliente" key="clientName">
                    <Input
                        name="clientName"
                        value={this.state.clientName}
                        onChange={this.onChange}
                    />
                </FormItem>
                <FormItem label="E-mail do cliente" {...(emailValidation)} key="clientEmail">
                    <Input
                        name="clientEmail"
                        value={this.state.clientEmail}
                        onChange={this.onChange}
                    />
                </FormItem>
                <FormItem label="Observações" key="clientMessage">
                    <Input.TextArea
                        name="clientMessage"
                        value={this.state.clientMessage}
                        onChange={this.onChange}
                        autosize={{ minRows: 4, maxRows: 6 }}
                    />
                </FormItem>
            </Form>
        )
    }
}

const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 10 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 12 }
    },
    colon: false,
    className: 'ps-ant-formItem'
}

const FormItem = props => <Form.Item {...formItemLayout} {...props} />

SendEmail.propTypes = {
    data: PropTypes.object,
    upState: PropTypes.func,
    $send: PropTypes.object
}
