import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import format from 'date-fns/format'
import differenceInDays from 'date-fns/difference_in_days'
import swal from 'sweetalert'
import { Icon } from 'antd'

import Auth from '~/modules/Auth'

import SendWhatsappBox from '~/widgets/SendWhatsappBox'
import SendMailModal from '~/widgets/SendMailModal'

// import SelectionModal from './SelectionModal'

import './style.scss'

function fixedTime(isodate) {
  const time = 'T05:00:00.000Z'
  return isodate.split('T')[0] + time
}

const mainClassName = 'selection-link'

const StatusInfo = ({ name, value, icon, color }) => (
  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
    <div className={`dashboard-stat dashboard-stat-v2 ${color || 'green'} selection-link-status`} href="#">
      <div className="visual">
        {icon}
      </div>
      <div className="details">
        <div className="desc">
          {name}
        </div>
        <div className="number">
          <span>
          {value}
          </span>
        </div>
      </div>
    </div>
  </div>
)

const expirationStatus = limitDate => {
  if (!limitDate) return 'não determinado'
  const expDay = format(fixedTime(limitDate), 'DD/MM/YYYY')
  const todayDay = format(new Date().toISOString(), 'DD/MM/YYYY')
  const leftToExpire = differenceInDays(
    fixedTime(limitDate),
    fixedTime(new Date().toISOString())
  )
  const str =
    leftToExpire > 0
      ? leftToExpire === 1
        ? 'expira amanhã'
        : `expira em ${leftToExpire} dias`
      : leftToExpire === 0 || leftToExpire === -1
      ? expDay === todayDay
        ? 'expira hoje'
        : 'expirou ontem'
      : `expirou há ${leftToExpire * -1} dias`
  return `${expDay} (${str})`
}

class SelectionLink extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    execAction: PropTypes.func
  };

  constructor(props, context) {
    super(props, context)

    const sel = { ...this.props.item }

    this.state = {
      id: sel.id,
      hash: sel.hash,
      title: sel.title,
      selectedPictures: sel.selected_pictures,
      limitDate: sel.limit_date,
      allowDownload: sel.allow_copy,
      contractedPictures: sel.contracted_pictures,
      allowExtraPictures: sel.allow_extra_pictures,
      extraPicturePrice: sel.extra_picture_price,

      lastAccess: sel.last_access,
      createdAt: sel.created_at
    }

    this.sendWhatsapp = this.sendWhatsapp.bind(this)
  }

  async sendWhatsapp() {
    let wrapper = document.createElement('div')
    ReactDOM.render(
      <SendWhatsappBox hash={this.state.hash} extradata={{}} />,
      wrapper
    )

    await swal({
      content: wrapper,
      dangerMode: false,
      title: false,
      icon: false,
      buttons: false
    })
  }

  execAction = (action) => {
    return this.props.execAction(action, this.state)
  }

  get isExpired () {
    return this.state.limitDate && (differenceInDays(
      fixedTime(this.state.limitDate),
      fixedTime(new Date().toISOString())
    ) <= 0)
  }

  render () {
    const className = `${mainClassName} ${mainClassName}`
    const url = `${process.env.SELECTION_FRONT_URL}/${this.state.hash}`

    return (
      <div className={`portlet light ${className}`}>
        <div className="portlet-title">
          <div className="caption font-green-sharp">
            <Icon type="share-alt" className="font-green-sharp" />
            <span className="caption-subject sbold">
              Link para compartilhamento
            </span>
          </div>
          <div className="actions">
            <div className="btn-group">
              <a
                className="btn green-haze btn-outline btn-circle btn-sm"
                href="javascript:;"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
              >
                Ações <i className="fa fa-angle-down" />
              </a>
              <ul className="dropdown-menu pull-right">
                <li>
                  <a onClick={() => this.execAction('edit')}>
                    Editar configurações do link
                  </a>
                </li>
                {(Auth.isPersonificated || Auth.userData.admin) && (
                  <li>
                    <a onClick={() => this.execAction('delete')}>
                      Excluir link
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="portlet-body">
          <div className="row">
            <StatusInfo
              name="fotos contratadas"
              value={this.state.contractedPictures || 'não definido'}
              icon={<Icon type="picture" />}
            />
            <StatusInfo
              name="valor por foto adicional"
              value={(
                (this.state.allowExtraPictures && this.state.extraPicturePrice > 0)
                ? 'R$ ' + (this.state.extraPicturePrice / 100).toFixed(2).replace('.', ',')
                : 'não definido'
              )}
              icon={<Icon type="dollar" />}
            />
            <StatusInfo
              name="permite download"
              value={this.state.allowDownload ? 'sim' : 'não'}
              icon={<Icon type="cloud-download" />}
            />
            <StatusInfo
              name="prazo limite"
              value={(
                this.state.limitDate
                ? format(fixedTime(this.state.limitDate), 'DD/MM/YYYY')
                : 'não definido'
              )}
              color={this.isExpired ? 'red' : 'green'}
              icon={<Icon type="schedule" />}
            />
          </div>
          <div
            className="input-group"
            style={{ marginTop: '15px', marginBottom: '15px' }}
          >
            <input
              type="text"
              className="form-control"
              value={url}
              readOnly
              style={{
                fontSize: '24px',
                height: '50px',
                color: '#32c5d2',
                border: '1px solid #f7f7f7 !important',
                background: '#f7f7f7'
              }}
            />
            <div className="input-group-btn">
              <button
                type="button"
                className="btn green-jungle dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
                style={{
                  height: '50px'
                }}
              >
                Compartilhar <i className="fa fa-angle-down" />
              </button>
              <ul className="dropdown-menu pull-right">
                <li>
                  <a onClick={this.sendWhatsapp}> Whatsapp </a>
                </li>
                <li>
                  <SendMailModal
                    triggerElement={<a>E-mail</a>}
                    genericLink={url}
                    key="share-mail"
                    message={`As suas fotos já estão disponíveis!
                    \rSegue o link de acesso para escolher as que você mais gostar:`}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="alert alert-warning" style={{ marginBottom: '0px' }}>
            <strong>Compartilhe</strong> o link para que os seus clientes possam
            iniciar as seleções!
          </div>
        </div>
      </div>
    )
  }
}

export default function SelectionLinkComponent(execAction, item) {
  return <SelectionLink key="sel-item" execAction={execAction} item={item} />
}
