import React from 'react'
import Auth from 'app/modules/Auth'
import Form from 'app/widgets/Form'

function FormMessagePreview () {
  const {displayName: customer_name} = Auth.userData
  return (
    <Form.Consumer>
      {(form) => (
        <div className="portlet light ">
          <div className="portlet-title">
            <div className="caption">
              <i className="fa fa-envelope" aria-hidden="true" />
              <span className="caption-subject font-dark sbold uppercase">
                Mensagem que o cliente receberá
              </span>
            </div>
          </div>
          <div className="portlet-body form">
            <p>
              Olá, {
                form.inputs.name.length > 0 ? form.inputs.name : '[nome do cliente]'
              }! Tudo bem?
            </p>
            <p>
              {form.inputs.message}
            </p>
            <p>
              [botão com o link do álbum]
            </p>
            <p>
              Por favor, confirme o recebimento deste e-mail.
            </p>
            <p>Um abraço, <br />{customer_name}</p>
          </div>
        </div>
      )}
    </Form.Consumer>
  )
}

export default FormMessagePreview
