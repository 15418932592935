import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

function LogoUpload (props) {
  return (
    <div>
      <div className="portlet light" style={{border: '1px solid #EFEFEF'}}>
        <div className="portlet-body" style={{display: 'table', margin: 'auto', textAlign: 'center'}}>
          {props.currentLogo && props.currentLogo !== 'false' ? <img src={props.currentLogo} width="100%" />:
          (props.processingLogo ?
          <div style={{display: 'table-cell', verticalAlign: 'middle', height: '160px', textAlign: 'center'}}>
            <i className="fa fa-cog fa-spin fa-3x fa-fw"></i>
            <span className="sr-only">Loading...</span>
            <br/>Processando...
          </div>:
          <div style={{display: 'table-cell', verticalAlign: 'middle', height: '160px', textAlign: 'center'}}>
            <i className="fa fa-ban fa-3x" style={{lineHeight: '40px'}}></i>
            <br/>Sem {props.fileName}
          </div>)}
        </div>
      </div>
      {
        props.isUploading ?
        <div className="portlet light" style={{backgroundColor: '#32c5d2'}}>
          <div
            className="portlet-body form"
            style={{display: 'table', margin: 'auto', color: '#FFFFFF'}}>
            <div style={{display: 'table-cell', verticalAlign: 'middle', height: 160, textAlign: 'center'}}>
              <i
                className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                style={{lineHeight: '50px'}} />
                <br />
                <br />
                Enviando {props.fileName}
            </div>
            </div>
          </div>
        : <Dropzone
          onDrop={props.onDrop}
          multiple={false}
          accept="image/jpeg, image/png"
          style={{cursor: 'pointer'}}>
          <div className="portlet light" style={{backgroundColor: '#26C281'}}>
            <div
              className="portlet-body form"
              style={{display: 'table', margin: 'auto', color: '#FFFFFF'}}>
              <div style={{display: 'table-cell', verticalAlign: 'middle', height: 160, textAlign: 'center'}}>
                <i
                  className="fa fa-cloud-upload fa-3x"
                  style={{lineHeight: '50px'}} />
                <br />
                Enviar {props.fileName}
              </div>
            </div>
          </div>
        </Dropzone>
      }
      {props.children}
    </div>
  )
}

LogoUpload.propTypes = {
  fileName: PropTypes.string
}

LogoUpload.defaultProps = {
  fileName: 'logotipo'
}


export default LogoUpload
