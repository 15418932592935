import React from 'react'
import PropTypes from 'prop-types'
import { Modal, notification } from 'antd'
import Auth from '~/modules/Auth'

import ModalForm from './Form'

import './style.scss'

const defaultData = {
    loading: false,

    id: null,
    title: '',
    limitDate: undefined,
    allowDownload: false,
    contractedPictures: 0,
    allowExtraPictures: false,
    extraPicturePrice: 0,
}

class GenericLinkModal extends React.PureComponent {
  static propTypes = {
    data: PropTypes.any,
    collectionId: PropTypes.any,
    hide: PropTypes.func,
    postData: PropTypes.func,
    type: PropTypes.string,
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      ...defaultData,
      ...this.props.data,
      visible: true
    }

    this.close = () => {
      this.setState({ ...defaultData, visible: false })
      setTimeout(() => this.props.hide(), 250)
    }
  }

  onCancel () {
    this.close()
  }

  async onOk () {
    this.setState({ loading: true })

    await this.props.postData({
      collection_id: this.props.collectionId,
      ...this.state,
    })

    if (!this.state.id) {
      notification.success({ message: 'Seleção adicionada!' })
    } else if (this.props.type === 'resend') {
      notification.success({ message: 'Seleção reenviada!' })
    } else {
      notification.success({ message: 'Dados atualizados!' })
    }

    this.close()
  }

  onChange (value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }

    const newState = { [key]: value }

    this.setState(newState)
  }

  render () {

    return (
    <Modal {...(this.getModalSettings())}>
      <ModalForm
            modalType={this.props.type}
            collectionId={this.props.collectionId}
            data={this.state}
            onChange={this.onChange.bind(this)}
          />
    </Modal>
    )
  }

  getModalSettings () {
    const settings = {
      visible: this.state.visible,
      title: 'Link Público',
      okText: 'Salvar',
      okButtonProps: {
        icon: 'check'
      },
      cancelText: 'Cancelar',
      zIndex: 99990,
      onCancel: this.onCancel.bind(this),
      onOk: this.onOk.bind(this),
      centered: true,
      confirmLoading: this.state.loading
    }

    return settings
  }
}

export default GenericLinkModal
