import React from 'react'

function StatusBadge ({className, children}) {
  return (
    <div className="col-md-5 reviewer-widget__status">
      <div className={`status-badge ${className}`}>
        <span>{children}</span>
      </div>
    </div>
  )
}

StatusBadge.PendingReview = function PendingReview () {
  return (
    <StatusBadge className="status-badge--pending-review">
      <i className="fa fa-hourglass-half "></i> Em aberto
    </StatusBadge>
  )
}
StatusBadge.WaitingChanges = function WaitingChanges () {
  return (
    <StatusBadge className="status-badge--waiting-changes">
      <i className="fa fa-wrench"></i> Aguardando alterações
    </StatusBadge>
  )
}
StatusBadge.Approved = function Approved () {
  return (
    <StatusBadge className="status-badge--approved">
      <i className="fa fa-check"></i> Álbum aprovado
    </StatusBadge>
  )
}
StatusBadge.Expired = function Expired () {
  return (
    <StatusBadge className="status-badge--expired">
      <i className="fa fa-calendar-times-o"></i> Limite expirado
    </StatusBadge>
  )
}

function RevisionStatus ({reviewer}) {
  if (reviewer.state === 0) {
    return <StatusBadge.PendingReview />
  }
  if (reviewer.state === 1) {
    return <StatusBadge.WaitingChanges />
  }
  if (reviewer.state === 2) {
    return <StatusBadge.Approved />
  }
  return <StatusBadge.Expired />
}

export default RevisionStatus
