import React, {Component} from 'react'

import toastr from 'toastr'

import Api from '~/modules/Api'
import Loading from '~/widgets/Loading'

import AlbumSteps from './common/Steps'
import AlbumInfoForm from './common/InfoForm'


class AlbumInfo extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isSubmiting: false,
      form: {title: '', description: ''},
      album: false
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getData = this.getData.bind(this)
    this.goToStep = this.goToStep.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    this.setState({isSubmiting: true})
    const {album} = await Api.get(`/revise/album/${this.props.params.id}`)
    if (album.id) {
      return this.setState({
        album,
        form: {title: album.title, description: album.description},
        isSubmiting: false
      })
    }
    this.props.router.push('/album/list')
  }

  goToStep (stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/album/${this.props.params.id}/${stepName}`)
  }

  handleChange(e) {
    const formState = this.state.form
    formState[e.target.name] = e.target.value
    this.setState({form: formState})
  }

  async processForm(event) {
    event.preventDefault()
    this.setState({isSubmiting: true})

    const {id} = this.state.album
    const {title, description} = this.state.form
    const result = await Api.post(`/revise/album/${this.props.params.id}`, {id, title, description})

    this.setState({isSubmiting: false})
    if (result && result.album) {
      toastr['success']('Dados do álbum atualizados com sucesso!')
      return
    }
    toastr['error']('Erro ao atualizar dados do álbum')
  }

  render() {
    return (
      <div className="row">
      {
        !(this.state.album) ?
        <Loading /> :
        <div>
          <AlbumSteps
            currentStep={1}
            reachedStep={3}
            onClickStep={this.goToStep}
          />
          <div className="col-md-12">
            <div className="portlet light" style={{maxWidth: '1380px', margin: 'auto'}}>
              <div className="portlet-title">
                <div className="caption">
                  <i className="fa fa-photo font-dark" />
                  <span className="caption-subject font-dark sbold uppercase">
                    Dados do álbum
                  </span>
                </div>
              </div>
              <div className="portlet-body form row">
                <div className="col-md-12 col-md-offset-0 col-lg-8 col-lg-offset-2">
                  <AlbumInfoForm
                    title={this.state.album.title}
                    description={this.state.album.description}
                    handleChange={this.handleChange}
                    isNew={false}
                    isSubmiting={this.state.isSubmiting}
                    onSubmit={this.processForm} />
                </div>
            </div>
          </div>
        </div>
        </div>
      }
    </div>
    )
  }
}

export default AlbumInfo
