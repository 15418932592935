import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Button, Icon, notification } from 'antd'
import swal from 'sweetalert'
import PropTypes from 'prop-types'

import CustomerService from '~/modules/CustomerService'
import { ElasticInput } from '~/widgets/ElasticInput'

import './EditSubdomain.scss'

export function open(props = {}) {
  const content = document.createElement('div')
  ReactDOM.render(<EditSubdomain {...props} ></EditSubdomain>, content)
  return swal({
    className: 'gallery-dialog-md',
    content,
    buttons: false,
    closeOnClickOutside: false,
  })
}

class EditSubdomain extends Component {

  constructor() {
    super(...arguments)

    this.state = {
      subdomain: this.props.subdomain,
      ready: false,
      busy: false,
      error: null
    }

    this.onInput = this.onInput.bind(this)
    this.save = this.save.bind(this)
  }

  componentDidMount() {
    setTimeout(() => this.setState({ ready: true }), 100)
  }

  onInput(e) {
    let subdomain = e.target.value || ''
    subdomain = subdomain.replace(/ /g, '-')
    subdomain = subdomain.replace(/[^a-zA-Z0-9-]/g, '')
    subdomain = subdomain.slice(0, 48)

    e.target.value = subdomain
    this.setState({ subdomain })
  }

  cancel() {
    swal.close()
  }

  async save() {
    if (!this.valid) {
      return
    }
    this.setState({ busy: true, error: null })
    try {
      let { subdomain } = this.state || {}
      subdomain = subdomain.toLowerCase()
      await CustomerService.editSubdomain(subdomain)
      notification.success({ message: 'O seu subdomínio foi atualizado!' })
      swal.setActionValue({ success: true })
      swal.close('success')
    }
    catch (ex) {
      if (ex && ex.error === 'AlreadyTakenError') {
        this.setState({ error: 'Ops, esse subdomínio já foi escolhido por outro usuário' })
      }
      else {
        console.warn(ex)
        // eslint-disable-next-line no-undef
        Raven.captureException(ex)
        notification.warning({ message: 'Algo deu errado.' })
      }
    }
    this.setState({ busy: false })
  }

  get valid() {
    const { subdomain } = this.state
    return typeof subdomain === 'string' && subdomain !== this.props.subdomain &&
      CustomerService.SUBDOMAIN_PATTERN.test(subdomain)
  }

  render() {
    return (
      <div className='scp-edit-subdomain-dialog'>
        <div className="ant-modal-header">
          <div className="ant-modal-title" id="rcDialogTitle0">
            Editar Subdomínio
          </div>
        </div>

        <button aria-label="Close" className="ant-modal-close" onClick={this.cancel}>
          <span className="ant-modal-close-x">
            <Icon type='close' />
          </span>
        </button>

        <div className='ant-modal-body' style={{ opacity: this.state.ready ? '1' : '0' }}>
          <div className='alert alert-warning'>
            A alteração desse link será atribuída a todas as galerias da sua conta e não apenas a esta.
          </div>
          <div>
            https://
            <ElasticInput placeholder='insira o subdomínio' spellCheck={false}
              className={'subdomain-inp' + (this.state.error ? ' invalid' : '')}
              onInput={this.onInput} value={this.state.subdomain} autoFocus={true} />
            .picsize.com.br/{this.props.hash || '[hash]'}
          </div>
          {this.state.error && <div className='input-error-explain'>{this.state.error}</div>}
        </div>

        <div className="ant-modal-footer">
          <div>
            <Button onClick={this.cancel}>Cancelar</Button>
            <Button type="primary" onClick={this.save} disabled={this.state.busy || !this.valid}>
              {this.state.busy ?
                <><i className='fa fa-spinner fa-pulse'></i> Salvando</> :
                <><Icon type='check' /> Salvar</>}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

EditSubdomain.propTypes = {
  subdomain: PropTypes.string,
  hash: PropTypes.string
}
