import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import swal from 'sweetalert'

import './style.scss'


const Modal = (props) => {
  return (
    <div className={`modal-dialog modal-ls ${props.className}`}>
        <div className="modal-content">
          {props.children}
        </div>
    </div>
  )
}

Modal.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func
  ])
}

Modal.defaultProps = {
  className: 'modal-lg'
}

Modal.show = async (component) => {
  let content = document.createElement('div')
  ReactDOM.render(component, content)

  return await swal({
    className: 'swal-modal2',
    content,
    dangerMode: false,
    title: false,
    icon: false,
    buttons: false
  })
}

Modal.hide = () => swal.close()

const ModalTitle = (props) => {
  return (
    <div className="modal-header">
      <button type="button" className="close" onClick={Modal.hide}>
      x
      </button>
      <h4 className="modal-title">
        {props.children}
      </h4>
    </div>
  )
}

ModalTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ])
}

const ModalBody = (props) => {
  return (
    <div className="modal-body">
      {props.children}
    </div>
  )
}

ModalBody.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ])
}

const ModalFooter = (props) => {
  return (
    <div className="modal-footer">
      {props.children}
    </div>
  )
}

ModalFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ])
}

Modal.Title = ModalTitle
Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
