import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import swal from 'sweetalert'

import Comments from './Comments'
import UploadNew from './UploadNew'
import DeleteSheet from './Delete'

import './style.scss'

async function open (data, type) {
  let wrapper = document.createElement('div')

  if (type === 'comments') {
    ReactDOM.render(<Comments data={data} />, wrapper)
  } else if (type === 'upload') {
    ReactDOM.render(<UploadNew  data={data} />, wrapper)
  } else if (type === 'delete') {
    return DeleteSheet.prompt(data)
  }

  await swal({
    className: 'sheets-modal',
    content: wrapper,
    dangerMode: false,
    title: false,
    icon: false,
    buttons: false
  })
}

export default {
  open
}
