import React from 'react'
import PropTypes from 'prop-types'

import {Button} from 'antd'

import Modal from '~/widgets/Modal2'
import Form from '~/widgets/Form'

import ReviewerForm from './Form'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function ReviewerModal (props) {
  return (
    <Modal id='reviewer'>
      <Modal.Title>
        Cliente
      </Modal.Title>
      <Form id='reviewer-form' {...props}>
        <Modal.Body>
          <ReviewerForm {...props}/>
        </Modal.Body>
        <Modal.Footer>
          <Form.Button className="dark btn-outline" onClick={Modal.hide}>
            Fechar
          </Form.Button>
          <Form.Consumer>
          {(form) => (
            <Button
              loading={form.isSubmiting}
              onClick={form.onSubmit}
              className="green"
              type="primary"
              icon="check"
              disabled={!validateEmail(form.inputs.email)}
              style={{marginLeft: '5px'}}
            >
              Salvar {props.send ? 'e enviar' : null}
            </Button>
          )}
          </Form.Consumer>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

ReviewerModal.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  send: PropTypes.bool
}

export default ReviewerModal
