import React from 'react'

const state = {
  isUploading: false,
  upload: {}
}

const AlbumSheetsContext = React.createContext(state)

export default AlbumSheetsContext
