import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import AntiCopySwitch from '~/widgets/AntiCopySwitch'
import UploadProgress from './Progress'

class PicturesUpload extends Component {

  render() {
    return [
      <div className="col-md-12" key='upload-widget'>
        {
          (this.props.isUploading || this.props.isProcessing) ?
            <div className="portlet light" style={{ backgroundColor: '#32c5d2' }}>
              <div
                className="portlet-body form"
                style={{ display: 'table', margin: 'auto', color: '#FFFFFF' }}>
                <div style={{ display: 'table-cell', verticalAlign: 'middle', height: 140, textAlign: 'center' }}>
                  <i
                    className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                    style={{ lineHeight: '40px' }} />
                  <br />
                  <br />
                  Enviando fotos
              </div>
              </div>
            </div>
            : <Dropzone
              onDrop={this.props.onDrop}
              accept="image/jpeg, image/png"
              style={{ cursor: 'pointer' }}>
              <div className="portlet light" style={{ border: 'dotted 2px #26C281' }}>
                <div
                  className="portlet-body form"
                  style={{ display: 'table', margin: 'auto', color: '#26C281' }}>
                  <div style={{ display: 'table-cell', verticalAlign: 'middle', height: 140, textAlign: 'center' }}>
                    <div><i className="fa fa-cloud-upload fa-3x" style={{ lineHeight: '40px' }} /></div>
                    <div>ENVIAR FOTOS</div>
                    <br />
                    <AntiCopySwitch />
                  </div>
                </div>
              </div>
            </Dropzone>
        }
      </div>,
      (this.props.isUploading || this.props.isProcessing) && <UploadProgress
        key='upload-progress'
        cancelUpload={this.props.cancelUpload}
        isProcessing={this.props.isProcessing}
        processingProgress={this.props.processingProgress}
        {...this.props.progress} />
    ]
  }
}

PicturesUpload.propTypes = {
  processingProgress: PropTypes.any,
  isProcessing: PropTypes.bool,
  isUploading: PropTypes.bool,
  progress: PropTypes.object,
  cancelUpload: PropTypes.func,
  onDrop: PropTypes.func
}

export default PicturesUpload
