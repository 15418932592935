import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { Switch } from 'antd'
import ReactGA from 'react-ga'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'

import some from 'lodash/some'
import toastr from 'toastr'
import swal from 'sweetalert'

import Api from 'app/modules/Api'
import Auth from '~/modules/Auth'
import Loading from '~/widgets/Loading'
import * as FeatureUnavailableAlert from '~/widgets/Alerts/FeatureUnavailable'
import GalleryService from '../GalleryService'
import UploadService from '../UploadService'
import GalleryContext from '../GalleryContext'
import ShareDialogs from '../Share/ShareDialogs'
import UploadProgress from './PicturesUploadProgress'
import FinishedUploadDialog from './FinishedUploadDialog'

import './PicturesUpload.scss'

export default class PicturesUpload extends Component {
  static contextType = GalleryContext
  usSubs = null

  constructor(props, context) {
    super(props, context)

    this.state = {
      loading: true,
      dragging: false,
      uploading: false,
      settings: false,
      replace: true,
      originalTitle: '',
      progress: { total: 0, done: 0 }
    }

    this.onDrop = this.onDrop.bind(this)
    this.cancelUpload = this.cancelUpload.bind(this)
    this.startUpload = this.startUpload.bind(this)
  }

  componentDidMount() {
    this.getWatermarkSettings()
    this.usSubs = UploadService.$state.subscribe(s => this.setState(s))
  }

  componentWillUnmount() {
    this.usSubs.unsubscribe()
  }

  async cancelUpload() {
    this.setState({ uploading: false })
    Api.abortUploads()
    window.location.reload()
  }

  // eslint-disable-next-line max-statements
  async onDrop(files) {
    this.setState({ dragging: false })

    const { id } = this.props
    if (!id || !files || !files.length) {
      return
    }

    const picMap = mapValues(keyBy(this.props.pictures, 'title'), 'file_size')
    let toUpload = [...files]

    if (some(files, f => picMap[f.name] && picMap[f.name] === f.size)) {
      const action = await duplicatedFileAlert()
      if (action === 'cancel') {
        return
      }
      if (action === 'sendNews') {
        toUpload = toUpload.filter(f => !picMap[f.name] || picMap[f.name] !== f.size)
      }
    }

    this.setState({ uploading: true })

    try {
      const canUpload = await UploadService.canUpload(toUpload, this.context.gallery)
      if (canUpload) {
        await this.startUpload(toUpload)
      }
    } catch (ex) {
      if (ex.error && ex.error === 'NotEnoughLimitsError') {
        notEnoughLimits(ex.space)
      }
      if (ex.error && ex.error === 'NotEnoughSpaceError') {
        const res = await notEnoughSpace(ex.space, toUpload)
        if (Array.isArray(res) && res.length) {
          await this.startUpload(res)
        }
      } else {
        console.warn(ex)
      }
    }

    this.setState({ uploading: false })
  }

  async startUpload(files) {
    const hasCover = !this.context.gallery || (this.context.gallery && this.context.gallery.cover_key)

    if (!hasCover) {
      files[0].setCover = true
    }

    const res = await UploadService.startUpload(files, this.props.id)
    const { succ, fail } = res

    let msg = ''
    if (succ) msg += `${succ} ${succ === 1 ? 'foto foi enviada' : 'fotos foram enviadas'}`
    if (fail) {
      if (msg) msg += ' e '
      msg += `${fail} ${fail === 1 ? 'foto não foi reconhecida' : 'fotos não foram reconhecidas'}`
    }

    this.showSuccessDialog(msg)

    if (files[0].setCover === true) {
      this.context.refresh()
    }

    GalleryService.$update.next('pictures')

    if (typeof this.props.onFinish === 'function') {
      this.props.onFinish()
    }
  }

  async showSuccessDialog(msg) {
    const action = await FinishedUploadDialog.open({ msg })
    if (action === 'share') {
      ShareDialogs.openOpts({ link: this.context.link() })
    } else if (action === 'config') {
      this.context.goto('settings')
    }
  }

  async getWatermarkSettings() {
    // this.setState({ loading: true })
    const settings = await GalleryService.getWatermark()
    return this.setState({
      loading: false,
      settings: {
        watermark: false,
        watermark_tmp: false,
        watermark_position: 9,
        ...settings,
        use_watermark: settings.use_watermark === 'true',
        use_antiCopy: settings.use_antiCopy === 'true'
      }
    })
  }

  async updateWatermark(value) {
    const hasWatermark =
      this.state.settings && this.state.settings.watermark && this.state.settings.watermark !== 'false'

    if (value && (Auth.isFreemium || Auth.isTrial)) {
      return await FeatureUnavailableAlert.open()
    }

    if (value && !hasWatermark) {
      swal({
        className: 'pictures-upload-dialog',
        icon: 'warning',
        text:
          "Para habilitar esse recurso é preciso cadastrar uma marca d'água.\
                    Para cadastrar, acesse o menu configurações > marca d'água"
      })
      return
    }

    ReactGA.event({
      category: 'Seleção',
      action: "Alterou a marca d'água"
    })
    const settings = {
      ...this.state.settings,
      use_watermark: value
    }
    // this.setState({ loading: true })
    const result = await Api.post('/customer/settings/collection_watermark', {
      values: settings
    })
    // this.setState({ loading: false })
    if (result && result.success) {
      await this.getWatermarkSettings()
      toastr['success']('Configurações atualizados com sucesso!')
      return
    }
    toastr['error']('Erro ao atualizar configurações')
  }

  render() {
    if (this.state.loading) return <Loading />

    if (this.state.uploading) {
      return (
        <div className={'pictures-upload pictures-uploading'}>
          <div>
            <i className="fa fa-loading fa-spinner fa-pulse" aria-hidden="true"></i>
            <div className="ctrl-text">Enviando fotos</div>
          </div>
          {/* <div className='ctrl-message'>
                    As fotos serão redimensionadas para <strong>1920px</strong>
                </div> */}
          <UploadProgress
            key="upload-progress"
            cancelUpload={this.cancelUpload}
            isProcessing={this.state.isProcessing}
            processingProgress={this.state.processingProgress}
            {...this.state.progress}
          />
        </div>
      )
    }

    return (
      <Dropzone
        onDrop={this.onDrop}
        accept="image/jpeg, image/png"
        style={{ cursor: 'pointer' }}
        onDragEnter={() => this.setState({ dragging: true })}
        onDragLeave={() => this.setState({ dragging: false })}>
        <div className={`pictures-upload ${this.state.dragging ? 'dragging' : ''}`}>
          <div>
            <i className="fa fa-cloud-upload" aria-hidden="true"></i>
            <div className="ctrl-text">{this.state.dragging ? 'Solte para enviar as fotos' : 'Enviar Fotos'}</div>
          </div>
          <div className="ctrl-setting">
            {'Inserir marca d\'água: '}
            <span onClick={e => e.stopPropagation()}>
              <Switch
                size="small"
                checked={this.state.settings && this.state.settings.use_watermark}
                onChange={v => this.updateWatermark(v)}
              />
            </span>
          </div>
          {/* {this.props.hasPictures && (
            <div className="ctrl-setting">
              Substituir fotos com mesmo nome:{" "}
              <span onClick={e => e.stopPropagation()}>
                <Switch
                  size="small"
                  checked={this.state.replace}
                  onChange={replace => this.setState({ replace })}
                />
              </span>
            </div>
          )} */}
        </div>
      </Dropzone>
    )
  }
}

PicturesUpload.propTypes = {
  id: PropTypes.number,
  pictures: PropTypes.any,
  onFinish: PropTypes.func
}


async function notEnoughLimits(space) {
  const content = document.createElement('div')
  content.style = 'text-align: left; font-size: 13px'
  content.innerHTML =
    'O limite de fotos do plano contratado não é suficiente para fazer o envio das fotos selecionadas.<br>' +
    '<br><strong>Imagens selecionadas:</strong> ' +
    space.requested +
    '<br><strong>Quantidade contratada:</strong> ' +
    space.limit +
    '<br><strong>Quantidade utilizado:</strong> ' +
    space.used +
    '<br><strong>Quantidade disponível:</strong> ' +
    space.available

  const openPlans = await swal({
    dangerMode: true,
    content,
    title: 'Sem espaço suficiente!',
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true
      },
      confirm: {
        text: 'Contratar mais espaço',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'http://picsize.com.br/optimizepress/planospicsize/'
  }
  ReactGA.event({
    category: 'Seleção',
    action: 'Espaço insuficiente para upload'
  })
}

async function notEnoughSpace(space, files) {
  const l = space.max_picture_size / 1048576
  const content = document.createElement('div')
  content.style = 'text-align: left; font-size: 13px'
  content.innerHTML =
    'Verifique se realmente é necessário enviar a foto nesse tamanho, pois isso irá impactar no tempo de envio das fotos. <br>' +
    'Se você exportar as suas fotos com compressão de 90%, irá diminuir muito o tamanho delas e a qualidade da foto ficará a mesma, além disso o envio será muito mais rápido. <br>'

  const send = await swal({
    className: 'no-space-alert-dialog',
    dangerMode: true,
    content,
    title: `Foram detectadas algumas fotos acima de ${l}MB, que é o limite de tamanho por foto.`,
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Cancelar envio',
        value: false,
        visible: true,
        closeModal: true
      },
      confirm: {
        text: `Enviar apenas fotos com menos de ${l}MB`,
        value: true,
        className: 'warning',
        visible: true,
        closeModal: true
      }
    }
  })

  ReactGA.event({
    category: 'Seleção',
    action: 'Arquivo ultrapassou tamanho limite'
  })

  // const invalidFiles = space.invalidFiles.map(i => `${(i.size / 1048576).toFixed(2)}MB`)
  // const log = {
  //     user: Auth.userData.email,
  //     message: `Tentativa de envio com foto com mais de ${l}MB`,
  //     collection: { title: gallery.title },
  //     filesLength: invalidFiles.length,
  //     fileSizes: invalidFiles.join(', ')
  // }
  // Raven.captureMessage(JSON.stringify(log, null, 4))

  if (send) {
    return files.filter(f => f.size <= space.max_picture_size)
  }

  return null
}

function duplicatedFileAlert() {
  return swal({
    className: 'duplicated-upload-dialog',
    title: 'O que deseja fazer?',
    text: 'Foram detectadas uma ou mais fotos que já estão na galeria.',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancelar envio',
        value: 'cancel',
        visible: true,
        closeModal: true
      },
      sendAll: {
        text: 'Enviar todas as fotos',
        value: 'sendAll',
        visible: true,
        className: 'warning',
        closeModal: true
      },
      sendNews: {
        text: 'Não enviar as duplicadas',
        value: 'sendNews',
        visible: true,
        closeModal: true
      }
    }
  })
}
