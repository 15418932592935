import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Subject } from 'rxjs'
import { takeUntil, filter } from 'rxjs/operators'
import chunk from 'lodash/chunk'
import ReactGA from 'react-ga'
import toastr from 'toastr'

import Loading from '~/widgets/Loading'
import Api from 'app/modules/Api'
// import GalleryPictures from './GalleryPictures'
import CollectionGalleryWrap from './CollectionGalleryWrap'
import PicturesUpload from './PicturesUpload'
import GalleryContext from '../GalleryContext'

import WS from '../Websocket'
import GalleryService from '../GalleryService'
import UploadService from '../UploadService'
import Pagination from './Pagination'
import Sort from './Sort'

import './PicturesPage.scss'

const PAGE_SIZE = 35

export default class PicturesPage extends Component {
  static contextType = GalleryContext
  $destroy = new Subject()
  $toolbar = new Subject()
  us = UploadService

  constructor(props, context) {
    super(props, context)

    this.state = {
      pictures: [],
      pagePictures: [],
      page: 1,
      selectAllChecked: false,
      anySelected: false,
      showUpload: false,
      hasPictures: false,
      isReady: false
    }

    this.onFinishUpload = this.onFinishUpload.bind(this)
    this.changePage = this.changePage.bind(this)
    this.changeSort = this.changeSort.bind(this)
    this.upState = this.upState.bind(this)
    this.toggleUpload = this.toggleUpload.bind(this)
  }

  componentDidMount() {
    if (this.props.sample) return
    const { id } = this.props.params
    if (!id) {
      return this.onData([])
    }
    GalleryService.$getPictures(id)
      .pipe(takeUntil(this.$destroy))
      .subscribe(i => this.onData(i))

    WS.events
      .pipe(
        filter(e => !this.us.isUploading && e && e.type === 'PictureReady' && e.collection_id === +id),
        takeUntil(this.$destroy)
      )
      .subscribe(e => this.onPictureReady(e))
  }

  componentWillUnmount() {
    this.$destroy.next()
    this.$destroy.complete()
  }

  onData(pictures) {
    const ordenation = this.context.gallery ? (this.context.gallery.pictures_ordenation || 1) : 1
    pictures = sortPictures(pictures, ordenation)
    const hasPictures = pictures && !!pictures.length
    const page = this.state.page || 1
    const pages = chunk(pictures, PAGE_SIZE)
    this.setState({
      hasPictures,
      pictures,
      pagePictures: pages[page - 1],
      picturesCount: pictures.length,
      pageCount: pages.length,
      isReady: true
    })
  }

  async onPictureReady(evt) {
    const { picture_id } = evt
    const picture = await GalleryService.getPicture(picture_id)
    const { pictures } = this.state
    if (!picture || !picture.id || !Array.isArray(pictures)) {
      return
    }
    const index = pictures.findIndex(i => i.id === picture.id)
    if (index > -1) {
      pictures[index] = picture
    }
    else {
      pictures.push(picture)
    }
    this.onData(pictures)
  }

  onFinishUpload() {
    this.setState({ showUpload: false })
  }

  upState(state) {
    this.setState(state)
  }

  changePage(page) {
    const { pictures } = this.state
    const pages = chunk(pictures, PAGE_SIZE)
    this.setState({
      pictures,
      pagePictures: pages[page - 1],
      picturesCount: pictures.length,
      pageCount: pages.length,
      page
    })
  }

  async changeSort(pictures_ordenation) {
    if (isNaN(pictures_ordenation)) return
    this.setState({
      pictures: sortPictures(this.state.pictures, pictures_ordenation),
    })

    this.context.gallery.pictures_ordenation = pictures_ordenation

    const { id } = this.props.params
    const result = await Api.post('/collection/update', { id, pictures_ordenation })
    if (result && result.success) {
      toastr['success']('Ordenação alterada!')
    }

    ReactGA.event({
      category: 'Seleção',
      action: 'Alterou a ordenação das fotos uma coleção'
    })

    return this.changePage(this.state.page)
  }

  toggleUpload() {
    const { showUpload } = this.state
    this.setState({ showUpload: !showUpload })
  }

  render() {
    // eslint-disable-next-line curly
    if (this.props.sample) return (
      <div className="pictures-page">
        <div className="portlet light">
          <div className='portlet-body'>
            <PicturesUpload hasPictures={false} />
          </div>
        </div>
      </div>
    )

    if (!this.state.isReady) return <Loading />

    if (!this.context.gallery) return <Loading />

    return (
      <div className="pictures-page">
        <div className="portlet light">

          <div className={`tilting-ctrl-wrap ${this.state.showUpload || !this.state.hasPictures || UploadService.state.uploading ? '' : 'ctrl-hidden'}`}>
            <div className={'tilting-ctrl'}>
              <PicturesUpload id={this.props.params.id} hasPictures={true} pictures={this.state.pictures} onFinish={this.onFinishUpload} />
            </div>
          </div>


          {this.state.hasPictures && <div className='pictures-toolbar portlet-title'>
            <button
              type="button"
              onClick={this.toggleUpload}
              className={'toolbar-btn btn green' + (this.state.showUpload ? ' active' : '')}>
              <i className="fa fa-cloud-upload" aria-hidden /> Enviar Fotos
                        </button>
            <Sort changeSort={this.changeSort} currentSort={this.context.gallery.pictures_ordenation} />
            <button
              type="button"
              onClick={() => this.$toolbar.next('selectAll')}
              className={'toolbar-btn btn green btn-outline ' + (this.state.selectAllChecked ? 'active' : '')}>
              <i className="fa fa-check-circle" aria-hidden /> Selecionar todas
                        </button>
            {this.state.anySelected &&
              <button
                onClick={() => this.$toolbar.next('deleteSelection')}
                type="button"
                className={'toolbar-btn btn red'}>
                <i className="fa fa-trash" aria-hidden /> Deletar fotos
                            </button>
            }

            <Pagination {...this.state}
              pageSize={PAGE_SIZE}
              currentPage={this.state.page}
              pages={this.state.pageCount}
              changePage={this.changePage}
            />
          </div>}

          {this.state.hasPictures && <div className='portlet-body'>
            {/* <GalleryPictures
                            pictures={this.state.pagePictures}
                            showCover={this.state.page === 1}
                        /> */}
            <CollectionGalleryWrap
              params={this.props.params}
              pictures={this.state.pagePictures}
              $actions={this.$toolbar.asObservable().pipe(takeUntil(this.$destroy))}
              upState={this.upState}
            />
          </div>}
        </div>
      </div>
    )
  }
}

PicturesPage.propTypes = {
  params: PropTypes.any,
  sample: PropTypes.bool
}


function sortPictures(pictures, type = 1) {
  // {id: 1, name: 'Data de envio [A-Z]'},
  // {id: 2, name: 'Data de envio [Z-A]'},
  // {id: 3, name: 'Nome do arquivo [A-Z]'},
  // {id: 4, name: 'Nome do arquivo [Z-A]'},
  // {id: 5, name: 'Data de criação da foto [A-Z]'},
  // {id: 6, name: 'Data de criação da foto [Z-A]'}
  // {id: 7, name: 'Numeração da foto [Z-A]'}
  // {id: 8, name: 'Numeração da foto [Z-A]'}
  if (!Array.isArray(pictures)) {
    console.warn({ pictures })
    return []
  }

  if (type === 1) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.created_at < b.created_at) return -1
      if (a.created_at > b.created_at) return 1
      return 0
    })
  }
  if (type === 2) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.created_at > b.created_at) return -1
      if (a.created_at < b.created_at) return 1
      return 0
    })
  }
  if (type === 3) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
      return 0
    })
  }
  if (type === 4) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.title.toLowerCase() > b.title.toLowerCase()) return -1
      if (a.title.toLowerCase() < b.title.toLowerCase()) return 1
      return 0
    })
  }
  if (type === 5) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.file_date < b.file_date) return -1
      if (a.file_date > b.file_date) return 1
      return 0
    })
  }
  if (type === 6) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.file_date > b.file_date) return -1
      if (a.file_date < b.file_date) return 1
      return 0
    })
  }
  if (type === 7) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      const aTitle = parseInt(a.title.toLowerCase().replace(/\D/g, ''))
      const bTitle = parseInt(b.title.toLowerCase().replace(/\D/g, ''))
      if (aTitle < bTitle) return -1
      if (aTitle > bTitle) return 1
      return 0
    })
  }
  if (type === 8) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      const aTitle = parseInt(a.title.toLowerCase().replace(/\D/g, ''))
      const bTitle = parseInt(b.title.toLowerCase().replace(/\D/g, ''))
      if (aTitle > bTitle) return -1
      if (aTitle < bTitle) return 1
      return 0
    })
  }
  return pictures
}
