import React from 'react'
import PropTypes from 'prop-types'

import { Divider, Icon } from 'antd'
import Auth from '~/modules/Auth'

import './style.scss'

function PreviewMessage({ data }) {
  return (
    <section>
      <Divider
        type="horizontal"
        orientation="left"
        className="divider-msg"
        style={{ margin: '5px 0' }}
      >
        <Icon type="mail" /> Mensagem que o cliente receberá
      </Divider>
      <div className="preview-message">
        <p>
          Olá, {data.clientName || '[nome do cliente]'}! Tudo bem?
        </p>
        <p>
          {data.clientMessage.split('\n').map((item, index) => [<span key={`pm-s${index}`}>{item}</span>, <br key={`pm-b${index}`} />])}
        </p>
        <p>Link de acesso - {data.link ? <a target='_blank' href={data.link}>{data.link}</a> : '[link de acesso será inserido aqui]'}</p>
        {!data.hideConfirm && <p>Por favor, confirme o recebimento deste e-mail.</p>}
        <p>
          Um abraço,
          <br />
          {Auth.userData.displayName}
        </p>
      </div>
    </section>
  )
}

PreviewMessage.propTypes = {
  data: PropTypes.object
}

export default PreviewMessage
