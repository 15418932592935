import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import { Row, Col } from 'antd'

import Loading from '~/widgets/Loading'
import Api from '~/modules/Api'
import CustomerService from '~/modules/CustomerService'
import Auth from '~/modules/Auth'

import CollectionsTable from './CollectionsTable'
import ReachedLimitDialog from '../../../widgets/ReachedLimitDialog/ReachedLimitDialog'

class CollectionList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      collections: false,
      listCollections: [],
      searchText: '',
      maxSelLimitReached: false
    }

    this.getData = this.getData.bind(this)
    this.goToStep = this.goToStep.bind(this)
    this.add = this.add.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    const collections = await Api.get('/collection')

    const { permission } = await Api.get('/collection/permission?collection_type=selection')
    if (!permission) this.setState({ maxSelLimitReached: true })

    if (collections && Array.isArray(collections)) {
      collections.sort((a, b) => {
        if (a.created_at > b.created_at) return -1
        if (a.created_at < b.created_at) return 1
        return 0
      })
      console.log(collections)
      return this.setState({ collections, listCollections: collections })
    }
  }

  handleSearchGallery = () => {
    const word = (this.state.searchText || '').toLowerCase()

    this.setState({
      collections: this.state.listCollections.filter(filterList)
    })

    function filterList (collection) {
        return collection.title.toString().toLowerCase().includes(word)
    }
  }

  goToStep (collection, stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/collection/${collection}/${stepName}`)
  }

  async add() {
    if(this.state.maxSelLimitReached){
      CustomerService.pushReachedPlanLimitLog('selection')
      return await ReachedLimitDialog.open({
        title: 'Limite atingido',
        message: `O limite de ${Auth.userData.subscription.limits.sel_max_collections} seleções do seu plano foi atingido.`
      })
    }
    this.props.router.push('/collection/new')
  }

  render() {
    return (
      <Row>
        <h1 className="page-title">Minhas Seleções</h1>
        <Col span={24}>
        <div className="portlet light ">
            <div className="portlet-title">
              <div className="caption">
                <Link onClick={this.add}>
                    <span className="btn btn-circle btn-primary green">
                    <i className="fa fa-plus"></i> Nova Seleção
                  </span>
                </Link>
              </div>
              <div className="tools" style={{alignItems: 'center', verticalAlign: 'middle', display: 'flex'}}>
                <div className="input-icon right" style={{marginRight: 10}}>
                      <i className="icon-magnifier"></i>
                      <input
                      className="form-control"
                      onChange={(event) => this.setState({ searchText: event.target.value }, () => this.handleSearchGallery())}
                      value={this.state.searchText}
                      placeholder="Buscar Seleção" type="text" />
                  </div>
              </div>
            </div>
            {this.state.collections && !this.state.collections.length && (<CreateFirst />)}
            <div className="portlet-body" style={{ marginTop: '-10px', paddingTop: 0 }}>
              <CollectionsTable data={this.state.collections} goToStep={this.goToStep} />
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

function CreateFirst () {
  return (
    <div className="portlet-body" style={{ minHeight: '300px', padding: '50px 0' }}>
      <div className="text-center">
        <p style={{ fontSize: '64px' }}>
          <i className="fo fo-check-picture" />
        </p>
        <h3>
          Você ainda não possui nenhuma coleção!
        </h3>
        <h4>
          <Link to="/collection/new">
            Clique aqui
          </Link> para cadastrar a primeira
        </h4>
      </div>
    </div>
  )
}

CollectionList.propTypes = {
  router: PropTypes.object
}

export default CollectionList
