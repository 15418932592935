import React from 'react'
import PropTypes from 'prop-types'

import FormContext from './context'

class Form extends React.Component {
  constructor (props, context) {
    super(props, context)

    this.state = {
      isSubmiting: false,
      inputs: this.props.inputs,
      id: this.props.id,
      onChange: this.handleChange.bind(this),
      onSubmit: this.onSubmit.bind(this),
    }
  }

  handleChange (e) {
    const inputsState = this.state.inputs
    inputsState[e.target.name] = e.target.value
    this.setState({inputs: inputsState})
  }

  componentDidMount () {
  }

  async onSubmit (e) {
    e.preventDefault()
    this.setState({isSubmiting: true})
    await this.props.onSubmit(this.state.inputs)
    this.setState({isSubmiting: false})
  }

  render () {
    return (
      <FormContext.Provider value={this.state}>
        <form id={this.props.id} className={`form-horizontal ${this.props.className}`} role="form" onSubmit={this.onSubmit.bind(this)}>
          {this.props.children}
        </form>
      </FormContext.Provider>
    )
  }
}

Form.propTypes = {
  children: PropTypes.any,
  inputs: PropTypes.object,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string.isRequired
}

Form.defaultProps = {
  inputs: {},
  className: ''
}

export default Form
