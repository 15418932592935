import ReactDom from 'react-dom'
import './style.scss'

function toast(msg, { icon, style, duration }) {
    let portal = document.querySelector('#toasty-portal')

    if (!portal) {
        portal = document.createElement('div')
        portal.id = 'toasty-portal'
    }

    document.body.appendChild(portal)

    const toastHost = document.createElement('div')
    toastHost.className = 'toast-host entering'

    function close() {
        clearTimeout(autoClose)
        toastHost.className = 'toast-host exiting'
        setTimeout(() => toastHost.remove(), 3e2)
    }

    const autoClose = setTimeout(() => close(), duration || 5e3)

    const toast = (
        <div className='toast-wrap' style={style}>
            <div className='toast-content'>
                <div className='toast-icon'>
                    <i className={`fa ${icon ? icon : 'fa-info-circle'}`}></i>
                </div>
                <div className='toast-message'>{msg}</div>
            </div>
            <button className='toast-close-btn' onClick={close}>
                <i className='fa fa-close'></i>
            </button>
        </div>
    )

    ReactDom.render(toast, toastHost)

    portal.appendChild(toastHost)
    setTimeout(() => toastHost.className = 'toast-host', 1e1)
}

export default {
    toast
}