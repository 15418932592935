import React, { Component } from 'react'
import { Modal } from 'antd'

import { Videos } from './KnowledgeService'

import Loading from '~/widgets/Loading'

import './KnowledgePage.scss'

export default class KnowledgeCenter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
      visible: false,
      link: null,
      title: null,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ ready: true })
    }, 300)
  }

  handleModal = (link, title) => {
    this.setState({
      visible: true,
      link,
      title
    })
  }

  render() {
    return (
      <div className='knowledge-list-page'>
        <h1 className="page-title">Área do Conhecimento</h1>
        <Modal
          title={this.state.title}
          visible={this.state.visible}
          onCancel={() => this.setState({ visible: false })}
          footer={[null, null]}
          centered={true}
          width="80vw"
          bodyStyle={{ height: '70vh' }}
        >
          <iframe
            width="100%"
            height="100%"
            src={this.state.link + 'autoplay=1'}
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="true"
          />
        </Modal>
        <div className='portlet light'>
          <div className='portlet-body'>
            {
              this.state.ready
                ?
                (
                  <div className='mt-element-card mt-element-overlay'>
                    <div className='list-wrap'>
                      {Videos.map((video, index) => <VideoCard link={video.link} title={video.title} key={index} handleModal={this.handleModal} />)}
                    </div>
                  </div>
                )
                : <Loading />
            }
          </div>
        </div>
      </div>
    )
  }
}

function VideoCard({ link, title, handleModal }) {
  return (
    <div className="knowledge-list-page" onClick={() => handleModal(link, title)}>
      <div className='mt-card-item'>
        <div className="mt-card-avatar mt-overlay-1">
          <a alt={`Clique para assistir o vídeo ${title}`}>
            <iframe
              className="video-card"
              width="100%"
              height="100%"
              src={link + 'showinfo=0'}
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="true"
            />
          </a>
        </div>
        <div className="mt-card-content">
          <h3 className="mt-card-name">
            {title}
          </h3>
        </div>
      </div>
    </div>
  )
}
