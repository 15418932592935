import React from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import 'moment/locale/pt-br'

import swal from 'sweetalert'
import {
  Tooltip,
  Row,
  Col,
  Icon,
  Menu,
  Dropdown,
  Button,
} from 'antd'
import { Link } from 'react-router'

// import SelectionModal from './SelectionModal'

import './style.scss'

const mainClassName = 'client-item'

const getStatusName = (expired, open) => {
  if (!open) return 'finished'
  if (open && expired) return 'expired'
  return 'open'
}

const fixedDate = (date) => {
  let today = new Date(date)
  let tomorrow = new Date()
  tomorrow.setDate(today.getDate()+1)
  let dateISO = tomorrow.toISOString()
  return `${dateISO.split('T')[0]}T1:50:33.359Z`
}

const StatusTag = ({ statusName, finishedAt, limitDate }) => {
  switch (statusName) {
    case 'expired':
      return (
        <div
          className="dashboard-stat dashboard-stat-v2 red"
        >
          <div className="display red" style={{ marginBottom: 0 }}>
            <div className="number">
              <span style={{ color: '#FFF' }}>
                EXPIRADO
              </span>
              <span className="extra-info">
              {limitDate && `em ${moment(limitDate).format('DD/MM/YYYY')}`}
              </span>
            </div>
            <div className="icon">
              <Icon type="exclamation-circle" />
            </div>
          </div>
        </div>
      )
    case 'open':
      return (
        <div
          className="dashboard-stat dashboard-stat-v2 yellow-saffron"
        >
          <div className="display" style={{ marginBottom: 0 }}>
            <div className="number">
              <span style={{ color: '#FFF' }}>
                EM ABERTO
              </span>
              <span className="extra-info">
              {limitDate && `até ${moment(limitDate).format('DD/MM/YYYY')}`}
              {!limitDate && 'aguardando seleção'}
              </span>
            </div>
            <div className="icon">
              <Icon type="eye" />
            </div>
          </div>
        </div>
      )
    case 'finished':
      return (
        <div
          className="dashboard-stat dashboard-stat-v2 green-jungle"
        >
          <div className="display" style={{ marginBottom: 0 }}>
            <div className="number">
              <span>
              CONCLUÍDO 
              </span>
              <span className="extra-info">
              {finishedAt && `em ${moment(finishedAt).format('DD/MM/YYYY')}`}
              </span>
            </div>
            <div className="icon">
              <Icon type="check-circle" />
            </div>
          </div>
        </div>
      )
  }
}

const ExpirationStatus = ({ limitDate, statusName }) => {
  if (!limitDate) return 'não definido'
  const expDay = moment(limitDate).format('DD/MM/YYYY')
  const todayDay = moment().format('DD/MM/YYYY')

  const leftToExpire = moment(limitDate).endOf('day').diff(moment(), 'days')

  const isFinished = statusName === 'finished'
  let statusText

  if (isFinished) {
    statusText = moment(limitDate).format('DD/MM/YYYY')
  } else if (leftToExpire >= -1 && leftToExpire < 1) {
    statusText = (
      expDay === todayDay
      ? 'Expira hoje'
      : 'Expirou ontem'
    )
  } else if (leftToExpire >= 1) {
    statusText = (
      leftToExpire === 1
      ? 'Expira amanhã'
      : `Expira em ${leftToExpire} dias`
    )
  } else if (leftToExpire < -1) {
    statusText = `Expirou há ${leftToExpire * -1} dias`
  }

  return (
    <Tooltip title={expDay}>
    {<span
      {...(
        (statusName === 'expired')
        ? ({ className: 'label label-danger' })
        : ({
          className: 'label',
          style: {
            fontWeight: '500',
            backgroundColor: isFinished ? 'rgb(38, 194, 129)' : '#F4D03F'
          }
        })
      )}>
        {statusText}
      </span>}
    </Tooltip>
  )
}

const lastAccessStatus = (lastAccess) => {
  if (!lastAccess) return 'Ainda não acessou'
  const lastDay = moment(lastAccess).format('DD/MM/YYYY')
  const todayDay = moment().format('DD/MM/YYYY')

  const diff = moment(lastAccess).endOf('day').diff(moment(), 'days')

  return (
    <Tooltip title={moment(lastAccess).format('DD/MM/YYYY HH:mm')}>
        {lastAccess != 0 && lastDay !== todayDay
          ? (`há ${diff * -1} dias`)
          : (`hoje às ${moment(lastAccess).format('HH:mm')}`)
        }
    </Tooltip>
  )
}

// {format(props.reviewer.limit_date, 'DD/MM/YYYY')}

class SelectionItem extends React.Component {
  static propTypes = {
    item: PropTypes.object,
    execAction: PropTypes.func
  };

  constructor(props, context) {
    super(props, context)
    //console.log(props)
    const sel = { ...this.props.item }

    this.state = {
      id: sel.id,
      hash: sel.hash,
      clientName: sel.client_name,
      clientTel: sel.client_tel ? (` | ${sel.client_tel}`) : '',
      clientEmail: sel.client_email,
      clientMessage: (sel.extradata || {}).client_message || '',
      selectedPictures: sel.selected_pictures,
      limitDate: sel.limit_date,
      allowDownload: sel.allow_copy,
      contractedPictures: sel.contracted_pictures,
      allowExtraPictures: sel.allow_extra_pictures,
      extraPicturePrice: sel.extra_picture_price,
      linkId: sel.sel_link_id,

      lastAccess: sel.last_access,
      createdAt: sel.created_at,
      updatedAt: sel.updated_at,
      finishedAt: (sel.extradata || {}).finished_at || '',

      isValidEmail: true
    }
  }

  execAction = (action) => {
    return this.props.execAction(action, this.state)
  }

  exportFileList = (separator, quotes = true) => {
    return exportFileList(this.state.selectedPictures, separator, quotes)
  }

  get extraPics () {
    return Math.max(0, this.state.selectedPictures.length - this.state.contractedPictures)
  }

  render() {
    //console.log(this.props.item)
    const { expired, open } = this.props.item
    const currentStatusName = getStatusName(expired, open)
    const className = `${mainClassName} ${mainClassName}--${currentStatusName}`

    const isFinished = currentStatusName === 'finished'
    const url = `${process.env.SELECTION_FRONT_URL}/${this.state.hash}`

    const exportActions = (
      <Menu>
        <Menu.Item
          key="e-lightroom"
          onClick={() => this.exportFileList(', ', false)}
        >
          <i className="ti-icon-lightroom" /> Exportar lista para Lightroom
        </Menu.Item>
        <Menu.Item key="e-finder" onClick={() => this.exportFileList(' OR ')}>
          <Icon type="apple" />
          Exportar lista para Finder
        </Menu.Item>
        <Menu.Item key="e-explorer" onClick={() => this.exportFileList(' OR ')}>
          <Icon type="windows" />
          Exportar lista para Windows Explorer
        </Menu.Item>
        <Menu.Item key="e-pdf" onClick={() => this.execAction('getPDF')}>
          <Icon type="file-pdf" />
          Exportar em PDF
        </Menu.Item>
      </Menu>
    )


    return (
      <div className={`portlet light ${className}`}>
        <div className="portlet-title">
          <div className="caption">
            <i className="fa fa-user font-green-sharp" />
            <span className="caption-subject font-green-sharp sbold">
              {this.state.clientName}
            </span>
            <span
              className="caption-subject"
              style={{
                color: 'rgb(136, 150, 160)',
                fontSize: '14px',
                display: 'inline-block',
                marginLeft: '6px'
              }}
            >      
              {this.state.clientEmail}
              {this.state.clientTel}
            </span>
          </div>
          <div className="actions">
            <div className="btn-group">
              <a
                className="btn green-haze btn-outline btn-circle btn-sm"
                data-toggle="dropdown"
                data-hover="dropdown"
                data-close-others="true"
              >
                {' Ações '}
                <i className="fa fa-angle-down" />
              </a>
              <ul className="dropdown-menu pull-right">
                <li style={{ paddingTop: '10px' }}>
                  <a href={`${url}`} target="_blank">
                    Ver como cliente
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a onClick={() => this.execAction('whatsapp')}>
                    Enviar por whatsapp
                  </a>
                </li>
                <li className="divider" />
                <li>
                  <a onClick={() => this.execAction('edit')}>
                    Editar dados dessa seleção
                  </a>
                </li>

                <li>
                  <a onClick={() => this.execAction('resend')}>
                    Reenviar para esse cliente
                  </a>
                </li>
                <li>
                  <a onClick={() => this.execAction('delete')}>
                    Excluir seleção deste cliente
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Row>
          <Col span={12}>
            <table className="table table-hover table-light">
              <tbody>
                <tr role="row" className="odd" style={{}}>
                  <td>Último acesso</td>
                  <td style={{ textAlign: 'right' }}>
                    {lastAccessStatus(this.state.lastAccess)}
                  </td>
                </tr>
                <tr role="row" className="odd" style={{}}>
                  <td>Selecionadas</td>
                  <td style={{ textAlign: 'right' }}>
                    <span className="label" style={{ fontWeight: '500', backgroundColor: isFinished ?  '#26C281' : '#2ab4c0' }}>
                      {this.state.selectedPictures.length}
                    </span>
                    <span>
                      {!!(+this.state.contractedPictures) && ` de ${this.state.contractedPictures}`}
                    </span>
                  </td>
                </tr>
                <tr role="row" className="odd" style={{}}>
                  <td>Fotos adicionais</td>
                  <td style={{ textAlign: 'right' }}>
                  {this.state.allowExtraPictures ? (
                    <Tooltip title={`${(+this.state.extraPicturePrice / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} por foto`}>
                    {this.extraPics} ({
                      (this.extraPics * (+this.state.extraPicturePrice / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                    })
                    </Tooltip>
                  ) : 'Não habilitado'}
                  </td>
                </tr>
                <tr role="row" className="odd" style={{}}>
                  <td>Prazo limite</td>
                  <td style={{ textAlign: 'right' }}>
                    <ExpirationStatus limitDate={this.state.limitDate} statusName={currentStatusName} />
                  </td>
                </tr>
                <tr role="row" className="odd" style={{}}>
                    <td>Adicionado em</td>
                    <td style={{ textAlign: 'right' }}>
                      {this.state.createdAt && moment(this.state.createdAt).format('DD/MM/YYYY')}
                    </td>
                  </tr>
                {!this.state.linkId && (
                  <tr role="row" className="odd" style={{}}>
                    <td>Link</td>
                    <td style={{ textAlign: 'right' }}>
                      {!!url && (<a href={url} target="_blank">{url}</a>)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
          <Col span={11} offset={1}>
            <StatusTag
              statusName={currentStatusName}
              finishedAt={this.state.finishedAt || this.state.updatedAt}
              limitDate={this.state.limitDate}
            />
            <Row gutter={10}>
              {!isFinished && expired && (
                <Col span={12}>
                  <Button
                    type={'primary'}
                    icon="check-circle"
                    style={{ marginBottom: '5px', width: '100%' }}
                    onClick={() => this.execAction('edit')}
                  >
                    Alterar prazo limite
                  </Button>
                </Col>
              )}
              {this.state.selectedPictures.length > 0 && (
                <Col span={12}>
                  <Tooltip
                    title={`Confira aqui a lista de fotos que o seu cliente escolheu ${
                      !isFinished ? 'até o momento' : ''
                    }`}
                  >
                    <Button
                      target="_blank"
                      href={`${url}/summary`}
                      type={isFinished ? 'primary' : 'default'}
                      icon="check-circle"
                      style={{ marginBottom: '5px', width: '100%' }}
                    >
                      Ver fotos selecionadas
                    </Button>
                  </Tooltip>
                </Col>
              )}
              <Col span={12}>
                <Dropdown overlay={exportActions} trigger={['click']} disabled={!this.state.selectedPictures || !this.state.selectedPictures.length}>
                  <Button style={{ marginBottom: '5px', width: '100%' }}>
                    Exportar selecionadas <Icon type="down" />
                  </Button>
                </Dropdown>
              </Col>
              {isFinished && (
                <Col span={12}>
                  <Tooltip title={'Permitir que o cliente faça alterações na seleção'} >
                    <Button
                      style={{ width: '100%' }}
                      icon="unlock"
                      onClick={() => this.execAction('reopen')}
                    >
                      Reabrir seleção
                    </Button>
                  </Tooltip>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    )

  }
}

function exportFileList(fileList, separator, quotes = true) {
  function getName(title) {
    title = `${title.substr(0, title.lastIndexOf('.'))}`
    if (!quotes) return `${title}.`
    return `"${title}"`
  }

  const content = document.createElement('textarea')
  content.rows = 5
  content.style = 'width: 100%'
  content.innerHTML = fileList.map(f => getName(f.title)).join(`${separator}`)
  content.onmouseover = e => e.target.select()

  swal({ content })
}

export default function SelectionItemComponent(execAction, item) {
  return <SelectionItem key="sel-item" execAction={execAction} item={item} />
}
