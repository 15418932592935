import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Button, Icon } from 'antd'
import swal from 'sweetalert'

export function open(props = {}) {
  const content = document.createElement('div')
  ReactDOM.render(<AlertDialog {...props} />, content)
  return swal({
    className: 'gallery-dialog-alert',
    icon: 'error',
    content,
    buttons: false,
    closeOnClickOutside: false,
  })
}

function AlertDialog({ text }) {
  if (!text) {
    text = 'Recurso indisponível no plano atual. Faça agora mesmo o Upgrade do seu plano.'
  }

  function cancel() {
    swal.close()
  }

  function ok() {
    window.open('http://picsize.com.br/optimizepress/planospicsize')
    swal.close()
  }

  return (
    <>
      <button aria-label="Close" className="ant-modal-close" onClick={cancel}>
        <span className="ant-modal-close-x">
          <Icon type='close' />
        </span>
      </button>

      <div className='ant-modal-body'>
        <div className='swal-text'>{text}</div>
      </div>

      <div className="ant-modal-footer">
        <div>
          <Button onClick={cancel}>Cancelar</Button>
          <Button type="primary" onClick={ok}>
            <><Icon type='check' /> Ver Planos</>
          </Button>
        </div>
      </div>
    </>
  )
}

AlertDialog.propTypes = {
  text: PropTypes.string
}
