import React, { Component } from 'react'

import FeaturesCard from './Wizard/FeaturesCard'

import './Home.scss'

export default class HomePage extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = { }
  }

  render() {
    return (
      <div className="getting-start">
        <FeaturesCard />
      </div>
    )
  }
}
