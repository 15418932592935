import React, {Component} from 'react'
import ReactGA from 'react-ga'
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc'
import PropTypes from 'prop-types'

import './style.scss'

const SortableItem = SortableElement((props) => (<Item {...props} movable={true} />))
const SortableList = SortableContainer(({items}) => (
  <Container>
  {items.map((value, index) => (
    <SortableItem key={`item-${index}`} index={index} value={value} />
  ))}
  </Container>
))

function Container ({children}) {
  return (
    <div className="album-sheets-gallery__sortable container">
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.array
}

function SortableGallery (props) {

  return (
    <SortableList
      axis={'xy'}
      transitionDuration={500}
      items={props.items}
      onSortEnd={({oldIndex, newIndex}) => props.onMoveItem(oldIndex, newIndex)}
    />
  )
}

SortableGallery.propTypes = {
  items: PropTypes.array,
  onMoveItem: PropTypes.func
}

function Item ({value}) {
  return (
      <div className="album-sheets-gallery__sortable__item">
        <img src={value.thumb_url || value.url} />
        <span>#{value.position + 1}</span>
      </div>
  )
}

Item.propTypes = {
  value: PropTypes.object,
  movable: PropTypes.bool
}

export default SortableGallery
