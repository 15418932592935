import React, { Component } from 'react'
import PropTypes from 'prop-types'

export class ElasticInput extends Component {
  wrapper = document.createElement('div')
  mirror = document.createElement('span')

  constructor() {
    super(...arguments)

    this.ref = React.createRef()

    this.state = {
      width: 40
    }

    this.overWrite = this.overWrite.bind(this)
    this.update = this.update.bind(this)
  }

  componentDidMount() {
    this.setupWraper()

    if (this.props.autoFocus) {
      setTimeout(() => this.ref.current.focus(), 250)
    }
  }

  componentWillUnmount() {
    this.wrapper.remove()
  }

  setupWraper() {
    this.wrapper.style.position = 'fixed'
    this.wrapper.style.top = '-999px'
    this.mirror.style.whiteSpace = 'pre'

    this.wrapper.appendChild(this.mirror)
    document.body.appendChild(this.wrapper)

    setTimeout(this.update, 250)
  }

  mirrorStyle() {
    const style = window.getComputedStyle(this.ref.current)

    this.mirror.style['minWidth'] = this.props.minWidth || style.minWidth
    this.mirror.style['maxWidth'] = this.props.maxWidth || style.maxWidth

    {
      const props = [
        'fontFamily',
        'fontSize',
        'fontWeight',
        'fontStyle',
        'letterSpacing',
        'textTransform',
        'wordSpacing'
      ]
      for (const s of props) {
        this.mirror.style[s] = style[s]
      }
    }

    this.mirror.style['paddingLeft'] = style.textIndent

    if (style.boxSizing === 'border-box') {
      const props = [
        'paddingLeft',
        'paddingRight',
        'borderLeftStyle',
        'borderLeftWidth',
        'borderRightStyle',
        'borderRightWidth'
      ]
      for (const s of props) {
        this.mirror.style[s] = style[s]
      }
    }
    else if (style.boxSizing === 'padding-box') {
      const props = ['paddingLeft', 'paddingRight']
      for (const s of props) {
        this.mirror.style[s] = style[s]
      }
    }
  }

  mirr(el) {
    this.mirrorStyle()

    this.mirror.textContent = el.value || el.placeholder || ''
    this.ref.current.style.width = `${this.mirror.offsetWidth + 2}px`
  }

  overWrite(e) {
    const evtName = `on${e.type.charAt(0).toUpperCase()}${e.type.slice(1)}`

    if (typeof this.props[evtName] === 'function') {
      this.props[evtName](...arguments)
    }

    this.mirr(e.target)
  }

  update() {
    this.mirr(this.ref.current)
  }

  render() {
    return (
      <>
        <input ref={this.ref} {...this.props} onInput={this.overWrite}
          onChange={this.overWrite} onFocus={this.overWrite} onBlur={this.overWrite} />
      </>
    )
  }

}

ElasticInput.propTypes = {
  autoFocus: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func
}
