import React from 'react'
import PropTypes from 'prop-types'

import format from 'date-fns/format'
import {   Table, Input, Button, Icon, Tooltip } from 'antd'
import { Link } from 'react-router'

import './style.scss'

const Cover = (cover, collection) => (
  <Link to={`/collection/${collection.id}/review`}>
    <img src={cover} className="collection-list__table__cover" />
  </Link>
)

const Title = (title, collection) => (
  <Link to={`/collection/${collection.id}/review`}>{title}</Link>
)

const PicturesCount = (count) => (
  <span>{count} {+count === 1 ? 'foto' : 'fotos'}</span>
)

const LastAccess = (selections) => {
  if (!selections || !selections.length) {
    return null
  }

  const [lastSelection] = selections

  if (!lastSelection.last_access) {
    return null
  }

  return (
    <span>{format(lastSelection.last_access, 'HH:mm - DD/MM/YYYY')}</span>
  )
}


const Status = ({ id, selections }) => {
  if (!selections) return null

    const status = selections.reduce((all, current) => {
      if (!current.open) all.blue++
      else if (current.expired) all.red++
      else all.yellow++
      return all
    }, { red: 0, yellow: 0, blue: 0 })

    return [
      (status.red ?
      (<Tooltip key={id + '-reds'} title="Seleções expiradas">
        <span className="badge badge-status badge-danger" style={{ marginRight: '4px' }}>
          {status.red > 0 && selections.length > 1 ? status.red : (<i>&nbsp;&nbsp;</i>)}
        </span>
      </Tooltip>) : null),
      (status.blue ?
      (<Tooltip key={id + '-blues'} title="Seleções concluídas">
        <span className="badge badge-status badge-success" style={{ marginRight: '4px' }}>
          {status.blue > 0 && selections.length > 1 ? status.blue : (<i>&nbsp;&nbsp;</i>)}
        </span>
      </Tooltip>) : null),
      (status.yellow ?
      (<Tooltip key={id + '-yellows'} title="Clientes selecionando">
        <span className="badge badge-status badge-warning" style={{ marginRight: '4px' }}>
          {status.yellow > 0 && selections.length > 1 ? status.yellow : (<i>&nbsp;&nbsp;</i>)}
        </span>
      </Tooltip>) : null),
      (!status.yellow && !status.red && !status.blue ?
      (<Tooltip key={id + '-default'} title="Essa coleção ainda não foi enviada para nenhum cliente">
        <span className="badge badge-status badge-default">
          &nbsp;&nbsp;
        </span>
      </Tooltip>) : null)
    ]
}

const Actions = (col) => (
  <div className="collection-list__table__actions">
    <ActionButton title="Editar dados" icon="edit" step="info" colId={col.id} />
    <ActionButton title="Enviar fotos" icon="cloud-upload" step="pictures" colId={col.id} />
    <ActionButton title="Enviar para um cliente" icon="send" step="review?modal=send" colId={col.id} />
    <ActionButton title="Acompanhar seleção" icon="eye" step="review" colId={col.id} />
  </div>
)

const ActionButton = ({ colId, title, icon, step }) => (
  <Tooltip placement="topRight" title={title}>
    <Link to={`/collection/${colId}/${step}`} className="btn btn-xs green">
      <i className={`fa fa-${icon}`} />
    </Link>
  </Tooltip>
)

ActionButton.propTypes = {
  colId: PropTypes.any,
  title: PropTypes.any,
  icon: PropTypes.any,
  step: PropTypes.any,
}


export class CollectionsTable extends React.Component {
  static propTypes = {
    data: PropTypes.any
  }

  constructor (props, context) {
    super(props, context)

    this.searchInput = React.createRef()
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={this.searchInput}
          placeholder={'Nome da coleção'}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 200, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
        >
          Limpar busca
        </Button>
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ float: 'right' }}
        >
          Buscar
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#2ab4c0' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }


  render() {
    const { data } = this.props

    if (data && !data.length) return null

    const pagination = {
      showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} registros`,
      pageSize: 25
    }
    return (
      <Table loading={!data} dataSource={data || []} className="collection-list__table" rowKey={(c) => `ps-c${c.id}`} pagination={pagination} locale={{ emptyText: 'Nenhuma coleção encontrada' }}>
        <Table.Column dataIndex="cover" key="cover" render={Cover}/>
        <Table.Column dataIndex="title" key="title" title="Título" render={Title} />
        <Table.Column key="status" title="Status" render={Status}/>
        <Table.Column dataIndex="pictures_count" key="pictures_count" title="Fotos" render={PicturesCount}/>
        <Table.Column dataIndex="selections" key="last_access" title="Último acesso" render={LastAccess}/>
        <Table.Column title="Ações" key="actions" render={Actions}
      />
      </Table>
    )
  }
}

export default CollectionsTable
