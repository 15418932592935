import { Component } from 'react'
import PropTypes from 'prop-types'

import Auth from 'app/modules/Auth'
import Utils from 'app/modules/Utils'
import fullLogo from 'img/picsize.png'

class Login extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      loading: true
    }

    const { cat, redir } = this.props.location.query
    const { push } = this.props.router
    if (!cat) {
      if (Auth.isAuthenticated) {
        return push('/')
      }
      return window.location = process.env.ACCOUNT_CP
    } else {
      Auth
      .tryLogin(false, false, cat)
      .then((res) => {
        const { firstAccess } = res.user
        if (res.success) {
          if (firstAccess) {

            return window.location = Utils.isMobileOrTablet() ? './wizard/mobile/first-access' : './wizard/first-access'
          }
          if (redir) {
            return window.location = `./${redir}`
          }
          return window.location = './'
        }
        this.setState({ loading: false })
      })
      .catch(() => this.setState({ loading: false }))
    }
  }

  componentDidMount() {
    document.body.classList.add('login')
  }

  render() {
    if (this.state.loading) return null
    return (
      <div>
        <div className="logo">
          <a href="http://painel.picsize.com.br/">
            <img src={fullLogo}  alt="PICSIZE" />
          </a>
        </div>
        <div className="copyright"> PICSIZE &copy; {(new Date()).getFullYear()}</div>
      </div>
    )
  }
}

Login.propTypes = {
  router: PropTypes.object,
  location: PropTypes.object,
}

export default Login
