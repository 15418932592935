import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import {
  Table, Input, InputNumber, Popconfirm, Form, Modal, Button 
} from 'antd';

import Loading from '~/widgets/Loading'
import Api from '~/modules/Api'

import CadForm from './CadForm'

/*class CustomerClients extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      clients: false,
    }
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    try {
        const clients = await Api.get('/customer/clients')
        return this.setState({
            clients: clients.map((e, i) => {
                return {
                    key: i,
                    name: e.name,
                    email: e.email
                }
            })
        })
    }catch(e) {
        console.log(e)
    }
  }

  render() {

    /*const dataSource = [{
        key: '1',
        name: 'Mike',
        age: 32,
        address: '10 Downing Street'
      }];*
      
      const columns = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      }, {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email'
      }/*, {
        title: 'Age',
        dataIndex: 'age',
        key: 'age',
      }, {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
      }*];

    return (
        <Table dataSource={this.state.clients || []} columns={columns} />
    )
  }
}

CustomerClients.propTypes = {
  router: PropTypes.object
}

export default CustomerClients*/






const data = [];

const FormItem = Form.Item;
const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [{
                      required: false,
                      message: `Please Input ${title}!`,
                    }],
                    initialValue: record[dataIndex],
                  })(this.getInput())}
                </FormItem>
              ) : restProps.children}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data, 
      editingKey: '',
      ModalText: 'Um email para criação de nova senha foi enviado ao seu cliente.',
      visible: false,
      modal2Visible: false
    };
    this.columns = [
      {
        title: '#',
        dataIndex: 'id',
        width: '5%',
        editable: false,
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        width: '15%',
        editable: true,
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        width: '15%',
        editable: true,
      },
      {
        title: 'Telefone',
        dataIndex: 'tel',
        width: '15%',
        editable: true,
      },
      {
        title: 'Origem',
        dataIndex: 'origin',
        width: '10%',
        editable: false,
      },

      {
        title: 'Gerar nova senha',
        dataIndex: 'newPass',
        width: '15%',


        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {form => (
                      <a disabled={editingKey !== ''} onClick={() => this.updatePass(record)}>Gerar uma nova senha</a>
                    )}
                  </EditableContext.Consumer>
                </span>
              ) : (
                <a disabled={editingKey !== ''} onClick={() => this.updatePass(record)}>Gerar uma nova senha</a>
              )}
            </div>
          );
        },
      },

      {
        title: 'Operações',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return (
            <div>
              {editable ? (
                <span>
                  <EditableContext.Consumer>
                    {form => (
                      <a
                        href="javascript:;"
                        onClick={() => this.save(form, record.key)}
                        style={{ marginRight: 8 }}
                      >
                        Salvar
                      </a>
                    )}
                  </EditableContext.Consumer>
                  <Popconfirm
                    title="Deseja mesmo cancelar?"
                    onConfirm={() => this.cancel(record.key)}
                  >
                    <a>Cancelar</a>
                  </Popconfirm>
                </span>
              ) : (
                <a disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>Editar</a>
              )}
            </div>
          );
        },
      },
    ];
  }

  showModal() {
    this.setState({
      visible: true,
    });
  }

  handleOk() {
    this.setState({
      visible: false,
    });
  }

  async updatePass(record) {
    event.preventDefault()
  
    //console.log(this.props.hash)
    const result = await Api.passRecovery(`/customer/clients/${record.key}/passRecovery`, record.email, this.props.hash)

    if (result) {
      return this.showModal()
    }
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const newData = [...this.state.data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
        //console.log(newData[index].email)
        this.update(newData[index])
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  async update(newData) {
    try {
      await Api.put(newData, '/customer/clients/')
    } catch (e) {
      console.log(e)
    }
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    try {
        const clients = await Api.get('/customer/clients')
        //console.log(clients)
        return this.setState({
            data: clients.slice(0).reverse().map(e => {
                return {
                    key: e.id,
                    id: e.id,
                    name: e.name,
                    email: e.email,
                    tel: e.tel,
                    origin: e.url_origin == "true" ? "Própria" : "Fotógrafo"
                }
            })
        })
    }catch(e) {
        console.log(e)
    }
  }

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible });
  }


  render() {
    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
        }),
      };
    });

    return (
      <div className="portlet light ">
            <div className="portlet-title">
              <div className="caption">
                <i className="fa fa-folder-open font-dark" />
                <span className="caption-subject font-dark sbold uppercase">
                  Meus Clientes
                </span>
              </div>
              <div className="tools">
                <div className="actions">
                    <span className="btn btn-circle btn-primary green" onClick={() => this.setModal2Visible(true)}>
                      <i onClick={() => this.setModal2Visible(true)} className="fa fa-plus"></i> Novo cliente
                    </span>
               </div>
              </div>
            </div>
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered={false}
          dataSource={this.state.data || []}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            onChange: this.cancel,
          }}
        />

        {
          this.state.visible ? 
                <Modal
                  title="Enviado"
                  visible={this.state.visible}
                  confirmLoading={this.state.confirmLoading}
                  footer={<Button type="primary" onClick={() => this.handleOk()}>OK</Button>}
                  closable={false}
                  maskClosable={false}
                >
                  <p>{this.state.ModalText}</p>
                </Modal> 
                : 
                null
        }

        {
          this.state.modal2Visible ? 
            <Modal
              title="Cadastrar novo usuário."
              centered
              visible={this.state.modal2Visible}
              onOk={() => this.setModal2Visible(false)}
              onCancel={() => this.setModal2Visible(false)}
              footer={null}
              maskClosable={false}
            >
              <CadForm close={() => this.setModal2Visible(false)} reload={() => this.getData()} />
            </Modal>
          :
            null
        }

      </EditableContext.Provider>
      </div>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable