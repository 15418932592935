export const Videos = [
    {
        title: "Cores no Lightroom",
        link: "https://www.youtube.com/embed/UvjdMyb5TVk?origin=http://select.picsize.com.br/knowledge/videos&",
    },
    {
        title: "Lightroom: Exportando com nitidez e qualidade",
        link: "https://www.youtube.com/embed/fC7mz1Z1Fd8?origin=http://select.picsize.com.br/knowledge/videos&",
    },
    {
        title: "Semana do Lightroom Ninja",
        link: "https://www.youtube.com/embed/uTePBaZ6Efw?origin=http://select.picsize.com.br/knowledge/videos&",
    },
    {
        title: "Dominando o pincel do Lightroom",
        link: "https://www.youtube.com/embed/0bdx-P4J8Ms?origin=http://select.picsize.com.br/knowledge/videos&",
    },
    {
        title: "Calibração de Câmeras e HSL",
        link: "https://www.youtube.com/embed/EK9p6fVefSc?origin=http://select.picsize.com.br/knowledge/videos&",
    },
    {
        title: "Perca o medo dos presets!",
        link: "https://www.youtube.com/embed/gMAM4AFxnkQ?origin=http://select.picsize.com.br/knowledge/videos&",
    },
    {
        title: "Como o ninja editaria sua foto?",
        link: "https://www.youtube.com/embed/Z7rGHfK_eDs?origin=http://select.picsize.com.br/knowledge/videos&",
    },
    {
        title: "Live Ninja: Tema secreto",
        link: "https://www.youtube.com/embed/IMOZt86L4Bc?origin=http://select.picsize.com.br/knowledge/videos&",
    },
]