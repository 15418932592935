import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import swal from 'sweetalert'
import { Button, Icon } from 'antd'
import { Subject } from 'rxjs'
import { take } from 'rxjs/operators'

import SendEmail from './SendEmail'
import SendWhatsapp from './SendWhatsapp'
import PreviewMessage from '../../Collection/Review/SelectionList/SelectionModal/PreviewMessage'

class ShareDialog extends Component {
  $send = new Subject()

  constructor(props, context) {
    super(props, context)

    this.state = {
      valid: false,
      clientName: '',
      clientMessage: 'As suas fotos já estão disponíveis!',
      link: props.link || ''
    }

    this.cancel = this.cancel.bind(this)
    this.upState = this.upState.bind(this)
  }

  cancel() {
    swal.close()
  }

  upState(state) {
    this.setState(state)
  }

  render() {
    let icon, text = 'Compartilhar galeria'
    if (this.props.mode === 'whatsapp') {
      icon = 'fa fa-whatsapp'
      text = 'Compartilhar galeria pelo Whatsapp'
    }
    else if (this.props.mode === 'facebook') {
      icon = 'fa fa-facebook-official'
      text = 'Compartilhar galeria por email'
    }
    else if (this.props.mode === 'email') {
      icon = 'fa fa-envelope-o'
      text = 'Compartilhar galeria por email'
    }

    return (
      <div key='share-dialog'>
        <div className="ant-modal-header">
          <div className="ant-modal-title" id="rcDialogTitle0">
            {icon && <i className={icon} />} {text}
          </div>
        </div>
        <button aria-label="Close" className="ant-modal-close" onClick={this.cancel}>
          <span className="ant-modal-close-x">
            <Icon type='close' />
          </span>
        </button>
        <div className='ant-modal-body dialog-row'>
          <div className='dialog-col'>
            {React.Children.map(
              this.props.children,
              child => React.cloneElement(child, {
                data: this.state,
                upState: this.upState,
                $send: this.$send.pipe(take(1))
              })
            )}
          </div>
          <div className='dialog-col'>
            <PreviewMessage data={this.state} />
          </div>
        </div>
        <div className="ant-modal-footer">
          <div>
            <Button onClick={this.cancel}>Cancelar</Button>
            <Button className="grey-salt share-btn" type="primary" onClick={() => this.$send.next()} disabled={!this.state.valid}>
              {icon && <i className={icon} />}
              Enviar
                        </Button>
          </div>
        </div>
      </div >
    )
  }

}

ShareDialog.propTypes = {
  children: PropTypes.any,
  mode: PropTypes.string,
  link: PropTypes.string
}

export default {
  openWhatsapp(props = {}) {
    const content = document.createElement('div')
    ReactDOM.render(<ShareDialog {...props} mode='whatsapp'><SendWhatsapp /></ShareDialog>, content)
    return swal({
      className: 'share-send-dialog',
      content,
      buttons: false,
      closeOnClickOutside: false
    })
  },
  openEmail(props = {}) {
    const content = document.createElement('div')
    ReactDOM.render(<ShareDialog {...props} mode='email'><SendEmail /></ShareDialog>, content)
    return swal({
      className: 'share-send-dialog',
      content,
      buttons: false,
      closeOnClickOutside: false
    })
  },
  openFacebook(props = {}) {
    const content = document.createElement('div')
    ReactDOM.render(<ShareDialog {...props} mode='facebook'><SendWhatsapp /></ShareDialog>, content)
    return swal({
      className: 'share-send-dialog',
      content,
      buttons: false,
      closeOnClickOutside: false
    })
  },
  openOpts(props = {}) {
    const content = document.createElement('div')
    ReactDOM.render(<ShareOptions dialogs={this} props={props} />, content)
    return swal({
      className: 'share-options-dialog',
      content,
      closeOnClickOutside: false,
      buttons: false
    })
  }
}



function ShareOptions({ dialogs, props }) {
  return [
    <div key='dialog-header' className='dialog-header'>
      <div className='dialog-title'>Compartilhar com</div>
      <button autoFocus className='dialog-close-btn' onClick={() => swal.close()}>
        <Icon type='close' />
      </button>
    </div>,
    <div key='dialog-body' className='dialog-body'>
      <div className='list-group'>
        <a className='list-group-item list-group-item-action' onClick={() => dialogs.openWhatsapp(props)}>
          <i className='fa fa-whatsapp' /> Whatsapp
        </a>
        <a className='list-group-item list-group-item-action' onClick={() => dialogs.openEmail(props)}>
          <i className='fa fa-envelope-o' /> E-mail
        </a>
        {/* <div className='list-group-item list-group-item-action'>
          <i className='fa fa-facebook' /> Facebook
        </div> */}
      </div>
    </div>
  ]
}
