import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

import swal from 'sweetalert'

function ModalComments ({data}) {
  const {sheet, reviewers} = data
  return (
    <div className="sheets-modal__comments">
      <div className="sheets-modal__comments__close" onClick={() => swal.close()}>
        <i className="fa fa-times" aria-hidden="true"></i>
      </div>
      <div className="sheets-modal__comments__title">
        Lâmina #{sheet.position + 1} - Comentários
      </div>
      {sheet.reviews.map((review, i) => {
        if (!review.comment) return null
        review.reviewer = reviewers[review.reviewer_id]
        return (<CommentItem key={`smci-${i}`} review={review} />)
      })}
    </div>
  )
}
function CommentItem ({review}) {
  return (
    <div className="sheets-modal__comments__item">
      <div className="sheets-modal__comments__item__header">
        <span>{review.reviewer.name}</span>
        <span>{review.reviewer.email}</span>
        <span>{format(review.created_at, 'DD/MM/YYYY | HH:mm')}</span>
      </div>
      <p>
        {review.comment}
      </p>
    </div>
  )
}

ModalComments.propTypes = {
  data: PropTypes.any
}

export default ModalComments
