import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import { Button, Icon } from 'antd';

import './ReachedLimitDialog.scss'

class ReachedLimitDialog extends Component {
    gallery = null
    editing = false
    modelHasChange = false

    constructor(props, context) {
        super(props, context)
    }

    cancel() {
        swal.close()
    }

    render() {
        return (
            <div key='adder-dialog'>
                <div className={`ant-modal-body text-center`}>
                    <h2 style={{margin: '0'}}>{this.props.title}</h2>
                    <p>{this.props.message}</p>
                </div>
                <div className="ant-modal-footer">
                    <div>
                        <Button onClick={this.cancel}>Cancelar</Button>
                    </div>
                </div>

            </div>
        )
    }

}

ReachedLimitDialog.propTypes = {}

export default {
    open(props) {
        const content = document.createElement('div')
        ReactDOM.render(<ReachedLimitDialog {...props} />, content)
        return swal({
            icon: 'error',
            dangerMode: true,
            className: 'reached-limit-dialog',
            content,
            buttons: false,
            closeOnClickOutside: false
        })
    }
}
