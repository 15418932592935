import React, {Component} from 'react'
import toastr from 'toastr'

import Api from '~/modules/Api'
import Loading from '~/widgets/Loading'

import CollectionSteps from './common/Steps'
import CollectionInfoForm from './common/InfoForm'


class CollectionInfo extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isSubmiting: false,
      form: {title: '', description: ''},
      collection: false
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getData = this.getData.bind(this)
    this.goToStep = this.goToStep.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    const data = await Api.get(`/collection?id=${this.props.params.id}`)
    if (data.id) {
      return this.setState({collection: data, form: {title: data.title, description: data.description}})
    }
    this.props.router.push('/collection/list')
  }

  goToStep (stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/collection/${this.props.params.id}/${stepName}`)
  }

  handleChange(e) {
    const formState = this.state.form
    formState[e.target.name] = e.target.value
    this.setState({form: formState})
  }

  async processForm(event) {
    event.preventDefault()

    this.setState({isSubmiting: true})

    const {id} = this.state.collection
    const {title, description} = this.state.form
    const result = await Api.post('/collection/update', {id, title, description})

    this.setState({isSubmiting: false})

    if (result && result.success) {
      toastr['success']('Dados da coleção atualizados com sucesso!')
      return
    }
    toastr['error']('Erro ao atualizar dados da coleção')
  }

  render() {
    return (
      <div className="row">
      {
        !(this.state.collection) ?
        <Loading /> :
        <div>
          <CollectionSteps
            currentStep={1}
            reachedStep={this.state.collection.step}
            onClickStep={this.goToStep}
          />
          <div className="col-md-12">
            <div className="portlet light">
              <div className="portlet-title">
                <div className="caption">
                  <i className="fa fa-photo font-dark" />
                  <span className="caption-subject font-dark sbold uppercase">
                    Dados da Coleção
                  </span>
                </div>
              </div>
              <div className="portlet-body form row">
                <div className="col-md-12 col-md-offset-0 col-lg-8 col-lg-offset-2">
                  <CollectionInfoForm
                    isSubmiting={this.state.isSubmiting}
                    title={this.state.collection.title}
                    description={this.state.collection.description}
                    handleChange={this.handleChange}
                    onSubmit={this.processForm}
                  />
                </div>
            </div>
          </div>
        </div>
        </div>
      }
    </div>
    )
  }
}

export default CollectionInfo
