import React from 'react'
import Dropzone from 'react-dropzone'

import UploadContext from './context'

export default function UploadButton () {
  return (
    <UploadContext.Consumer>
    {(upload) => {
      if (!upload.isUploading) {
        return (
          <Dropzone onDrop={upload.onDrop} accept="image/jpeg, image/png" style={{cursor: 'pointer', display: 'inline-block'}}>
            <span className="btn btn-primary" style={{backgroundColor: '#26C281', border: 'none'}}>
              <i className="fa fa-upload"></i>
            </span>
          </Dropzone>
        )
      }
      return (
        <span className="btn btn-primary" style={{backgroundColor: '#32c5d2', border: 'none'}}>
          <i className="fa fa-circle-o-notch fa-spin fa-fw"></i> Enviando fotos
        </span>
      )
    }}
    </UploadContext.Consumer>
  )
}
