import React, {Component} from 'react'
import ReactGA from 'react-ga'

import Api from 'app/modules/Api'
import Auth from '~/modules/Auth'
import ReachedLimitDialog from '~/widgets/ReachedLimitDialog/ReachedLimitDialog'
import CustomerService from '~/modules/CustomerService'

import CollectionSteps from './common/Steps'
import CollectionInfoForm from './common/InfoForm'


class NewCollection extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      isSubmiting: false,
      form: {title: '', description: ''}
    }

    this.processForm = this.processForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount(){
    const { permission } = await Api.get('/collection/permission?collection_type=selection')
      if(!permission){
        ReachedLimitDialog.open({
          title: 'Limite atingido',
          message: `O limite de ${Auth.userData.subscription.limits.sel_max_collections} seleções do seu plano foi atingido.`
        })
        CustomerService.pushReachedPlanLimitLog('selection')
        return this.props.router.push('/collection/list')
      }
  }

  handleChange(e) {
    const formState = this.state.form
    formState[e.target.name] = e.target.value
    this.setState({form: formState})
  }

  async processForm(event) {
    event.preventDefault()
    this.setState({isSubmiting: true})

    const {push} = this.props.router
    const {title, description} = this.state.form
    const result = await Api.post('/collection/create', {title, description})

    this.setState({isSubmiting: false})

    
    if (result && result.success) {
      ReactGA.event({
        category: 'Seleção',
        action: 'Criou uma nova coleção'
      })
      CustomerService.refreshStats()
      return push(`/collection/${result.collection.id}/pictures`)
    }
    else if (result.error && result.error === 'ReachedLimitError') {
      await ReachedLimitDialog.open({
        title: 'Limite atingido',
        message: `O limite de ${result.limit} seleções do seu plano foi atingido.`
      })
    }
    return push('/collection/list')
  }

  render() {
    return (
      <div className="row">
        <CollectionSteps currentStep={1} reachedStep={1} />
        <div className="col-md-12">
          <div className="portlet light">
            <div className="portlet-title">
              <div className="caption">
                <i className="fa fa-photo font-dark" />
                <span className="caption-subject font-dark sbold uppercase">
                  Dados da Seleção
                </span>
              </div>
            </div>
            {
              /*
              <div className="alert alert-danger">
                Estamos fazendo importantes atualizações na plataforma, pedimos desculpas pelo transtorno. Volte mais tarde.
              </div>
              */
            }
            <div className="portlet-body form row">
              <div className="col-md-12 col-md-offset-0 col-lg-8 col-lg-offset-2">
                <CollectionInfoForm
                  isNew={true}
                  handleChange={this.handleChange}
                  onSubmit={this.processForm}
                  isSubmiting={this.state.isSubmiting}
                />
              </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

export default NewCollection
