import React from 'react'
import PropTypes from 'prop-types'
import {Tooltip} from 'antd'

import ColorPicker from '~/widgets/ColorPicker'


function CollectionColors (props) {
  return (
    <form className="form-horizontal" role="form">
    <div className="form-body" id="collection-collors">
      <div className="form-group">
        <label className="col-md-6 control-label">
        <Tooltip title="Para fonte mais clara, escolha background mais escuro e vice-versa">
          Background <i className="fa fa-question-circle" />
        </Tooltip>
        </label>
        <div className="col-md-6">
            <ColorPicker color={props.bgColor} updateColor={(hex) => props.updateColor('bg', hex)} />
        </div>
      </div>
      <div className="form-group">
        <label className="col-md-6 control-label">
        <Tooltip title="Para background mais escuro, escolha fonte mais clara e vice-versa">
          Fonte <i className="fa fa-question-circle" />
        </Tooltip>
        </label>
        <div className="col-md-6">
            <ColorPicker color={props.fontColor} updateColor={(hex) => props.updateColor('font', hex)} />
        </div>
      </div>
    </div>
    {props.children}
  </form>)
}

CollectionColors.propTypes = {
  updateColor: PropTypes.func,
  bgColor: PropTypes.string,
  fontColor: PropTypes.string,
  children: PropTypes.object
}

export default CollectionColors
