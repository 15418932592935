import Pica from 'pica/dist/pica.js'
import filterJPEG from './util/filter-jpeg'

const pica = Pica()
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const isEdge = (/Edge/.test(navigator.userAgent)
|| (navigator.userAgent.indexOf('MSIE') > 0))

const JPEG_QUALITY = 0.9
const MIN_FILE_SIZE = 500000 // 500kb

async function processFile (file, fitIn) {
  console.log(file.size, file.size < MIN_FILE_SIZE)
  if (file.size < MIN_FILE_SIZE) return file
  try {
    const {img, header} = await loadImage(file)
    const processed = await processImg(img, fitIn)
    if (!processed) return file
    return await canvasToFile(file, processed, header)
  } catch (e) {
    console.error(e)
    return file
  }
}


function createCanvas (img, fitIn) {
  const canvas = document.createElement('canvas')

  if (!fitIn) {
    canvas.width = img.width
    canvas.height = img.height
  } else if (img.width > img.height) {
    canvas.width = Math.min(fitIn, img.width)
    canvas.height = img.height * canvas.width / img.width
  } else {
    canvas.height = Math.min(fitIn, img.height)
    canvas.width = img.width * canvas.height / img.height
  }
  return canvas
}

async function processImg (img, fitIn, attempt = 1) {
  if (attempt > 3) return false
  try {
    const canvas = createCanvas(img, fitIn)

    return await pica.resize(img, canvas, {

      // unsharpAmount: 80,
      // unsharpRadius: 0.6,
      // unsharpThreshold: 2,
    })
  } catch (e) {
    await sleep(500)
    return await processImg(img, fitIn, attempt + 1)
  }
}

function loadImage (file) {
  return new Promise((resolve) => {
    const img = document.createElement('img')
    const reader = new FileReader()
    reader.onloadend = function () {
      img.src = window.URL.createObjectURL(file)
      resolve({img, header: getJPEGHeader(reader.result)})
    }
    reader.readAsArrayBuffer(file)
  })
}

function sleep (ms) {
  return new Promise((resolve) => {
    return setTimeout(resolve, ms)
  })
}

async function canvasToFile (originalFile, processedCanvas, jpegHeader) {
  let blob = await pica.toBlob(processedCanvas, originalFile.type || originalFile.mimetype, JPEG_QUALITY)
  if (jpegHeader) {
    const slice = originalFile.slice || originalFile.webkitSlice || originalFile.mozSlice
    const jpegBody = slice.call(blob, 20)
    // remove JPEG header (2 bytes) and JFIF segment (18 bytes),
    // assuming JFIF is always present and always the same in all
    // images from canvas

    blob = new Blob([jpegHeader, jpegBody] , {type: originalFile.type})
  }

  await sleep(250)

  if (isSafari || isEdge) {
    blob.lastModifiedDate = new Date()
    blob.name = originalFile.name
    return blob
  }

  try {
    const p =  new File([blob], originalFile.name, {type: originalFile.type, lastModified: Date.now()})
    return p
  } catch (e) {
    console.log(e)
    return blob
  }

}

function getJPEGHeader (jpeg) {
  const fileData = new Uint8Array(jpeg)
  if (fileData[0] === 0xFF && fileData[1] === 0xD8) {
    // only keep comments and exif in header
    const filter = filterJPEG({
      removeComments: true,
      removeImage: true,
      filter:      true,
      removeICC:   false
    })

    try {
      filter.push(fileData)
      filter.end()
    } catch (err) {
      return false
    }

    const tmp = arrayConcat(filter.output)

    // cut off last 2 bytes (EOI, 0xFFD9),
    // they are always added by filter_jpeg on end
    return tmp.subarray(0, tmp.length - 2)
  }
  return false
}

// Concatenate multiple Uint8Arrays
//
function arrayConcat(list) {
  let size = 0
  let pos = 0

  for (let i = 0; i < list.length; i++) {
    size += list[i].length
  }

  let result = new Uint8Array(size)

  for (let i = 0; i < list.length; i++) {
    result.set(list[i], pos)
    pos += list[i].length
  }

  return result
}

export default {
  processFile
}
