import { Subject, of, merge, from } from 'rxjs'
import { delay, filter, switchMap, tap, map } from 'rxjs/operators'

import Api from 'app/modules/Api'

class GalleryService {
  $update = new Subject()

  getGalleries() {
    return from(Api.get('/collection?collection_type=gallery'))
  }

  getGallery(id) {
    return from(Api.get(`/gallery/${id}`))
  }

  getLink(id) {
    return from(Api.get(`/gallery/${id}/links`)).pipe(
      map(res => Array.isArray(res) && typeof res[0] === 'object' ? res[0] : [])
    )
  }

  async getWatermark() {
    return await Api.get('/customer/settings/collection_watermark')
  }

  async getPicture(id) {
    const res = await Api.get(`/collection/picture?id=${id}`)
    if (res && res.success && res.picture) {
      return res.picture
    }
    else {
      return null
    }
  }

  getPictures(collId) {
    return from(Api.get(`/collection/pictures?id=${collId}`))
  }

  $getPictures(collId) {
    const $update = this.$update.pipe(
      filter(k => k === 'pictures'),
      switchMap(() => this.getPictures(collId)),
      delay(500)
    )
    return merge(this.getPictures(collId), $update)
  }

  async createGallery(model) {
    if (!model) {
      return of(false).pipe(delay(500)).toPromise()
    }

    const res = await Api.post('/collection/create', {
      title: model.title,
      event_date: model.eventDate || null,
      collection_type: 'gallery'
    })

    return res
  }

  async updateGallery(model) {
    if (!model) {
      return Promise.resolve(false)
    }

    const res = await Api.post('/collection/update', model)
    return res
  }

  updateLink(model) {
    return from(Api.post('/gallery/link', model))
  }

  deleteGallery(id) {
    return Api.post('/gallery', { id, action: 'delete' })
  }

  deletePictures(collId, picturesIds) {
    return from(Api.post('/collection/pictures', { id: collId, action: 'delete', pictures: picturesIds })).pipe(
      tap(() => this.$update.next('pictures'))
    )
  }

}

export default new GalleryService()
