import React, {Component} from 'react'
import {Link} from 'react-router'
import PropTypes from 'prop-types'
import Loading from 'app/widgets/Loading'
import Api from 'app/modules/Api'
import Auth from 'app/modules/Auth'
import CustomerService from 'app/modules/CustomerService'
import ReachedLimitDialog from '../../../widgets/ReachedLimitDialog/ReachedLimitDialog'
import ListItem from './Item'

class AlbumList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      albums: false,
      maxAlbumLimitReached: false,
    }

    this.getData = this.getData.bind(this)
    this.goToStep = this.goToStep.bind(this)
    this.add = this.add.bind(this)
  }

  componentDidMount () {
    this.getData()
  }

  async getData () {
    const { permission } = await Api.get('/album/permission')
    if (!permission) this.setState({ maxAlbumLimitReached: true })

    const {albums} = await Api.get('/revise/album')
    if (albums && Array.isArray(albums)) {
      albums.sort((a, b) => {
        if (a.created_at > b.created_at) return -1
        if (a.created_at < b.created_at) return 1
        return 0
      })
      return this.setState({albums})
    }

  }

  goToStep (album, stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/album/${album}/${stepName}`)
  }

  async add(){
    if(this.state.maxAlbumLimitReached){
      CustomerService.pushReachedPlanLimitLog('album')
      return await ReachedLimitDialog.open({
        title: 'Limite atingido',
        message: `O limite de ${Auth.userData.subscription.limits.rev_max_albums} albuns do seu plano foi atingido.`
      })
    }

    this.props.router.push('/album/new')
  }

  render() {
    return (
      <div className="row">
        {
          !(this.state.albums) ?
          <Loading /> :
        <div className="col-md-12">
          <div className="portlet light" style={{maxWidth: '1380px', margin: 'auto'}}>
            <div className="portlet-title">
              <div className="caption">
                <i className="fa fa-folder-open font-dark" />
                <span className="caption-subject font-dark sbold uppercase">
                  Meus álbuns
                </span>
              </div>
              <div className="tools">
                <div className="actions">
                  <Link onClick={this.add}>
                    <span className="btn btn-circle btn-primary green">
                      <i className="fa fa-plus"></i> Novo álbum
                    </span>
                  </Link>
               </div>
              </div>
            </div>
            <div className="portlet-body">
              {
                !this.state.albums.length ?
                <CreateFirst /> :
                <div className="table-scrollable table-scrollable-borderless">
                  <table className="table table-hover table-light">
                    <thead>
                      <tr role="row">
                        <th></th>
                        <th>Álbum</th>
                        <th>Status</th>
                        <th style={{textAlign: 'center'}}>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.albums.map(
                        (a, key) => (
                          <ListItem key={key} goToStep={this.goToStep} album={a} />
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        </div>
        }
      </div>
    )
  }
}

function CreateFirst () {
  return (
    <div className="portlet-body" style={{minHeight: '300px', padding: '50px 0'}}>
      <div className="text-center">
        <p style={{fontSize: '64px'}}>
          <i className="fa fa-book" />
        </p>
        <h3>
          Você ainda não possui nenhum álbum!
        </h3>
        <h4>
          <a href="/album/new">
            Clique aqui
          </a> para cadastrar o primeiro
        </h4>
      </div>
    </div>
  )
}

AlbumList.propTypes = {
  router: PropTypes.object
}

export default AlbumList
