/* eslint-disable max-statements */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import sum from 'lodash/sum'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import some from 'lodash/some'
import mapValues from 'lodash/mapValues'
import toastr from 'toastr'
import swal from 'sweetalert'
import ReactGA from 'react-ga'

import CustomerService from 'app/modules/CustomerService'
import Api from 'app/modules/Api'
import Picture from 'app/modules/Picture'
import Loading from 'app/widgets/Loading'
import Toasty from 'app/widgets/Toasty'

import CollectionSteps from '../common/Steps'

import PicturesUpload from './Upload'
import PicturesList from './List'
import MetadataListItem from './MetadataListItem'
// import ProcessingStatus from '../Review/ProcessingStatus'

import './batch-suggestions.scss'

const CHUNK_SIZE = 6
const PAGE_SIZE = 25

function formatBytes(bytes, decimals) {
  if (bytes == 0) return '0 Bytes'
  let k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

function sortPictures(pictures, type = 1) {
  // {id: 1, name: 'Data de envio [A-Z]'},
  // {id: 2, name: 'Data de envio [Z-A]'},
  // {id: 3, name: 'Nome do arquivo [A-Z]'},
  // {id: 4, name: 'Nome do arquivo [Z-A]'},
  // {id: 5, name: 'Data de criação da foto [A-Z]'},
  // {id: 6, name: 'Data de criação da foto [Z-A]'}
  // {id: 7, name: 'Numeração da foto [Z-A]'}
  // {id: 8, name: 'Numeração da foto [Z-A]'}
  if (!pictures) return []

  if (type === 1) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.created_at < b.created_at) return -1
      if (a.created_at > b.created_at) return 1
      return 0
    })
  }
  if (type === 2) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.created_at > b.created_at) return -1
      if (a.created_at < b.created_at) return 1
      return 0
    })
  }
  if (type === 3) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1
      return 0
    })
  }
  if (type === 4) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.title.toLowerCase() > b.title.toLowerCase()) return -1
      if (a.title.toLowerCase() < b.title.toLowerCase()) return 1
      return 0
    })
  }
  if (type === 5) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.file_date < b.file_date) return -1
      if (a.file_date > b.file_date) return 1
      return 0
    })
  }
  if (type === 6) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      if (a.file_date > b.file_date) return -1
      if (a.file_date < b.file_date) return 1
      return 0
    })
  }
  if (type === 7) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      const aTitle = parseInt(a.title.toLowerCase().replace(/\D/g, ''))
      const bTitle = parseInt(b.title.toLowerCase().replace(/\D/g, ''))
      if (aTitle < bTitle) return -1
      if (aTitle > bTitle) return 1
      return 0
    })
  }
  if (type === 8) {
    return pictures.sort((a, b) => {
      if (!b) return 0
      const aTitle = parseInt(a.title.toLowerCase().replace(/\D/g, ''))
      const bTitle = parseInt(b.title.toLowerCase().replace(/\D/g, ''))
      if (aTitle > bTitle) return -1
      if (aTitle < bTitle) return 1
      return 0
    })
  }
  return pictures
}

class CollectionPictures extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      pictureListKey: Math.random(), // hack to force component update
      checkUnprocessedCount: 0,
      collection: false,
      files: [],
      pictures: [],
      filters: [],
      filtersMap: new Map(),
      pagePictures: false,
      page: 1,
      pageCount: 1,
      isUploading: false,
      originalTitle: '',
      isProcessing: false,
      progress: { total: 0, done: 0 }
    }
    this.onDrop = this.onDrop.bind(this)
    this.startUpload = this.startUpload.bind(this)
    this.goToStep = this.goToStep.bind(this)
    this.getData = this.getData.bind(this)
    this.checkSpace = this.checkSpace.bind(this)
    this.updateProgress = this.updateProgress.bind(this)
    this.updatePicturesList = this.updatePicturesList.bind(this)
    this.checkUnprocessedTimeout = this.checkUnprocessedTimeout.bind(this)
    this.cancelUpload = this.cancelUpload.bind(this)
    this.changePage = this.changePage.bind(this)
    this.processPictures = this.processPictures.bind(this)
    this.changeSort = this.changeSort.bind(this)
    this.deletePictures = this.deletePictures.bind(this)
    this.watermarkOptions = this.watermarkOptions.bind(this)
    this.suggest = this.suggest.bind(this)
    this.suggestFilter = this.suggestFilter.bind(this)
    this.batchSuggest = this.batchSuggest.bind(this)

    this.unregisterLeaveHook = this.props.router.setRouteLeaveHook(
      this.props.route,
      this.routerWillLeave.bind(this)
    )
  }

  routerWillLeave(nextLocation) {
    if (!this.state.isUploading) return true
    const sure = window.confirm('Envio de fotos em progresso! Ao sair da página o envio das fotos será cancelado.')
    if (sure) {
      window.location.href = nextLocation.pathname
    }
    return sure
  }

  componentDidMount() {
    this.setState({ originalTitle: document.title.toString() })
    this.getData()
    window.onbeforeunload = () => {
      return this.state.isUploading ?
        'Envio de fotos em progresso! Ao sair da página o envio das fotos será cancelado.' :
        undefined
    }
  }

  componentWillUnmount() {
    this.unregisterLeaveHook()
  }

  updateProgress(progress) {
    progress = Object.assign(this.state.progress, progress)
    this.setState({
      isUploading: (progress.total !== progress.sent) || (progress.total > 0 && !progress.allChunksSent),
      progress
    })
  }

  async getData() {
    const [collection, settings, stats] = await Promise.all([
      Api.get(`/collection?id=${this.props.params.id}`),
      Api.get('/customer/settings/collection_watermark'),
      Api.get('/customer/stats')
    ])
    if (collection.id) {
      this.updatePicturesList(collection)
      return this.setState({
        stats,
        collection,
        use_watermark: settings.use_watermark === 'true',
        use_antiCopy: settings.use_antiCopy === 'true'
      })
    }
    this.props.router.push('/collection/list')
  }

  async cancelUpload() {
    this.setState({ isUploading: false })
    Api.abortUploads()
    window.location.reload()
  }

  changePage(page, pictures = false) {
    const pages = chunk(pictures || this.state.pictures, PAGE_SIZE)
    if (!pages.length) {
      return this.setState({
        pagePictures: [],
        page,
        pageCount: 1,
        picturesCount: 0,
        pictureListKey: Math.random()
      })
    }
    return this.setState({
      page,
      pageCount: pages.length,
      picturesCount: (pictures || this.state.pictures).length,
      pagePictures: pages[page - 1] || pages[0],
      pictureListKey: Math.random()
    })
  }

  async changeSort(pictures_ordenation) {
    if (isNaN(pictures_ordenation)) return
    this.setState({
      pictures: sortPictures(this.state.pictures, pictures_ordenation),
      collection: {
        ...this.state.collection,
        pictures_ordenation
      }
    })

    const { id } = this.state.collection
    const result = await Api.post('/collection/update', { id, pictures_ordenation })
    if (result && result.success) {
      toastr['success']('Ordenação alterada!')
    }

    ReactGA.event({
      category: 'Seleção',
      action: 'Alterou a ordenação das fotos uma coleção'
    })

    return this.changePage(this.state.page)
  }

  async updatePicturesList(collection = this.state.collection) {
    let pictures = await Api.get(`/collection/pictures?id=${this.props.params.id}`)
    pictures = sortPictures(pictures, collection.pictures_ordenation)
    this.makeFilters(pictures)
    this.setState({ pictures })
    this.changePage(this.state.page, pictures)
    this.checkUnprocessedTimeout()
  }

  checkUnprocessedTimeout() {
    let timeout = 5000
    if (this.state.isUploading) return
    if (this.state.checkUnprocessedCount > 10) {
      timeout = timeout * 10
      this.setState({ checkUnprocessedCount: 0 })
    }
    setTimeout(() => {
      if (this.state.pictures.filter((p) => p.image_processing).length > 0) {
        this.setState({ checkUnprocessedCount: this.state.checkUnprocessedCount + 1 })
        this.updatePicturesList()
      }
    }, timeout)
  }

  makeFilters(pictures) {
    const filtersMap = new Map()
    let arr, xmp

    for (const pic of pictures) {
      if (typeof pic.metadata !== 'object') continue
      arr = []
      xmp = pic.metadata.xmp || {}
      if (xmp.rating) {
        arr.push({ key: `${xmp.rating} Estrela${xmp.rating === 1 ? '' : 's'}`, type: 'rating', value: xmp.rating })
        pic.rating = xmp.rating
      }
      if (xmp.label) {
        arr.push({ key: `${xmp.label}`, type: 'label', value: xmp.label })
        pic.label = xmp.label
      }
      for (const i of arr) {
        if (filtersMap.has(i.key)) {
          filtersMap.get(i.key).pictures.push(pic)
        } else {
          filtersMap.set(i.key, { ...i, pictures: [pic] })
        }
      }
      pic._filters = arr.map(i => i.key)
    }

    const suggestedMetadatas = this.state.collection.suggested_metadatas || []
    // eslint-disable-next-line curly
    for (const k of suggestedMetadatas) if (filtersMap.has(k)) {
      filtersMap.get(k).selected = true
    }

    const values = Array.from(filtersMap.values())
    const filters = []
      .concat(
        values
          .filter(i => i.type === 'rating')
          .map(i => i.key)
          .sort((a, b) => a < b ? 1 : (a > b ? -1 : 0)),
        values
          .filter(i => i.type === 'label')
          .map(i => i.key)
          .sort((a, b) => a < b ? -1 : (a > b ? 1 : 0))
      )
      .map(key => filtersMap.get(key))

    this.setState({ filtersMap, filters })
  }

  checkUnusedFilters(picture) {
    let filter, pic, has_suggestion
    for (const k of picture._filters) {
      filter = this.state.filtersMap.get(k)
      if (!filter.selected) continue
      has_suggestion = false
      for (pic of filter.pictures) {
        if (pic.suggested) {
          has_suggestion = true
          break
        }
      }
      filter.selected = has_suggestion
    }
  }

  suggest(picture) {
    if (!picture._filters) return

    this.checkUnusedFilters(picture)
    this.setState({ filters: this.state.filters })
    this.saveSuggestion([{ id: picture.id, suggested: picture.suggested }])
  }

  suggestFilter(i) {
    i.selected = !i.selected

    const changes = []
    let count = 0
    for (const pic of i.pictures) {
      if (pic.suggested !== i.selected) count++
      pic.suggested = i.selected
      changes.push({ id: pic.id, suggested: pic.suggested })
      this.checkUnusedFilters(pic)
    }

    this.setState({
      filters: this.state.filters,
      pagePictures: this.state.pagePictures,
      pictureListKey: Math.random()
    })

    const suggestedMetadatas = this.state.filters.filter(f => f.selected).map(i => i.key)

    this.saveSuggestion(changes, suggestedMetadatas)

    let icon, msg = `${count} `
    if (count === 0) {
      if (i.selected) {
        icon = 'fa-star'
        msg = 'As fotos com esse metadado já haviam sido sugeridas.'
      }
      else {
        icon = 'fa-star-o'
        msg = 'A sugestão foi desfeita.'
      }
    }
    else if (count > 1) {
      if (i.selected) {
        icon = 'fa-star'
        msg += 'fotos foram sugeridas.'
      }
      else {
        icon = 'fa-star-o'
        msg += 'sugestões foram desfeitas.'
      }
    }
    else {
      if (i.selected) {
        icon = 'fa-star'
        msg += 'foto foi sugerida.'
      }
      else {
        icon = 'fa-star-o'
        msg += 'sugestão foi desfeita.'
      }
    }

    Toasty.toast(msg, { icon })
  }

  async saveSuggestion(suggestions, suggested_metadatas) {
    if ((!Array.isArray(suggestions) || !suggestions.length) && !Array.isArray(suggested_metadatas)) {
      return
    }
    if (Array.isArray(suggested_metadatas) && !suggested_metadatas.length) {
      suggested_metadatas = ['']
    }
    await Api.post('/collection/suggest', {
      id: this.props.params.id,
      suggestions,
      suggested_metadatas
    })
  }

  batchSuggest() {
    const content = document.createElement('div')

    const _render = () => {
      const isEmpty = !this.state.filters || !this.state.filters.length

      ReactDOM.render([
        <div className={`dialog-content ${isEmpty ? 'empty-list' : ''}`} key='filter-dialog'>
          {isEmpty ?
            <div className='alert alert-warning'>
              Não encontramos nenhuma foto com Avaliação de Estrelas ou Rótulo de Cor definidos no Lightroom.
              Verifique se no momento da exportação você optou por manter todos os metadados da foto.
            </div>
            :
            <div>
              <div className='alert alert-warning'>
                Identificamos automaticamente as marcações que você definiu no Lightroom,
                logo você pode sugerir fotos em lote, pela avaliação em estrelas ou pelo rótulo de cor.
                </div>
              <div className='filter-list' key='filter-dialog'>
                {this.state.filters.map((i, k) => <MetadataListItem
                  key={`filter-${k}`}
                  item={i}
                  itemId={k}
                  onSelect={() => { this.suggestFilter(i); _render() }}
                />
                )}
              </div>
            </div>
          }
        </div>
      ], content)
    }
    _render()

    window.addEventListener('resize', _render)

    swal({
      className: 'swal-batch-filters',
      title: 'Sugerir fotos que contenham',
      content,
      buttons: {
        finish: {
          text: 'Concluir'
        }
      }
    })
      .then(() => window.removeEventListener('resize', _render))
  }

  async startUpload(files) {
    const hasCover = !this.state.collection || (this.state.collection && this.state.collection.coverKey)
    if (!hasCover) {
      files[0].setCover = true
    }

    let totalBytes = sum(map(files, ({ size }) => size))
    let sentBytes = {}

    const mainProgress = { total: files.length, totalBytes: formatBytes(totalBytes, 2), pictures_size: totalBytes, progress: 0, sent: 0, sentBytes: 0, allChunksSent: false }
    this.updateProgress(mainProgress)
    this.setState({ files })

    ReactGA.event({
      category: 'Seleção',
      action: 'Upload de fotos iniciado',
      value: files.length
    })

    const chunks = chunk(files, CHUNK_SIZE)
    const { id } = this.state.collection
    let succ = 0, fail = 0
    for (let chunkIndex in chunks) {
      let chunk = chunks[chunkIndex]
      const chunkPics = chunk.map(async (picture, picIndex) => {
        const res = await Api.upload('/collection/pictures', { id, setCover: picture.setCover }, [picture], (bytes) => {
          sentBytes[chunkIndex + '-' + picIndex] = bytes
          mainProgress.sentBytes = sum(map(sentBytes, (sent) => sent))
          mainProgress.progress = mainProgress.sentBytes / totalBytes
          document.title = `(${parseInt(mainProgress.progress * 100)}%) ${this.state.originalTitle}`
          mainProgress.sentBytes = formatBytes(mainProgress.sentBytes, 2)
          return this.updateProgress(mainProgress)
        })
        mainProgress.sent = mainProgress.sent + 1
        return res
      })
      const results = await Promise.all(chunkPics)
      const [s, f] = results
        .filter(r => typeof r === 'object')
        .reduce((p, c) => [p[0] + (c.success || 0), p[1] + (c.fail || 0)], [0, 0])
      succ += s
      fail += f
      mainProgress.done = mainProgress.sent
      this.updateProgress(mainProgress)
    }
    document.title = this.state.originalTitle.toString()
    this.updateProgress({ allChunksSent: true })

    ReactGA.event({
      category: 'Seleção',
      action: 'Upload de fotos finalizado',
      value: files.length
    })
    let msg = ''
    if (succ) msg += `${succ} ${succ === 1 ? ' foto foi enviada' : 'fotos foram enviadas'}`
    if (fail) {
      if (msg) msg += ' e '
      msg += `${fail} ${fail === 1 ? 'foto não foi reconhecida' : 'fotos não foram reconhecidas'}`
    }

    swal('Envio de fotos concluído com sucesso!', msg, { icon: 'success', })
    Api.get(`/collection/info?collection_id=${this.state.collection.id}`)
    Api.post('/collection/patch/increment', { 
      collection_type: 'selection', 
      pictures_count: this.state.progress.total,
      pictures_size: this.state.progress.pictures_size/1024/1024
    })
    this.updatePicturesList()
  }


  async deletePictures(pictures, pictures_size) {
    const willDelete = await swal({
      title: `Deletar ${pictures.length > 1 ? 'fotos' : 'foto'}?`,
      text: `Tem certeza que deseja deletar ${pictures.length > 1 ? pictures.length + ' fotos' : 'a foto'} da coleção?`,
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: `Deletar ${pictures.length > 1 ? 'fotos' : 'foto'}`
      }
    })
    if (willDelete) {
      const { id } = this.state.collection
      await Api.post('/collection/pictures', { id, action: 'delete', pictures })
      toastr['success']('Imagens removidas!')

      ReactGA.event({
        category: 'Seleção',
        action: 'Fotos deletadas na coleção',
        value: pictures.length
      })
      Api.get(`/collection/info?collection_id=${this.state.collection.id}`)

      Api.post('/collection/patch/decrement', { 
        collection_type: 'selection', 
        pictures_count: pictures.length,
        pictures_size: pictures_size.reduce((total, size) => (+total) + (+size), 0)/1024/1024
      })
      this.updatePicturesList()
    }
  }

  async checkSpace(files) {
    const requested = files.length
    const stats = await Api.get('/customer/stats')
    const max_picture_size = (+stats.subscription.limits.sel_max_picture_size) * 1048576
    const limit = (+stats.subscription.limits.sel_max_pictures) || 100000000
    const used = +stats.usage.sel_pictures
    const available = (limit - used) > 0 ? limit - used : 0
    return {
      requested,
      max_picture_size,
      max_picture_size_limit_key: 'sel_max_picture_size',
      max_picture_dimension: +stats.subscription.limits.sel_max_picture_dimension,
      limit,
      used,
      available,
      hasEnough: available >= requested
    }
  }

  async onDrop(files) {
    const space = await this.checkSpace(files)
    if (!space.hasEnough) return notEnoughLimits(space)
    const picMap = mapValues(keyBy(this.state.pictures, 'title'), 'file_size')
    let toUpload = [...files]
    if (some(files, (f) => picMap[f.name] && picMap[f.name] === f.size)) {
      const ignore = await swal({
        text: 'Foram detectadas uma ou mais fotos que já estão na coleção.',
        title: 'O que deseja fazer?',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Enviar todas as fotos',
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: 'Ignorar duplicadas',
            value: true,
            visible: true,
            className: 'warning',
            closeModal: true
          }
        }
      })
      if (ignore) {
        toUpload = toUpload.filter((f) => !picMap[f.name] || picMap[f.name] !== f.size)
      }
    }
    if (CustomerService.hasUsedCollection && some(files, (f) => f.name.indexOf(' ') !== -1)) {
      const action = await badFileNameAlert()
      if (!action) {
        return
      }
    }
    if (space.max_picture_size && some(files, (f) => f.size > space.max_picture_size)) {
      await swal({
        text: `Foram detectadas uma ou mais fotos com mais de ${formatBytes(space.max_picture_size)}, estas fotos serão ignoradas.`,
        title: 'Fotos maiores que o limite',
        icon: 'warning',
        buttons: {
          confirm: {
            text: 'Continuar upload',
            value: true,
            visible: true,
            className: 'warning',
            closeModal: true
          }
        }
      })
      const invalidFiles = toUpload.filter((f) => f.size > space.max_picture_size)
      toUpload = toUpload.filter((f) => f.size <= space.max_picture_size)
      const params = {
        service: 'selection',
        limitKey: space.max_picture_size_limit_key,
        limitValue: space.max_picture_size,
        moreInfo: {
          collection_id: this.state.collection.id,
          fileSizes: invalidFiles.map(i => (i.size / 1048576))
        }
      }
      Api.post('/account/logReachedLimit', params, true).catch(e => console.warn(e))

    }
    // console.log('Uploading', toUpload.length, 'of', files.length)

    if (process.env.SEL_ENABLE_PREPROCESSING) {
      this.processPictures(toUpload, space)
        .then((processedFiles) => {
          this.startUpload(processedFiles)
        })
    } else {
      this.startUpload(toUpload)
    }
  }

  async processPictures(files, space) {
    const processedFiles = []
    this.setState({
      isProcessing: true,
      processingProgress: {
        done: 0,
        total: files.length
      }
    })
    for (const file of files) {
      processedFiles.push(await Picture.processFile(file, space.max_picture_dimension))
      this.setState({
        processingProgress: {
          ...this.state.processingProgress,
          done: this.state.processingProgress.done + 1
        }
      })
    }
    this.setState({ isProcessing: false })
    return processedFiles
  }

  goToStep(stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/collection/${this.props.params.id}/${stepName}`)
  }

  watermarkOptions() {
    this.props.router.push('/settings/watermark')
    // window.location.reload()
  }

  render() {
    return (
      <div className="row">
        {
          !(this.state.collection) ?
            <Loading /> :
            <div>
              <CollectionSteps
                currentStep={2}
                reachedStep={this.state.collection.step}
                onClickStep={this.goToStep}
              />
              <PicturesUpload
                progress={this.state.progress}
                isProcessing={this.state.isProcessing}
                processingProgress={this.state.processingProgress}
                useWatermark={this.state.use_watermark}
                useAntiCopy={this.state.use_antiCopy}
                watermarkOptions={this.watermarkOptions}
                maxPictureSize={this.state.stats.subscription.limits.sel_max_picture_size ? formatBytes((+this.state.stats.subscription.limits.sel_max_picture_size) * 1048576) : false}
                maxPictureDimension={this.state.stats.subscription.limits.sel_max_picture_dimension ? (+this.state.stats.subscription.limits.sel_max_picture_dimension) : false}
                onDrop={this.onDrop}
                cancelUpload={this.cancelUpload}
                isUploading={this.state.isUploading}
                files={this.state.files} />
              <div className="col-md-12">
                {/* <ProcessingStatus pictures={this.state.pictures}></ProcessingStatus> */}
                {
                  !(this.state.pagePictures) ?
                    <Loading /> :
                    <PicturesList
                      pageSize={PAGE_SIZE}
                      picturesCount={this.state.picturesCount}
                      key={this.state.pictureListKey}
                      changeSort={this.changeSort}
                      goToStep={this.goToStep}
                      currentSort={this.state.collection.pictures_ordenation || 1}
                      changePage={this.changePage}
                      pages={this.state.pageCount}
                      currentPage={this.state.page}
                      pictures={this.state.pagePictures}
                      deletePictures={this.deletePictures}
                      suggest={this.suggest}
                      batchSuggest={this.batchSuggest}
                    />
                }
              </div>
            </div>
        }
      </div>
    )
  }
}

CollectionPictures.propTypes = {
  router: PropTypes.any,
  route: PropTypes.any,
  params: PropTypes.any
}

async function notEnoughLimits(space) {
  const content = document.createElement('div')
  content.style = 'text-align: left; font-size: 13px'
  content.innerHTML = 'O limite de fotos do plano contratado não é suficiente para fazer upload das fotos selecionadas.<br>' +
    '<br><strong>Imagens selecionadas:</strong> ' + space.requested +
    '<br><strong>Quantidade contratada:</strong> ' + space.limit +
    '<br><strong>Quantidade utilizado:</strong> ' + space.used +
    '<br><strong>Quantidade disponível:</strong> ' + space.available

  const openPlans = await swal({
    dangerMode: true,
    content,
    title: 'Sem espaço suficiente!',
    icon: 'error',
    buttons: {
      cancel: {
        text: 'Cancelar',
        value: false,
        visible: true,
        closeModal: true,
      },
      confirm: {
        text: 'Contratar mais espaço',
        value: true,
        className: 'good',
        visible: true,
        closeModal: true
      }
    }
  })
  if (openPlans) {
    window.location.href = 'http://picsize.com.br/optimizepress/planospicsize/'
  }
  ReactGA.event({
    category: 'Seleção',
    action: 'Espaço insuficiente para upload'
  })
}

function badFileNameAlert() {
  const content = document.createElement('div')
  content.style = 'text-align: left; font-size: 13px'
  content.innerHTML = `
  <p>
      Estamos alertando pois o Lightroom não funciona com espaço no momento da integração,
      então você poderá ter problemas após a seleção.
  </p>
  <p>
      Sugerimos fortemente que troque os espaços por "-" ou "_" antes de fazer o envio.
  </p>
  `

  return swal({
    className: 'no-space-alert-dialog',
    dangerMode: true,
    content,
    title: 'Identificamos que no nome de uma ou mais fotos contém espaços',
    icon: 'warning',
    buttons: {
      cancel: {
        text: 'Cancelar envio',
        value: false,
        visible: true,
        closeModal: true,
      },
      confirm: {
        text: 'Enviar mesmo assim',
        value: true,
        className: 'warning',
        visible: true,
        closeModal: true
      }
    }
  })
}


export default CollectionPictures
