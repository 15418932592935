import React, { Component } from 'react'

class MetadataListItem extends Component {

    componentDidMount() { }

    starLevel(i) {
        return i < this.props.item.value ? 'fa-star' : 'fa-star-o'
    }

    color(v) {
        if (typeof v !== 'string') return 'none'
        switch (v.toLowerCase()) {
            case 'blue': case 'azul': return '#4af'
            case 'red': case 'vermelho': return 'red'
            case 'green': case 'verde': return 'green'
            case 'yellow': case 'amarelo': return 'yellow'
            case 'purple': case 'púrpura': return '#a5d'
        }
        return 'none'
    }

    icon(item) {
        if (item.type === 'label') {
            const labelStyle = {
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                margin: 'auto',
                background: this.color(item.value)
            }
            return (<div style={labelStyle}></div>)
        }
        else if (item.type === 'rating') {
            return (<div>
                <i className={'fa ' + this.starLevel(0)}></i>
                <i className={'fa ' + this.starLevel(1)}></i>
                <i className={'fa ' + this.starLevel(2)}></i>
                <i className={'fa ' + this.starLevel(3)}></i>
                <i className={'fa ' + this.starLevel(4)}></i>
            </div>)
        }
    }

    surplus(max) {
        const surplus = this.props.item.pictures.length - max

        if (surplus <= 0) return null

        return (
            <div className='surplus-message'>
                +{surplus} fotos
            </div>
        )
    }

    render() {
        const { item, itemId } = this.props
        let displayMax = 7

        if (screen.width < 440) displayMax = 1
        else if (screen.width < 520) displayMax = 2
        else if (screen.width < 600) displayMax = 3
        else if (screen.width < 680) displayMax = 4
        else if (screen.width < 760) displayMax = 5
        else if (screen.width < 830) displayMax = 6

        return <div className='filter-item' onClick={this.props.onSelect}>
            <div>
                <i className={`fa ${item.selected ? 'fa-check-square-o' : 'fa-square-o'}`}></i>
            </div>
            <div className='filter-info'>
                <div style={{ marginBottom: '4px' }}>{item.key}</div>
                {this.icon(item)}
            </div>
            {item.pictures.slice(0, displayMax).map((i, id) =>
                <div key={`filter-picture-${itemId}-${id}`}>
                    <img src={i.thumb} />
                </div>
            )}
            {this.surplus(displayMax)}
        </div>
    }

}

export default MetadataListItem
