import React, {Component} from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import DayPickerInput from 'react-day-picker'
import 'react-day-picker/lib/style.css'

const WEEKDAYS_LONG = {
  pt: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
}
const WEEKDAYS_SHORT = {
  pt: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
}
const MONTHS = {
  pt: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
}
const FIRST_DAY_OF_WEEK = {
  pt: 1,
}
const LABELS = {
  pt: {nextMonth: 'Próximo mês', previousMonth: 'Mês anterior'},
}

class SelectDate extends Component {
  constructor() {
    super()
    this.handleDayClick = this.handleDayClick.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.pickerToggle = this.pickerToggle.bind(this)
    this.state = {showDatepicker: false}
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  handleClickOutside(event) {
    if (!this.node || !this.node.contains(event.target)) {
      if (event.target && !((event.target.className || '').includes('DayPicker'))) {
        this.setState({showDatepicker: false})
      }
    }
  }

  pickerToggle() {
    const showDatepicker = !this.state.showDatepicker
    this.setState({showDatepicker})
  }

  handleDayClick(day, {disabled, selected}) {
    if (disabled) {
      return
    }
    this.setState({showDatepicker: false})
    this.props.selectHandler(selected ? null : day)
  }

  render() {
    const formatedDate = (
      this.props.selectedDate.length > 0
      ? format(this.props.selectedDate, 'DD/MM/YYYY')
      : ''
    )
    return (
      <div className="select-date" style={this.props.style}>
        <div className="input-group input-medium">
          <input type="text"className="form-control" readOnly value={formatedDate} />
          <span className="input-group-btn">
            <button className="btn default" type="button" onClick={this.pickerToggle}>
              <i className="fa fa-calendar" />
            </button>
          </span>
        </div>
        <div className={`s-datepicker ${this.state.showDatepicker ? 'show': ''}`}>
          <DayPickerInput
            disabledDays={this.props.disabledDays}
            selectedDays={new Date(this.props.selectedDate)}
            onDayClick={this.handleDayClick}
            locale="pt"
            months={MONTHS['pt']}
            weekdaysLong={WEEKDAYS_LONG['pt']}
            weekdaysShort={WEEKDAYS_SHORT['pt']}
            firstDayOfWeek={FIRST_DAY_OF_WEEK['pt']}
            labels={LABELS['pt']}
          />
        </div>
      </div>
    )
  }
}

SelectDate.propTypes = {
  selectHandler: PropTypes.func,
  style: PropTypes.object,
  disabledDays: PropTypes.array
}

SelectDate.defaultProps = {
  style: {float: 'left', marginLeft: 10, marginTop: 16, padding: '3px 8px'},
  disabledDays: [{after: (new Date())}]
}

export default SelectDate
