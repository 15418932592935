import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import './ExitPopup.scss'

import BgExiPopup from 'public/img/bg-exit-popup.jpg'

export function trap(props) {
  document.addEventListener('mouseleave', _trap)

  function _trap(e) {
    if (e.clientY < 0) {
      document.removeEventListener('mouseleave', _trap)
      open(props)
    }
  }
}

export function open(props) {
  const host = document.getElementById('modal-area')

  if (!host) {
    console.warn('HostElementNotFoundError')
    return false
  }

  ReactDom.render(<ExitPopup close={close} {...props} />, host)

  function close(type = false) {
    if (type === 'exit-button') ReactGA.event({ category: 'Exit-Popup', action: 'Clicou Fechar Popup'})
    ReactDom.unmountComponentAtNode(host)
  }

  return {
    close
  }
}

const ExitPopup = ({ close, createGal, createSel }) => {

  function onAction(e) {
    if (e.target.name === 'gal' && typeof createGal === 'function') {
      ReactGA.event({ category: 'Exit-Popup', action: 'Clicou criar minha primeira Galeria p/ Entrega'})
      createGal()
    }
    if (e.target.name === 'sel' && typeof createSel === 'function') {
      ReactGA.event({ category: 'Exit-Popup', action: 'Clicou criar minha primeira Galeria p/ Seleção' })
      createSel()
    }
    close()
  }

  return (
    <div className='scp-exit-popup-overlay'>
      <div className='scp-exit-popup' style={{ backgroundImage: `url(${BgExiPopup})` }}>

        <button className='close-btn' onClick={() => close('exit-button')}>
          <i className='fa fa-close'></i>
        </button>

        <div className='popup-content'>
          <h2>Opa!</h2>
          <p>Não estou acreditando que você vai sair sem ter utilizado o sistema</p>
          <button className='gal-btn' name='gal' onClick={onAction}>Criar Minha Primeira Galeria p/ Entrega</button>
          <button className='sel-btn' name='sel' onClick={onAction}>Criar Minha Primeira Galeria p/ Seleção</button>
        </div>

      </div>

    </div>
  )
}

ExitPopup.propTypes = {
  close: PropTypes.func,
  createGal: PropTypes.func,
  createSel: PropTypes.func
}
