import React from 'react'
import PropTypes from 'prop-types'

const steps = [
  {
    name: 'info',
    icon: 'fa fa-book',
    title: 'Álbum',
    description: 'Dados do álbum'
  },
  {
    name: 'sheets',
    icon: 'fa fa-columns',
    title: 'Lâminas',
    description: 'Envio e gerenciamento das lâminas'
  },
  {
    name: 'review',
    icon: 'fa fa-eye',
    title: 'Clientes',
    description: 'Clientes que farão a revisão o álbum'
  }
]

function CollectionSteps (props) {
  return (
    <div className="col-md-10 col-md-offset-1">
      <div className="portlet light portlet-fit" style={{backgroundColor: '#eef1f5'}}>
        <div className="portlet-body">
          <div className="mt-element-step">
            <div className="row step-line">
              {steps.map((step, index) => (
                <Step
                  key={index}
                  onClick={props.onClickStep ? (() => props.onClickStep(step.name)) : undefined}
                  isCurrent={props.currentStep === (index + 1)}
                  isDone={(index + 1) <= props.reachedStep}
                  isFirst={(index + 1) === 1}
                  isLast={(index + 1) === steps.length}
                  number={(index + 1)}
                  icon={step.icon}
                  title={step.title}
                  description={step.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Step (props) {
  let classes = 'col-md-4 mt-step-col'
  if (props.isFirst) classes += ' first'
  else if (props.isLast) classes += ' last'
  if (props.isCurrent) classes += ' active'
  else if (props.isDone) classes += ' done'
  return (
    <div className={classes}>
      <div onClick={props.onClick} style={{cursor: props.onClick ? 'pointer': 'default'}}>
        <div
          className="mt-step-number"
          style={{
            backgroundColor: '#eef1f5',
            color: (props.isCurrent || props.isDone) ? '' : '#95A5A6'
          }}>
          <i className={props.icon} />
        </div>
        <div className="mt-step-title uppercase font-grey-cascade">
          {props.number}. {props.title}
        </div>
        <div className="mt-step-content font-grey-cascade">
          {props.description}
        </div>
      </div>
    </div>
  )
}

Step.propTypes = {
  icon: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  number: PropTypes.number,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isDone: PropTypes.bool,
  isCurrent: PropTypes.bool,
  onClick: PropTypes.func
}

CollectionSteps.propTypes = {
  currentStep: PropTypes.number,
  reachedStep: PropTypes.number,
  onClickStep: PropTypes.func
}

export default CollectionSteps
