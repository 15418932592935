import React, { Component } from 'react'
import { Switch, notification } from 'antd'
import ReactGA from 'react-ga'
import swal from 'sweetalert'

// import Auth from '~/modules/Auth'
import Api from '~/modules/Api'
// import * as FeatureUnavailableAlert from '~/widgets/Alerts/FeatureUnavailable'

import './AntiCopySwitch.scss'

export default class AntiCopySwitch extends Component {

  constructor() {
    super(...arguments)

    this.state = {
      ready: false,
      busy: false
    }
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    const settings = await Api.get('/customer/settings/collection_watermark')

    return this.setState({
      ready: true,
      settings: {
        watermark: false,
        watermark_tmp: false,
        watermark_position: 9,
        ...settings,
        use_watermark: settings.use_watermark === 'true',
        use_antiCopy: settings.use_antiCopy === 'true',
      }
    })
  }

  async switch(value) {
    // if (value && (Auth.isFreemium || Auth.isTrial)) {
    //   return await FeatureUnavailableAlert.open()
    // }

    ReactGA.event({
      category: 'Seleção',
      action: 'Alterou carimbo antiCopy'
    })

    this.setState({ busy: true })

    const result = await Api.post('/customer/settings/collection_watermark', {
      values: {
        ...this.state.settings,
        use_antiCopy: value
      }
    })
    if (result && result.success) {
      await this.getData()
      this.alert()
      // notification.success({ message: 'Dados atualizados com sucesso!' })
      this.setState({ busy: false })
      return
    }
    notification.error({ message: 'Erro ao atualizar dados' })
    this.setState({ busy: false })
  }

  async alert() {
    swal({
      text: 'As fotos que já foram enviadas NÃO sofrerão alterações.',
      title: 'Aviso',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Ok, entendi',
          value: false,
          visible: true,
          closeModal: true,
        }
      }
    })
  }

  render() {
    return (
      <label className='scp-anti-copy-switch' onClick={e => e.stopPropagation()}>

        {this.state.busy ? <><i className='fa fa-spinner fa-pulse'></i>&nbsp;</> : null}

        <>{'Inserir carimbo de "CÓPIA NÃO AUTORIZADA" ao enviar as fotos:'}&nbsp;</>

        <Switch
          size="small"
          disabled={!this.state.ready || this.state.busy}
          checked={this.state.settings && this.state.settings.use_antiCopy}
          onChange={v => this.switch(v)}
        />
      </label>
    )
  }
}
