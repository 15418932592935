import React from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import moment from 'moment'
import 'moment/locale/pt-br'
import {
  Input,
  InputNumber,
  Icon,
  AutoComplete,
  Button,
  Form,
  Tooltip,
  Switch,
  Divider,
  DatePicker,
  Popover
} from 'antd'
import locale from 'antd/lib/date-picker/locale/pt_BR'
import { Link } from 'react-router'

const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 10 }
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 12 }
  },
  colon: false,
  className: 'ps-ant-formItem'
}

const FormItem = props => <Form.Item {...formItemLayout} {...props} />

function ModalForm({ modalType, data, onChange, onSubmit }) {
  
  const emailValidation = (
    data.clientEmail.length && !data.isValidEmail ? {
      help: 'E-mail inválido',
      validateStatus: 'error'
    } : {}
  )

  return (
    <Form layout="horizontal" onSubmit={onSubmit}>
    {modalType !== 'edit' && ([
      <FormItem label="Nome do cliente" key="clientName">
        <Input
          name="clientName"
          value={data.clientName}
          onChange={onChange}
          disabled={modalType === 'resend'}
        />
      </FormItem>,
      <FormItem label="E-mail do cliente" {...(emailValidation)} key="clientEmail">
        <Input
          name="clientEmail"
          value={data.clientEmail}
          onChange={onChange}
          disabled={modalType === 'resend'}
        />
      </FormItem>,
      <FormItem label="Observações" key="clientMessage">
        <Input.TextArea
          name="clientMessage"
          value={data.clientMessage}
          onChange={onChange}
          autosize={{ minRows: 4, maxRows: 6 }}
        />
      </FormItem>
    ])}
      <FormItem label="Prazo limite">
        <DatePicker
          format="DD/MM/YYYY"
          locale={locale}
          value={data.limitDate ? moment(data.limitDate) : null}
          popupStyle={{ zIndex: 99995 }}
          onChange={newMoment => onChange(newMoment ? newMoment.toISOString() : null, 'limitDate')}
        />
      </FormItem>
      <FormItem
        label={
          <Popover
            title="Permitir download?"
            content={
              <div>
                Opte por NÃO caso queira que seu cliente não copie as fotos.
                <br />
                Essa opção bloqueia o clique do botão direito do mouse.
                <br />
                Se optar por sim, aparecerá para o seu cliente a opção de fazer
                o download de todas as fotos em um arquivo compactado (zipado)
              </div>
            }
            overlayStyle={{ zIndex: 99995, maxWidth: '350px' }}
          >
            Permitir download? <Icon type="question-circle-o" />
          </Popover>
        }
      >
        <Switch
          checked={data.allowDownload}
          onChange={allow => onChange(allow, 'allowDownload')}
        />
      </FormItem>
      <FormItem
        label={
          <Popover
            title="Fotos contratadas"
            content={
              <div>
                O seu cliente conseguirá concluir a seleção antes de atingir a quantidade informada.
                Se deixar o campo zerado, o cliente poderá selecionar quantas quiser.
              </div>
            }
            overlayStyle={{ zIndex: 99995, maxWidth: '350px' }}
          >
            Fotos contratadas <Icon type="question-circle-o" />
          </Popover>
        }
      >
        <InputNumber
          value={data.contractedPictures}
          onChange={num => onChange(num, 'contractedPictures')}
        />
      </FormItem>
      <FormItem
        label={
          <Popover
            title="Fotos adicionais?"
            content={
              <div>
                Se você optar por vender fotos extras, basta marcar essa opção
                que o seu cliente poderá marcar fotos além do contratado, e será
                informado do valor a ser pago.
              </div>
            }
            overlayStyle={{ zIndex: 99995, maxWidth: '350px' }}
          >
            Fotos adicionais? <Icon type="question-circle-o" />
          </Popover>
        }
      >
        <Switch
          checked={data.allowExtraPictures}
          onChange={allow => onChange(allow, 'allowExtraPictures')}
        />
      </FormItem>
      {data.allowExtraPictures && (
        <FormItem
          label={
            <Popover
              title="Valor da foto adicional"
              content={
                <div>
                  Informe o valor por foto adicional. O sistema irá calcular
                  automaticamente a o valor a ser pago baseado na quantidade de
                  fotos adicionais.
                </div>
              }
              overlayStyle={{ zIndex: 99995, maxWidth: '350px' }}
            >
              Valor da foto adicional <Icon type="question-circle-o" />
            </Popover>
          }
        >
          <InputNumber
            value={data.extraPicturePrice}
            step={100}
            formatter={value => `R$ ${(+value / 100).toFixed(2).replace('.', ',')}`}
            parser={value => parseInt(value.replace(/\D/g, ''))}
            onChange={val => onChange(val, 'extraPicturePrice')}
          />
        </FormItem>
      )}
    </Form>
  )
}

ModalForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export default ModalForm
