import { Component } from 'react'
import ReactDOM from 'react-dom'
import { browserHistory } from 'react-router'
import ReactGA from 'react-ga'

import 'jquery'
import 'bootstrap'
import 'toastr'
import AppComponent from './app'

import 'simple-line-icons/simple-line-icons.min.css'
import 'styles/custom/fonts.css'
import 'fonts/fonts.css'
import 'fonts/picsize.css'
import 'fonts/knowledge/knowledge.css'
import 'styles/metronic/lib.scss'

import 'toastr/build/toastr.css'
import 'custom/widgets.scss'
import 'styles/custom/sidebar.scss'

import './style.scss'

// ReactGA.initialize('UA-81145851-3')
window.paceOptions = {
  target: '#root',
  elements: {
    selectors: ['body', 'img']
  },
  ajax: {
    trackMethods: ['GET', 'POST'],
    trackWebSockets: true
  }
}
import Pace from 'vendor/pace/pace'
window.Pace = Pace
Pace.start()

browserHistory.listen(location => {
  ReactGA.pageview(location.pathname)
})

export const App = ReactDOM.render(<AppComponent />, document.getElementById('root'))
