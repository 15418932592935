import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import Api from '~/modules/Api'
import Auth from '~/modules/Auth'
import Loading from '~/widgets/Loading'
import GalleryService from '../GalleryService'
import CustomerService from '~/modules/CustomerService'
import ReachedLimitDialog from '../../../widgets/ReachedLimitDialog/ReachedLimitDialog'

import './GalleryListPage.scss'

export default class GalleryListPage extends Component {
  subs = null

  constructor(props, context) {
    super(props, context)
    this.state = {
      galleries: [],
      listGalleries: [],
      ready: false,
      searchText: '',
      maxCollLimitReached: false,
    }
    this.add = this.add.bind(this)
  }

  async componentWillMount() {
    const maxCollLimit = Auth.userData && Auth.userData.subscription && Auth.userData.subscription.limits
      && Auth.userData.subscription.limits.col_max_collections || 0
    this.subs = GalleryService.getGalleries().subscribe(galleries => {
      this.setState({ galleries, ready: true, listGalleries: galleries })
    })

    const { permission } = await Api.get('/collection/permission?collection_type=gallery')
    if (!permission) this.setState({ maxCollLimitReached: true })
  }

  componentWillUnmount() {
    this.subs.unsubscribe()
  }

  handleSearchGallery = () => {
    const word = (this.state.searchText || '').toLowerCase()

    this.setState({
      galleries: this.state.listGalleries.filter(filterList)
    })

    function filterList(gallery) {
      return gallery.title.toString().toLowerCase().includes(word)
    }
  }

  async add() {
    if(this.state.maxCollLimitReached){
      CustomerService.pushReachedPlanLimitLog('gallery')
      return await ReachedLimitDialog.open({
        title: 'Limite atingido',
        message: `O limite de ${Auth.userData.subscription.limits.col_max_collections} galerias do seu plano foi atingido.`
      })
    }
    this.props.router.push('/gallery')
  }

  render() {
    return (
      <div className='gallery-list-page'>
        <h1 className="page-title">Minhas Entregas</h1>
        <div className='portlet light'>
          <div className='portlet-title'>
            <div className="caption">
              <button className="btn btn-circle btn-primary green" onClick={this.add}
              // disabled={this.state.maxCollLimitReached} title={this.state.maxCollLimitReached ? 'Você atingiu o limite de galerias do seu plano.' : ''}
              >
                <i className="fa fa-plus"></i> Nova Entrega
                            </button>
            </div>
            <div className="tools" style={{ alignItems: 'center', verticalAlign: 'middle', display: 'flex' }}>
              <div className="input-icon right" style={{ marginRight: 10 }}>
                <i className="icon-magnifier"></i>
                <input
                  className="form-control"
                  onChange={(event) => this.setState({ searchText: event.target.value }, () => this.handleSearchGallery())}
                  value={this.state.searchText}
                  placeholder="Buscar Entrega" type="text" />
              </div>
            </div>
          </div>
          <div className='portlet-body'>
            {
              this.state.ready ?
                (
                  this.state.galleries && !this.state.galleries.length ?
                    <CreateFirst /> :
                    <div className='mt-element-card mt-element-overlay'>
                      <div className='list-wrap'>
                        {this.state.galleries.map((i, id) => GalleryItem(i, id))}
                      </div>
                    </div>
                ) :
                <Loading />
            }
          </div>
        </div>
      </div>
    )
  }
}

function GalleryItem(i, id) {
  return (
    <div key={`gli-${id}`} className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <div className='mt-card-item'>
        <div className="mt-card-avatar mt-overlay-1">
          <Link to={`/gallery/${i.id}/pictures`} className={`${!i.cover && 'no-cover'}`}>
            {i.cover && <img src={i.cover} />}
          </Link>
        </div>
        <div className="mt-card-content">
          <h3 className="mt-card-name">
            <Link to={`/gallery/${i.id}/pictures`}>
              {i.title}
            </Link>
          </h3>

          <div className="mt-card-content-stats">
            <div className="pull-left">
              <i className="fa fa-image" /> {i.pictures_count} fotos
                        </div>

            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  )
}


function CreateFirst() {
  return (
    <div className="portlet-body" style={{ minHeight: '300px', padding: '50px 0' }}>
      <div className="text-center">
        <p style={{ fontSize: '64px' }}>
          <i className="fo fo-pictures" />
        </p>
        <h3>
          Você ainda não possui nenhuma galeria!
                </h3>
        <h4>
          <Link to="/gallery">Clique aqui</Link> para cadastrar a primeira
                </h4>
      </div>
    </div>
  )
}


GalleryListPage.propTypes = {
  location: PropTypes.any,
  params: PropTypes.any,
  router: PropTypes.any
}
