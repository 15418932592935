import React from 'react'

import Form from '~/widgets/Form'

import FormMessagePreview from './FormMessagePreview'

function ReviewerForm (props) {
  return (
      <div className="row">
        <div className={`portlet light ${props.send ? 'col-md-6' : 'col-md-10 col-offset-md-1'}`}>
          <Form.Group>
            <Form.Label className="col-md-5">
              Nome <Form.Label.Hint>Nome da pessoa que revisará o álbum</Form.Label.Hint>
            </Form.Label>
            <Form.Input type="text" disabled={!props.send} name="name" className="col-md-7" />
          </Form.Group>
          <Form.Group>
            <Form.Label className="col-md-5">
              E-mail <Form.Label.Hint>E-mail para envio do link</Form.Label.Hint>
            </Form.Label>
            <Form.Input type="text" disabled={!props.send} name="email" className="col-md-7" />
          </Form.Group>
          {
            props.send &&
            <Form.Group>
              <Form.Label className="col-md-5">
                Obervações <Form.Label.Hint>Essas obervações irão compor o e-mail que será enviado para o seu cliente. Verifique como ficará a mensagem no box ao lado</Form.Label.Hint>
              </Form.Label>
              <Form.Input.Textarea name="message" className="col-md-7" />
            </Form.Group>
          }
          <Form.Group>
            <Form.Label className="col-md-5">
              Prazo limite <Form.Label.Hint>Sugerimos preencher uma data para incentivar o seu cliente a aprovar o mais rápido possível. Após o prazo, ele não poderá mais aprovar ou fazer considerações. Se deixar em branco ele nunca terá restrições.</Form.Label.Hint>
            </Form.Label>
            <Form.Input.Date name="limit_date" className="col-md-7" />
          </Form.Group>
        </div>
        <div className="col-md-6">
          {props.send && <FormMessagePreview />}
        </div>
      </div>
  )
}

export default ReviewerForm
