import React from 'react'
import PropTypes from 'prop-types'

import {
  Input,
  Form,
  Select,
  notification,
  Modal
} from 'antd'

import Api from '~/modules/Api'

const formItemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 6 }
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 18 }
  },
  colon: false,
  className: 'ps-ant-formItem'
}

const FormItem = props => <Form.Item {...formItemLayout} {...props} />

const defaultState = {
  visible: false,
  confirmLoading: false,
  message: '',
  toEmails: [],
}

class SendMailModal extends React.Component {
  static propTypes = {
    triggerElement: PropTypes.any,
    children: PropTypes.any,
  }

  constructor(props) {
    super(props)

    this.state = {
      ...defaultState,
      ...this.props
    }
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  };

  handleOk = async () => {
    this.setState({ confirmLoading: true })

    if (!this.state.toEmails.length) {
      return this.setState({ confirmLoading: false }, () => {
        notification.error({
          message: 'É necessário definir ao menos um destinatário!'
        })
      })
    }

    await Api.post('/select/send-mail', {
      toEmails: this.state.toEmails,
      message: this.state.message,
      genericLink: this.state.genericLink
    }, true)

    this.setState({ visible: false, confirmLoading: false }, () => {
      notification.success({
        message: 'E-mail enviado!'
      })
    })
  };

  handleCancel = () => {
    this.setState({
      ...defaultState,
      ...this.props
    })
  };

  onChange = (value, key)  => {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }

    if (key === 'toEmails') {
      value = value.filter((input) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input))
    }
    const newState = { [key]: value }

    this.setState(newState)
  }

  get triggerElement () {
    const trigger = this.props.triggerElement
    if (typeof this.props.triggerElement === 'object') {
      return ((props) => <trigger.type {...props} {...trigger.props} />)
    }
    return trigger || ((props) => <div style={{ display: 'inline-block' }} {...props} />)
  }

  render () {
    const { visible, confirmLoading } = this.state

    return [
      <this.triggerElement
        onClick={this.showModal}
        key="send-mail-openModal"
        {...this.button}
      >
        {this.props.children}
      </this.triggerElement>,
      <Modal
        title="Enviar email"
        visible={visible}
        onOk={this.handleOk}
        confirmLoading={confirmLoading}
        onCancel={this.handleCancel}
        key="send-mail-modal"
        okText='Enviar'
        okButtonProps={
          {
            icon: 'mail'
          }
        }
        cancelText='Cancelar'
        width='100%'
        style={
          { maxWidth: '650px' }
        }
      >
        <Form layout="horizontal">
          <FormItem label="Destinatários">
            <Select
              mode="tags"
              placeholder="Lista de endereços de e-mail que receberão a mensagem"
              value={this.state.toEmails}
              dropdownRender={() => <div />}
              tokenSeparators={[',', ' ']}
              onChange={(mails) => this.onChange(mails, 'toEmails')}
            />
          </FormItem>
          <FormItem label="Mensagem" help="O link para a seleção será adicionado após a mensagem">
            <Input.TextArea
            name="message"
            value={this.state.message}
            onChange={this.onChange}
            autosize={{ minRows: 4, maxRows: 6 }}
          />
          </FormItem>
        </Form>
      </Modal>
    ]
  }
}

export default SendMailModal
