import { ReplaySubject, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export default function ElasticCover(gallery, mapBlur) {
  const $sbj = new ReplaySubject()
  const $destroy = new Subject()

  let key = gallery.cover_key
  let coverPath = key.split('customer/')[1]
  let blurLevel = 5
  const settings = gallery.cover_settings || {}
  let { width, height } = settings

  mapBlur = mapBlur || (d => d)

  if (!settings || !settings.width || !settings.height) {
    $sbj.next({ cover: '', blurLevel })
    getImgDim()
  }
  else {
    $sbj.next({ cover: `https://img.picsize.com.br/thumb/max/32/${coverPath}`, blurLevel })
    setTimeout(() => calc(), 5e2)
  }

  window.addEventListener('resize', calc)

  function getImgDim() {
    const img = document.createElement('img')
    img.src = `https://img.picsize.com.br/thumb/max/1920/${coverPath}`
    img.onload = () => {
      width = img.width
      height = img.height
      calc()
    }
  }

  function push(gallery, _mapBlur) {

    if (typeof _mapBlur === 'function') {
      mapBlur = _mapBlur
    }

    if (gallery) {
      key = gallery.cover_key
      coverPath = key.split('customer/')[1]
      const settings = gallery.cover_settings
      if (!settings || !settings.width || !settings.height) {
        return getImgDim()
      }
      width = settings.width
      height = settings.height
    }

    calc()
  }

  function calc() {
    const { width: screenWidth, height: screenHeight } = mapBlur({ width: screen.width, height: screen.height })

    if (!width || !height) {
      $sbj.next({ cover: '', blurLevel })
      return
    }

    if (screenWidth <= 800) {
      width = Math.min(768, width)
    }
    else if (screenWidth <= 1200) {
      width = Math.min(1080, width)
    }
    else if (screenWidth <= 1500) {
      width = Math.min(1440, width)
    }
    else {
      width = Math.min(1920, width)
    }

    const d = Math.max(screenWidth - width, screenHeight - height)

    if (d <= 100) {
      blurLevel = 0
    } else if (d < 200) {
      blurLevel = 1
    } else if (d < 300) {
      blurLevel = 2
    } else if (d < 500) {
      blurLevel = 3
    } else if (d < 1300) {
      blurLevel = 4
    } else {
      blurLevel = 5
    }

    $sbj.next({ cover: `https://img.picsize.com.br/thumb/max/${width}/${coverPath}`, blurLevel })
  }

  function destroy() {
    window.removeEventListener('resize', calc)
    $destroy.next()
    $destroy.complete()
  }

  return {
    $obs: $sbj.asObservable().pipe(takeUntil($destroy)),
    push,
    destroy
  }
}
