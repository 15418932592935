import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown, Tooltip } from 'antd'
import toastr from 'toastr'

import * as EditSubdomain from '~/widgets/EditSubdomain'
import GalleryContext from '../GalleryContext'
import ShareDialogs from './ShareDialogs'

import './Share.scss'

export default class GalleryShare extends Component {
  static contextType = GalleryContext
  static propTypes = {
    link: PropTypes.object
  }
  menu = null

  constructor(props, context) {
    super(props, context)
    this.whatsapp = this.whatsapp.bind(this)
    this.email = this.email.bind(this)
    this.facebook = this.facebook.bind(this)
    this.copy = this.copy.bind(this)
    this.open = this.open.bind(this)
    this.edit = this.edit.bind(this)

    this.menu = (
      <Menu>
        <Menu.Item key="1" onClick={this.whatsapp}><i className='fa fa-whatsapp' /> Whatsapp</Menu.Item>
        <Menu.Item key="2" onClick={this.email}><i className='fa fa-envelope-o' /> Email</Menu.Item>
        {/* <Menu.Item key="3" onClick={this.facebook}><i className='fa fa-facebook-official' /> Facebook</Menu.Item> */}
      </Menu>
    )
  }

  whatsapp() {
    ShareDialogs.openWhatsapp({ link: this.link })
  }

  email() {
    ShareDialogs.openEmail({ link: this.link })
  }

  facebook() {
    ShareDialogs.openFacebook({ link: this.link })
  }

  async copy() {
    function _oldCopy() {
      let inp = document.getElementById(`link-${this.linkHash}`)
      if (!inp) {
        inp = document.createElement('input')
        inp.id = `link-${this.linkHash}`
        inp.value = this.link
        inp.className = 'clipboard-inp'
        inp.readOnly = true
        document.getElementById(`share-link-${this.linkHash}`).appendChild(inp)
      }
      inp.focus()
      inp.select()
      document.execCommand('copy')
      return true
    }
    async function _copy() {
      if (!navigator.clipboard) {
        return _oldCopy.bind(this)()
      }
      try {
        await navigator.clipboard.writeText(this.link)
        return true
      }
      catch (ex) {
        console.warn(ex)
        return _oldCopy.bind(this)()
      }
    }
    await _copy.bind(this)()
    toastr['success']('Link copiado para área de transferencia!')
  }

  open() {
    window.open(this.link)
  }

  async edit() {
    if (!this.props.link) {
      return
    }
    const res = await EditSubdomain.open({ subdomain: this.props.link.subdomain, hash: this.props.link.hash })
    if (res === true) {
      this.context.refresh()
    }
  }

  get linkBase() {
    return this.props.link && this.props.link.domain
  }

  get linkHash() {
    return this.props.link && this.props.link.hash || ''
  }

  get link() {
    return this.props.link && this.props.link.url
  }

  render() {
    if (!this.props.link) {
      return null
    }

    return (
      <div className="gallery-share">

        {/* <i className="icon-link share-icon"></i> */}

        <div className='share-link' id={`share-link-${this.linkHash}`}>
          <a target="_blank" href={this.link} rel="noopener noreferrer">
            {this.linkBase}/<strong>{this.linkHash}</strong>
          </a>
        </div>

        <Tooltip title='Editar subdomínio'>
          <button className='share-btn btn btn-xs green' onClick={this.edit}>
            <i className="fa fa-edit"></i>
          </button>
        </Tooltip>

        <Tooltip title='Copiar o link'>
          <button className='share-btn btn btn-xs green' onClick={this.copy}>
            <i className="fa fa-copy"></i>
          </button>
        </Tooltip>

        <Tooltip title='Ver galeria como o cliente'>
          <button className='share-btn btn btn-xs green' onClick={this.open}>
            <i className="fa fa-external-link"></i>
          </button>
        </Tooltip>

        <Dropdown overlay={this.menu}>
          <button className="btn share-menu">
            Compartilhar <i className='fa fa-angle-down' />
          </button>
        </Dropdown>

      </div>
    )
  }
}
