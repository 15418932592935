/* eslint-disable curly */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import toastr from 'toastr'

import Api from '~/modules/Api'
import ElasticCover from './ElasticCover'

import './CoverItemAlt.scss'

export default class CoverItem extends Component {

  constructor(props, context) {
    super(props, context)

    const gallery = this.props.gallery || {}
    const settings = gallery.cover_settings || {}

    this.state = {
      dragging: false,
      uploading: false,
      processing: settings.processing,
      showCover: gallery.cover && !settings.processing,
      cover: gallery.cover,
      blurLevel: 5
    }

    this.uploadCover = this.uploadCover.bind(this)

    function mapBlur({ width, height }) {
      return { width: width - 94, height: 315 }
    }
    this.elasticCover = ElasticCover(gallery, mapBlur)
  }

  componentDidMount() {
    this.elasticCover.$obs.subscribe(state => this.setState(state))
  }

  componentWillUnmount() {
    this.elasticCover.destroy()
  }

  componentWillUpdate(props) {
    if (this.props.gallery !== props.gallery) {
      const gallery = props.gallery || {}
      const settings = gallery.cover_settings || {}

      this.elasticCover.push(gallery)

      this.setState({
        processing: settings.processing,
        showCover: gallery.cover && !settings.processing
      })
    }
  }

  async uploadCover(file) {
    this.setState({ dragging: false })
    if (!file || !file.length) return
    this.setState({ uploading: true, processing: true })
    const { id } = this.props.gallery
    await Api.upload('/collection/cover', { id }, file)
    this.setState({ uploading: false })
    toastr['success']('Upload completo!')
  }

  render() {
    if (this.props.sample) return (
      <div className='gallery-cover-item-wrap'>
        <div className='gallery-cover-item cover-sample'>
          <img src={'https://picsum.photos/800/600/?random'}></img>
          <div className="cover-tip"></div>
          <div className="cover-tip-message">
            <i className="fa fa-cloud-upload fa-2x"></i>
            <span>Adicionar Foto da Capa</span>
          </div>
        </div>
      </div >
    )

    if (this.state.uploading) return (
      <div className='gallery-cover-item-wrap'>
        <div className='gallery-cover-item no-cover processing-cover'>
          <div className="cover-state-message">
            <i className="fa fa-spinner fa-pulse fa-2x"></i>
            <span>Fazendo envio da foto</span>
          </div>
        </div>
      </div>
    )

    if (this.state.processing) return (
      <div className='gallery-cover-item-wrap'>
        <div className='gallery-cover-item no-cover processing-cover'>
          <div className="cover-state-message">
            <i className="fa fa-cog fa-spin fa-2x"></i>
            <span>Processando foto</span>
          </div>
        </div>
      </div>
    )

    return (
      <Dropzone
        className='gallery-cover-item-wrap'
        onDrop={this.uploadCover}
        multiple={false}
        onDragEnter={() => this.setState({ dragging: true })}
        onDragLeave={() => this.setState({ dragging: false })}
        accept="image/jpeg, image/png"
        style={{ cursor: 'pointer' }}>
        {this.state.showCover ?
          <div className={`gallery-cover-item ${this.state.dragging ? 'dragging' : ''}`}>
            <img src={this.state.cover} className={`blur-${this.state.blurLevel}`}></img>
            <div className="cover-tip"></div>
            <div className="cover-tip-message">
              <i className="fa fa-camera"></i>
              <span>{this.state.dragging ? 'Solte Para ' : ''}Alterar Foto da Capa</span>
            </div>
          </div>
          :
          <div className={`gallery-cover-item no-cover ${this.state.dragging ? 'dragging' : ''}`}>
            <div className="cover-tip-message">
              <i className="fa fa-cloud-upload fa-2x"></i>
              <span>{this.state.dragging ? 'Solte Para ' : ''}Adicionar Foto da Capa</span>
            </div>
          </div>
        }

      </Dropzone>
    )
  }

}

CoverItem.propTypes = {
  gallery: PropTypes.any,
  sample: PropTypes.bool,
}
