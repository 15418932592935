import React, { Component } from 'react'

import Auth from '~/modules/Auth'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-switch/css/bootstrap-switch.min.css'
import 'styles/metronic/layout.min.css'
import 'styles/metronic/blue.min.css'
import 'styles/metronic/custom.min.css'
import './style.scss'

import logoPICSIZE from 'img/logo-picsize.png'

export default class BasicLayout extends Component {

    constructor(props, context) {
        super(props, context)
    }

    componentDidMount() {
        document.body.classList.add('scp-layout-basic-body')
        checkPermission(this.props.location.pathname)
        document.body.classList.remove('login')
        document.body.classList.add('page-header-fixed')
        document.body.classList.add('page-sidebar-closed-hide-logo')
        document.body.classList.add('page-container-bg-solid')
        document.body.classList.add('page-sidebar-closed')
        this.addGTMMetaTags()
    }

    componentWillUnmount() {
        document.body.classList.remove('scp-layout-basic-body')
    }

    addGTMMetaTags() {
        if (!document.querySelector('meta[name="auth-method"]')) {
            const m = document.createElement('meta')
            m.name = 'auth-method'
            m.content = Auth.isPersonificated ? 'admin-cp' : 'login'
            document.head.appendChild(m)
        }
        if (!document.querySelector('meta[name="freemium-subscription"]')) {
            const m = document.createElement('meta')
            m.name = 'freemium-subscription'
            m.content = Auth.isFreemium
            document.head.appendChild(m)
        }
        if (!document.querySelector('meta[name="first-access"]')) {
            const firstAccess = Auth.userData && Auth.userData.firstAccess
            const m = document.createElement('meta')
            m.name = 'first-access'
            m.content = firstAccess || false
            document.head.appendChild(m)
        }
    }

    render() {
        return (
            <div className='scp-layout-basic'>
                <div className="page-header navbar navbar-fixed-top">
                    <div className='page-header-logo' style={{ backgroundImage: `url(${logoPICSIZE})` }}></div>
                </div>
                <div className="clearfix" />
                <div className="page-container">
                    <div className="page-content-wrapper">
                        <div className="page-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function checkPermission(path) {
    const { subscription } = Auth.userData
    if (!subscription) return
    if (!path.includes('/album/')) return
    if (subscription.limits && !subscription.limits.rev) {
        window.location = '/'
    }
}
