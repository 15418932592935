import Api from './Api'
import Auth from './Auth'
import { BehaviorSubject } from 'rxjs'
import { filter, map } from 'rxjs/operators'

const SUBDOMAIN_PATTERN = /^[a-z0-9-]{4,48}$/i

class User {
  _stats = {
    ready: false,
    collectionInfo: {
      count: 0,
      last: null,
      totalPictures: 0,
      totalSize: 0
    },
    galleryInfo: {
      count: 0,
      last: null,
      totalPictures: 0,
      totalSize: 0
    }
  }
  $stats = new BehaviorSubject()

  constructor() { }

  get stats() {
    return this.$stats.asObservable().pipe(
      filter(() => typeof this._stats === 'object' && this._stats.ready === true),
      map(() => this._stats)
    )
  }

  async refreshStats() {
    try {
      const result = await Api.get('/customer/collection')

      if (!result && !result.success) {
        throw result
      }

      const { collection_info, gallery_info } = result

      this._stats = {
        ready: true,
        collectionInfo: {
          count: +collection_info.count,
          last: new Date(collection_info.last),
          totalPictures: +collection_info.total_pictures,
          totalSize: +collection_info.total_size
        },
        galleryInfo: {
          count: +gallery_info.count,
          last: new Date(gallery_info.last),
          totalPictures: +gallery_info.total_pictures,
          totalSize: +gallery_info.total_size
        }
      }

      this.$stats.next()
    } catch (ex) {
      console.warn(ex)
    }
  }

  get hasUsedCollection() {
    return typeof this._stats === 'object' && this._stats.ready === true &&
      this._stats.collectionInfo && this._stats.collectionInfo.totalPictures > 0
  }

  async pushLog(key, value, unique) {
    try {
      const res = await Api.post('/account/aud', { key, value, unique: !!unique })
      if (!res || !res.success) {
        console.warn(res)
      }
      return res
    } catch (e) {
      console.warn(e)
      // eslint-disable-next-line no-undef
      Raven.captureException(e)
    }
  }

  async pushReachedPlanLimitLog(service) {
    try {
      let limitKey
      let limitValue

      if (service === 'gallery') {
        limitKey = 'col_max_collections'
        limitValue = Auth.userData.subscription.limits.col_max_collections
      }
      else if (service === 'selection') {
        limitKey = 'sel_max_collections'
        limitValue = Auth.userData.subscription.limits.sel_max_collections
      }
      else if (service === 'album') {
        limitKey = 'rev_max_albums'
        limitValue = Auth.userData.subscription.limits.rev_max_albums
      }
      else {
        return
      }

      const res = await Api.post('/account/logReachedLimit', {
        customer_id: Auth.userData.customer_id,
        service,
        limitKey,
        limitValue
      })
      if (!res || !res.success) {
        console.warn(res)
      }
      return res
    } catch (e) {
      console.warn(e)
      // eslint-disable-next-line no-undef
      Raven.captureException(e)
    }
  }

  async editSubdomain(subdomain) {
    if (!subdomain || !this.SUBDOMAIN_PATTERN.test(subdomain)) {
      return Promise.reject('InvalidSubdomainError')
    }
    try {
      subdomain = subdomain.toLowerCase()
      const res = await Api.post('/account/edit', { action: 'EditSubdomain', subdomain })
      if (!res || res.success !== true) {
        return Promise.reject(res)
      }
      return res
    } catch (e) {
      console.warn(e)
      // eslint-disable-next-line no-undef
      Raven.captureException(e)
    }
  }

  get SUBDOMAIN_PATTERN() { return SUBDOMAIN_PATTERN }
}

export default new User()
