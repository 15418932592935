import React, {Component} from 'react'
import toastr from 'toastr'
import {Button} from 'antd'

import Api from '~/modules/Api'
import Loading from '~/widgets/Loading'

import CollectionColors from '../common/Colors'

import LogoUpload from './LogoUpload'


class CollectionSettings extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isSubmiting: false,
      settings: false,
      isUploadingLogo: false
    }
    this.updateColor = this.updateColor.bind(this)
    this.uploadLogo = this.uploadLogo.bind(this)
    this.updateSettings = this.updateSettings.bind(this)
    this.getData = this.getData.bind(this)
    this.checkUnprocessedTimeout = this.checkUnprocessedTimeout.bind(this)
  }

  componentDidMount() {
    this.getData()
  }

  async getData () {
    const settings = await Api.get('/customer/settings/collection_customization')
    const processingLogo = !settings.logo && settings.logo_tmp
    if (settings.logo) {
      settings.logo = (
        settings
        .logo
        .replace('picsize.s3.us-west-2.amazonaws.com', 'img.picsize.com.br')
        .replace('picsize.s3-us-west-2.amazonaws.com', 'img.picsize.com.br')
      )
    }
    this.checkUnprocessedTimeout(processingLogo)

    return this.setState({
      processingLogo,
      settings: {
      bgColor: 'rgba(0, 0, 0, 0.5)',
      fontColor: '#333333',
      logo: false,
      ...settings
    }})
  }

  checkUnprocessedTimeout (processing = false) {
    let timeout = 3000
    if (!this.state.processingLogo && !processing) return
    setTimeout(() => {
      this.getData()
    }, timeout)
  }

  async updateSettings () {
    this.setState({isSubmiting: true})

    const result = await Api.post('/customer/settings/collection_customization', {values: this.state.settings})
    this.setState({isSubmiting: false})

    if (result && result.success) {
      return toastr['success']('Dados atualizados com sucesso!')
    }

    toastr['error']('Erro ao atualizar dados')
  }

  updateColor (partName, colorString) {
    this.setState({settings: {...this.state.settings, [`${partName}Color`]: colorString}})
  }

  async uploadLogo (file) {
    if (!file || !file.length) return
    this.setState({isUploadingLogo: true})
    await Api.upload('/customer/settings/collection_customization', {upload:true, field: 'logo'}, file)
    this.setState({isUploadingLogo: false})
    toastr['success']('Upload completo!')
    this.getData()
  }

  render() {
    return (
      <div className="row">
      {
        !(this.state.settings) ?
        <Loading /> :
        <div>
          <div className="col-md-8 col-md-offset-2">
            <div className="portlet light">
              <div className="portlet-title">
                  <div className="caption">
                      <i className="fa fa-registered font-dark"></i>
                      <span className="caption-subject font-dark sbold uppercase">SEU LOGOTIPO</span>
                  </div>
              </div>
              <div className="portlet-body form">
                <LogoUpload
                  isUploading={this.state.isUploadingLogo}
                  onDrop={this.uploadLogo}
                  processingLogo={this.state.processingLogo}
                  currentLogo={this.state.settings.logo}>
                  <small>- Tamanho recomendado do logotipo: <strong>360 x 180px</strong></small><br/>
                  <small>- Tipo de foto: <strong>PNG ou JPG</strong></small><br/>
                  <small>- Sugerimos fotos <strong>PNG</strong> com o fundo transparente, para o layout ficar mais bonito!</small>
                </LogoUpload>
              </div>
            </div>
          </div>
          {/* <div className="col-md-5">
            <div className="portlet light">
              <div className="portlet-title">
                  <div className="caption">
                      <i className="fa fa-paint-brush font-dark"></i>
                      <span className="caption-subject font-dark sbold uppercase">PERSONALIZE</span>
                  </div>
              </div>
              <div className="portlet-body form">
              <small>- Aqui você pode configurar a cor do fundo e da fonte da página de seleção que o seu cliente irá acessar.</small><br/>
              <small>- Depois de cadastradas, as coleções poderão ser personalizadas individualmente.</small>
              <CollectionColors {...this.state.settings} updateColor={this.updateColor} submitChanges={this.updateSettings}>
                <div className="form-actions right">
                  <Button
                    onClick={() => this.updateSettings()}
                    type="primary"
                    icon="check"
                    loading={this.state.isSubmiting}
                  >
                    Salvar
                  </Button>
                </div>
              </CollectionColors>
              </div>
            </div>
          </div> */}
        </div>
      }
    </div>
    )
  }
}

export default CollectionSettings
