import React, { Component } from 'react'

export default class GallerySettings extends Component {


  constructor(props, context) {
    super(props, context)

    this.state = {}
  }

  componentDidMount() {
  }


  render() {


    return (
      <div className='gallery-mobile-page'>
        <div className='portlet light'>
          <div className='portlet-body text-center' style={{ paddingBottom: '96px', marginTop: '48px' }}>
            <span className='caption-subject'>
              Em breve você poderá criar o seu Mobile App para essa linda galeria!
                        </span>
          </div>
        </div>
      </div>
    )
  }

}
