import React from 'react'

import UploadContext from './context'

function ProcessingProgress ({context: state}) {
  const progress = state.processingProgress.done / state.processingProgress.total
  return (

      <div className="portlet light" style={{maxWidth: '1380px', margin: 'auto'}}>
        <div className="row">
          <div className="col-md-10">
            <div className="progress progress-striped progress-upload active" style={{height: '50px'}}>
              <div
                className="progress-bar progress-bar-success"
                role="progressbar"
                aria-valuenow={(progress) * 100}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{width: `${(progress) * 100}%`}}>
                <span className="sr-only"> {parseInt((progress) * 100)}% Completo (success) </span>
                <span style={{fontSize: '20px', lineHeight: '46px'}}>{parseInt((progress) * 100)}%</span>
              </div>
            </div>
          </div>
          <div className="col-md-2" style={{paddingTop: '5px'}}>
            <small>
              <strong>{state.processingProgress.done}</strong> de <strong>{state.processingProgress.total} lâminas</strong>
            </small>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          As lâminas estão sendo processadas para otimizar o tempo de envio...
          </div>
        </div>
      </div>
  )
}

function UploadProgress () {
  return (
    <div className="col-md-12">
      <UploadContext.Consumer>
      {(upload) => {
        if (upload.isProcessing) return <ProcessingProgress context={upload} />
        if (!upload.isUploading) return null
        return (
          <div className="portlet light" style={{maxWidth: '1380px', margin: 'auto'}}>
            <div className="row">
              <div className="col-md-10">
                <div className="progress progress-striped progress-upload active" style={{height: '50px'}}>
                  <div
                    className="progress-bar progress-bar-success"
                    role="progressbar"
                    aria-valuenow={(upload.progress) * 100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{width: `${(upload.progress) * 100}%`}}>
                    <span className="sr-only"> {parseInt((upload.progress) * 100)}% Completo (success) </span>
                    <span style={{fontSize: '20px', lineHeight: '46px'}}>{parseInt((upload.progress) * 100)}%</span>
                  </div>
                </div>
              </div>
              <div className="col-md-2" style={{paddingTop: '5px'}}>
                <small>
                  <strong>{upload.sent}</strong> de <strong>{upload.total} lâminas</strong>
                </small>
                <br />
                <small>
                  <strong>{upload.sentBytes}</strong> de <strong>{upload.totalBytes}</strong>
                </small>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-12">
                <div className="alert alert-warning">
                  <strong>Atenção!</strong>
                  <br />
                  - Não feche essa janela enquanto o envio não for concluído. Após a conclusão você poderá enviar mais fotos e fazer as edições necessárias
                  <br />
                  - Se desejar <a onClick={upload.cancelUpload}>cancelar</a> o envio, as fotos que já foram enviadas não sofrerão alterações.
                </div>
              </div>
            </div>
          </div>
        )
      }}
      </UploadContext.Consumer>
    </div>
  )
}

export default UploadProgress
