import { Component } from 'react'
import { Router, browserHistory } from 'react-router'

import Auth from './modules/Auth'
import routes from './routes'
import CustomerService from './modules/CustomerService'

export default class AppComponent extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      ready: false,
      userData: false
    }
  }

  async componentDidMount() {
    CustomerService.refreshStats()
    CustomerService.pushLog('select-cp-opened', undefined, true)
    await this.getAuthData()
  }

  async getAuthData() {
    const res = await Auth.getUserData()
    if (!res || !res.data) {
      console.warn(res)
      return Auth.logout()
    }

    Auth.userData = res.data
    if (Auth.isTrialExpired) return Auth.logout()

    this.setState({ ready: true, userData: res.data })
    // this.forceUpdate()
  }

  render() {
    return <div>{this.state.ready && <Router history={browserHistory}>{routes}</Router>}</div>
  }
}
