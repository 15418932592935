import React from 'react'
import PropTypes from 'prop-types'

function FormButton (props) {
  return (
    <button {...props}
      type="button"
      className={`btn ${props.className}`}
      onClick={props.onClick}>
      {props.children}
    </button>
  )
}

FormButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default FormButton
