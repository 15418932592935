import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Switch, Input } from 'antd'
import toastr from 'toastr'
import swal from 'sweetalert'
import ReactGA from 'react-ga'

import Loading from '~/widgets/Loading'
import GalleryService from '../GalleryService.js'
import CustomerService from '~/modules/CustomerService'

import './SettingsPage.scss'

export default class GallerySettings extends Component {
  switchWidth = 50
  link = null
  fields = [
    'single_download',
    'download_all',
    'download_photo_size',
    'allow_share',
    'anti_copy',
    'pass_protect',
    'password',
    'show_contact_info',
    'share_image_url',
    'share_title',
    'share_description'
  ]

  constructor(props, context) {
    super(props, context)

    this.state = {
      single_download: false,
      download_all: false,
      download_photo_size: undefined,
      allow_share: true,
      anti_copy: false,
      pass_protect: false,
      password: '',
      show_contact_info: true,
      share_image_url: '',
      share_title: '',
      share_description: ''
    }
    this.updateValue = this.updateValue.bind(this)
    this.reset = this.reset.bind(this)
    this.save = this.save.bind(this)
    this.deleteGallery = this.deleteGallery.bind(this)
  }

  componentDidMount() {
    const { id } = this.props.params
    GalleryService.getLink(id).subscribe(i => {
      this.link = i
      this.reset()
    })
  }

  updateValue(param, value) {
    this.setState({ [param]: value })
  }

  reset() {
    if (!this.link) return
    const values = this.fields.filter(k => this.link[k] !== null)
      .reduce((p, k) => { p[k] = this.link[k]; return p }, {})
    this.setState({ ...values })

  }

  async save() {
    const model = this.fields.reduce((p, k) => { p[k] = this.state[k]; return p }, { id: this.link.id })

    const res = await GalleryService.updateLink(model).toPromise()
    
    if (res && res.success) {
      toastr['success']('Configurações alteradas!')
    }
    else {
      toastr['warning']('Não foi possível alterar as configurações!')
      console.warn(res)
    }
  }

  async deleteGallery() {
    const { id } = this.props.params

    if (!id) {
      return
    }

    const willDelete = await swal({
      title: 'Deseja realmente excluir essa galeria?',
      text: 'Todas as fotos e seleções também serão removidas!',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Deletar'
      }
    })

    if (!willDelete) {
      return
    }

    ReactGA.event({
      category: 'Galeria',
      action: 'Deletou uma galeria'
    })

    const res = await GalleryService.deleteGallery(id)

    if (res && res.success) {
      toastr.success('Galeria removida!')
      CustomerService.refreshStats()
      this.props.router.push('/gallery/list')
      return
    }

    console.warn(res)
  }

  render() {
    if (!this.link) return <Loading />

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 16 },
      },
    }
    const formItemLayout2 = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    return (
      <div className='gallery-settings-page'>
        <div className='portlet light'>

          <div className='portlet-title'>
            <div className="caption">
              <i className="icon-social-dribbble font-green"></i>
              <span className="caption-subject font-green bold uppercase">Configurações Gerais</span>
            </div>
          </div>

          <div className='portlet-body'>
            <Form {...formItemLayout}>

              <Form.Item label='Permitir download individual'>
                <Switch
                  checked={this.state.single_download}
                  onChange={v => this.updateValue('single_download', v)}
                />
              </Form.Item>

              <Form.Item label='Permitir download de todas as fotos'>
                <Switch
                  checked={this.state.download_all}
                  onChange={(v) => this.updateValue('download_all', v)}
                />
              </Form.Item>


              {/* <Form.Item label='Tamanho para download'>
                <select id="single" className="form-control select2 select2-hidden-accessible" tabIndex="-1" aria-hidden="true">
                  <option value={1920}>1920px</option>
                </select>
              </Form.Item> */}

              <Form.Item label='Permitir compartilhamento'>
                <Switch
                  checked={this.state.allow_share}
                  onChange={(v) => this.updateValue('allow_share', v)}
                />
              </Form.Item>

              <Form.Item label='Inserir carimbo "CÓPIA NÃO AUTORIZADA"'>
                <Switch
                  checked={this.state.anti_copy}
                  onChange={(v) => this.updateValue('anti_copy', v)}
                />
              </Form.Item>

              {/* <Form.Item label='Proteger por senha (PIN)' className='pin-item'>
                <Switch
                  checked={this.state.pass_protect}
                  onChange={(v) => this.updateValue('pass_protect', v)}
                />
                {this.state.pass_protect &&
                  <Input type='text' placeholder="Inserir o PIN"
                    value={this.state.password} onChange={e => this.updateValue('password', e.target.value)} />
                }
              </Form.Item> */}

              {/* <Form.Item label='Mostrar minhas informações de contato'>
                <Switch
                  checked={this.state.show_contact_info}
                  onChange={(v) => this.updateValue('show_contact_info', v)}
                />
              </Form.Item> */}
            </Form>
          </div>

          <div className='portlet-title'>
            <div className="caption">
              <i className="fa fa-share font-green"></i>
              <span className="caption-subject font-green bold uppercase">Compartilhamento</span>
            </div>
          </div>

          <div className='portlet-body'>
            <Form {...formItemLayout2}>
              <Form.Item label='Imagem'>
                A imagem de compartilhamento será a foto de capa.
                            </Form.Item>

              <Form.Item label='O titulo da mensagem'>
                <Input type='text' placeholder="Inserir o PIN"
                  value={this.state.share_title} onChange={e => this.updateValue('share_title', e.target.value)} />
              </Form.Item>

              <Form.Item label='A descrição da mensagem'>
                <Input.TextArea autosize={{ minRows: 2, maxRows: 6 }}
                  value={this.state.share_description} onChange={e => this.updateValue('share_description', e.target.value)} />
              </Form.Item>
            </Form>
          </div>
        </div>

        <div className='portlet light'>
          <div className="portlet-body">
            <div className='text-right'>
              <button type="submit" className="btn green" onClick={this.save}>Salvar</button>
            </div>
          </div>
        </div>

        <button className="btn red btn-outline" onClick={this.deleteGallery}>
          Excluir Galeria
                </button>
      </div>
    )
  }
}

GallerySettings.propTypes = {
  location: PropTypes.any,
  params: PropTypes.any,
  router: PropTypes.any
}
