import React, {Component} from 'react'

import swal from 'sweetalert'
import {arrayMove} from 'react-sortable-hoc'
import cloneDeep from 'lodash/cloneDeep'
import {Tooltip, Dropdown, Menu} from 'antd'

import Api from '~/modules/Api'
import Loading from '~/widgets/Loading'

import AlbumSteps from '../common/Steps'

import AlbumSheetsGallery from './Gallery'
import UploadButton from './Upload/Button'
import UploadButtonBig from './Upload/ButtonBig'
import UploadProgress from './Upload/Progress'

import UploadProvider from './Upload/Provider'

import './style.scss'

if (!Array.prototype.fill) {
  Array.prototype.fill = function(value) {

    // Steps 1-2.
    if (this == null) {
      throw new TypeError('this is null or not defined');
    }

    var O = Object(this);

    // Steps 3-5.
    var len = O.length >>> 0;

    // Steps 6-7.
    var start = arguments[1];
    var relativeStart = start >> 0;

    // Step 8.
    var k = relativeStart < 0 ?
      Math.max(len + relativeStart, 0) :
      Math.min(relativeStart, len);

    // Steps 9-10.
    var end = arguments[2];
    var relativeEnd = end === undefined ?
      len : end >> 0;

    // Step 11.
    var final = relativeEnd < 0 ?
      Math.max(len + relativeEnd, 0) :
      Math.min(relativeEnd, len);

    // Step 12.
    while (k < final) {
      O[k] = value;
      k++;
    }

    // Step 13.
    return O;
  };
}

class AlbumSheets extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      isSortable: false,
      isLoading: true,
      galleryFilter: false,
      sheets: [],
      sortedSheets: []
    }

    this.handleChange = this.handleChange.bind(this)
    this.onMoveItem = this.onMoveItem.bind(this)
    this.goToStep = this.goToStep.bind(this)
    this.getData = this.getData.bind(this)
    this.toggleOrdenation = this.toggleOrdenation.bind(this)
    this.saveOrdenation = this.saveOrdenation.bind(this)
    this.setGalleryFilter = this.setGalleryFilter.bind(this)
    this.onFinishUpload = this.onFinishUpload.bind(this)
    this.createNewVersion = this.createNewVersion.bind(this)
    this.loadVersion = this.loadVersion.bind(this)
    this.deleteAlbum = this.deleteAlbum.bind(this)
  }

  async getData (album_version = false) {
    const album_id = this.props.params.id
    const {query} = this.props.location
    const [{album}, {sheets, reviewers}] = await Promise.all([
      Api.get(`/revise/album/${album_id}`),
      Api.get(`/revise/sheet?album_id=${album_id}${album_version ? '&album_version=' + album_version : ''}`)
    ])
    if (album && album.id) {
      return this.setState({
        album,
        isLoading: false,
        old_sheets: sheets.filter(({is_current}) => !is_current),
        sheets: sheets.filter(({is_current}) => is_current),
        reviewers,
        loaded_version: album_version || album.version_count,
        galleryFilter: query && !isNaN(+query.filter) ? +query.filter : false
      })
    }
    this.props.router.push('/album/list')
  }

  componentDidMount () {
    this.getData()
  }

  async loadVersion (version) {
    await this.getData(version)
  }

  async onFinishUpload (uploadPositions) {
    await this.getData()
    await this.saveOrdenation(false, uploadPositions)
    await this.getData()
  }

  handleChange(e) {
    const formState = this.state.form
    formState[e.target.name] = e.target.value
    this.setState({form: formState})
  }

  async toggleOrdenation () {
    if (!this.state.isSortable) {
      this.setState({
        isSortable: true,
        sortedSheets: cloneDeep(this.state.sheets)
      })
      return
    }
    this.setState({
      isSortable: false,
      sortedSheets: false
    })
  }

  async saveOrdenation (sorted = true, uploadPositions = false) {
    let sheets = cloneDeep(sorted ? this.state.sortedSheets : this.state.sheets)
    if (uploadPositions) {
      uploadPositions = uploadPositions.map((us) => {
        return {
          ...sheets.find((s) => s.title === us.name),
          position: us.position
        }
      })

      if (sheets.length === uploadPositions.length) {
        sheets = []
      } else {
        sheets = sheets.filter((sheet) => !uploadPositions.find((usheet) => sheet.title === usheet.name))
      }
    }

    const positionMap = getPositionMap(sheets, uploadPositions)

    const album_id = this.props.params.id
    await Api.post('/revise/sheet/position', {album_id, positionMap})
    this.setState({
      sheets: sheets,
      isSortable: false,
      sortedSheets: false
    })
  }

  async createNewVersion () {
    const album_id = this.props.params.id
    const willCreate = await swal({
      title: 'Criar uma nova versão do álbum?',
      text: 'Ao criar uma nova versão do álbum será necessário enviar todas as lâminas novamente. Além disso, não será possível editar ou solicitar aprovação para as lâminas já enviadas.',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Criar nova versão'
      }
    })
    if (willCreate) {
      await Api.post(`/revise/album/${album_id}/version`, {album_id})
      await this.getData()
    }
  }

  async deleteAlbum () {
    const album_id = this.props.params.id
    const willDelete = await swal({
      title: 'Deletar álbum?',
      text: 'Ao deletar o álbum, TODOS OS DADOS (incluindo lâminas, revisões e clientes) serão removidos permanentemente. Não é possível reverter esta ação. Deseja continuar?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Deletar'
      }
    })
    if (willDelete) {
      await Api.del(`/revise/album/${album_id}`)
      await this.getData()
    }
  }

  async setGalleryFilter (galleryFilter) {
    this.setState({galleryFilter})
  }

  onMoveItem (oldIndex, newIndex) {
    function setPosition (item, index) {
      item.position = index
      return item
    }
    this.setState({
      sortedSheets: arrayMove(this.state.sortedSheets, oldIndex, newIndex).map(setPosition),
    })
  }

  goToStep (stepName) {
    if (this.props.router.location.pathname.includes(stepName)) return
    this.props.router.push(`/album/${this.props.params.id}/${stepName}`)
  }

  render () {
    const uploadParams = {
      fileCategory: 'sheets',
      uploadPath: '/revise/sheet',
      uploadVariables: {
        album_id: this.props.params.id
      },
      statsKeys: {
        file_maxsize: 'rev_file_maxsize',
        space: 'rev_space'
      }
    }
    const hasSheets = this.state.sheets && !!this.state.sheets.length
    const onCurrentVersion = this.state.album && (this.state.loaded_version === this.state.album.version_count)
    return (
      <div className="row">
        <AlbumSteps currentStep={2} reachedStep={3} onClickStep={this.goToStep} />
        <UploadProvider params={uploadParams} currentFiles={this.state.sheets} onFinish={this.onFinishUpload}>
          <UploadProgress />
          <Portlet>
            <Portlet.Title>
              <div className="caption">
                <span className="caption-subject font-dark sbold uppercase">
                  <i className="fa fa-book font-dark" /> Lâminas do álbum
                </span>
                <span className="caption-subject font-dark" style={{fontSize: '15px', marginLeft: '4px'}}>
                  {!!this.state.album && <VersionDropdown
                    addNewVersion={this.createNewVersion}
                    loadVersion={this.loadVersion}
                    loadedVersion={this.state.loaded_version}
                    versionCount={this.state.album.version_count} />}
                </span>
                <span className="caption-subject font-dark" style={{fontSize: '15px', marginLeft: '4px'}}>
                  {!!this.state.sheets.length && `(${this.state.sheets.length} lâminas)`}
                </span>
              </div>
              {
                hasSheets &&
                <div className="tools" style={{padding: '0px'}}>
                  {!this.state.isSortable && onCurrentVersion &&
                    <Tooltip title="Enviar lâminas">
                      <UploadButton />
                    </Tooltip>
                  }
                  <ReorderButton
                    isSortable={this.state.isSortable}
                    toggleOrdenation={this.toggleOrdenation}
                    saveOrdenation={this.saveOrdenation}
                  />
                {!this.state.isSortable && <Tooltip title="Imprimir versão">
                  <a target="_blank" rel="noopener noreferrer" href={`https://album.picsize.com.br/o?album_id=${this.state.album.id}&album_version=${this.state.loaded_version}`}>
                  <span className="btn btn-primary btn-outline green">
                  <i className="fa fa-print" />
                  </span>
                </a>
              </Tooltip>}
                {!this.state.isSortable && <Tooltip title="Pré-visualizar"> <a target="_blank" rel="noopener noreferrer" href={`https://album.picsize.com.br/a/${this.state.album.previewHash}`}>
                  <span className="btn btn-primary btn-outline green">
                  <i className="fa fa-eye" />
                  </span>
                </a> </Tooltip>}
                {!this.state.isSortable && onCurrentVersion && <SendRevisionButton reviewerCount={this.state.album.reviewerCount} goToStep={this.goToStep} />}
                </div>
              }
            </Portlet.Title>
            <Portlet.Body>
              {
                (this.state.isLoading && <Loading />) ||
                (!hasSheets && <UploadButtonBig />) ||
                <AlbumSheetsGallery
                  items={this.state.isSortable ? this.state.sortedSheets : this.state.sheets}
                  reviewers={this.state.reviewers}
                  enableEdit={onCurrentVersion}
                  isSortable={this.state.isSortable}
                  onMoveItem={this.onMoveItem}
                  updateGallery={this.getData}
                  filter={this.state.galleryFilter}
                  setFilter={this.setGalleryFilter}
                />
              }
            </Portlet.Body>
          </Portlet>
        </UploadProvider>
        <DeleteAlbum onClick={this.deleteAlbum} />
      </div>
    )
  }
}

function DeleteAlbum (props) {
  return (
    <div className="col-md-12">
      <span className="btn btn-primary btn-outline red pull-left" onClick={() => props.onClick()}>
        <i className="fa fa-times"></i> Excluir álbum
      </span>
    </div>
  )
}
function VersionDropdown (props) {

  const menu = (
    <Menu>
      {(new Array(props.versionCount))
      .fill(1)
      .map((x, index) => (
        <Menu.Item key={`yo-${index}`}>
          <a onClick={() => props.loadVersion(index + 1)}>
            {index + 1}ª versão
          </a>
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item key="new-version">
        <a onClick={props.addNewVersion}>
        Criar nova versão do álbum
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <div className="versions-dropdown">
      <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
        <button>
          {props.loadedVersion}ª versão <i className="fa fa-angle-down" />
        </button>
      </Dropdown>
    </div>
  )
}

function Portlet ({children}) {
  return (
    <div className="col-md-12">
      <div className="portlet light album-sheets">
        {children}
      </div>
    </div>
  )
}

Portlet.Title = function PortletTitle ({children}) {
  return (
    <div className="portlet-title album-sheets__title">
      {children}
    </div>
  )
}

Portlet.Body = function PortletTitle ({children}) {
  return (
    <div className="portlet-body form row">
      {children}
    </div>
  )
}

function getPositionMap (sheets, uploadPositions) {
  const positionMap = sheets.map(({id, position}) => ({id, position}))

  if (!uploadPositions) return positionMap

  const lastSheetPos = positionMap.reduce((a, s) => {
    if (+(s.position) > a) return +(s.position)
    return a
  }, 0)

  for (const sheet of uploadPositions) {
    let position = (+sheet.position + (!lastSheetPos ? 0 : (lastSheetPos + 1)))
    positionMap.push({id: sheet.id, position})
  }

  return positionMap
}

function ReorderButton (props) {
  if (props.isSortable) {
    return [
      <a onClick={props.saveOrdenation} key='save-sort'>
        <span className="btn btn-primary green">
          <i className="fa fa-check"></i> Salvar ordenação
        </span>
      </a>,
      <a onClick={props.toggleOrdenation} key='cancel-sort'>
        <span className="btn btn-primary red">
          <i className="fa fa-times"></i> Cancelar
        </span>
      </a>
    ]
  }
  return (
    <a onClick={props.toggleOrdenation}>
      <Tooltip title="Alterar ordem das lâminas">
      <span className="btn btn-primary btn-outline green">
        <i className="fa fa-arrows"></i>
      </span>
      </Tooltip>
    </a>
  )
}

function SendRevisionButton (props) {
  return (
    <a onClick={() => props.goToStep('review?modal=new')}>
      <span className="btn btn-primary green">
        <i className="fa fa-user"></i> {
          props.reviewerCount > 0 ?
          'Solicitar nova revisão'
          : 'Enviar para um cliente'
        }
      </span>
    </a>
  )
}

export default AlbumSheets
