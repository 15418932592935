import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'

import Auth from '~/modules/Auth'
import CustomerService from '~/modules/CustomerService'
import WizardBg from './WizardBg'

import './WizardPage.scss'
import WomanImg from 'public/img/woman.png'

export class WizardPage extends Component {
  constructor(props) {
    super(props)
  }

  static propTypes = {
    router: PropTypes.any,
    params: PropTypes.any
  }

  async componentDidMount() {
    if (/first-access/.test(this.props.params.splat)) {
      Auth.firstAccess()
    }
    CustomerService.pushLog('wizard-preview')
  }

  navTo(url, action) {
    ReactGA.event({ category: 'Wizard', action })
    this.props.router.push(url)
  }

  render() {
    return (
      <div className="scp-wizard-page">
        <WizardBg />
        <button onClick={() => this.navTo('/home', 'Clicou fechar')} className="btn-close">
          <i className="fa fa-close"></i>
        </button>

        <div className="wizard-text">
          <h1>Olá</h1>

          <h3>Vou te ajudar a criar sua primeira galeria.</h3>
          <p>
            Essas fotos são para seu cliente{' '}
            <a onClick={() => this.navTo('/collection/new', 'Clicou criar seleção')}>selecionar</a> ou você quer{' '}
            <a onClick={() => this.navTo('/gallery', 'Clicou criar galeria')}>entregar</a> para download?
          </p>
        </div>

        <img src={WomanImg} />
      </div>
    )
  }
}
