import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ProcessingStatus extends Component {

    constructor(props, context) {
        super(props, context)
        this.state = { picsLen: 0, underProcLen: 0, procLen: 0, errorLen: 0 }
    }

    componentWillMount() {
        this.calc(this.props.pictures)
    }

    componentWillReceiveProps() {
        this.calc(this.props.pictures)
    }

    calc(pics) {
        if (!Array.isArray(pics)) {
            pics = []
        }
        let picsLen = 0, underProcLen = 0, procLen = 0, errorLen = 0
        for (const p of pics) {
            picsLen++
            if (p.image_processing) underProcLen++
            else procLen++
        }
        this.setState({ picsLen, underProcLen, procLen, errorLen })
    }

    render() {
        return (
            <div className='portlet light' style={{ color: '#8896a0' }}>
                <div className='portlet-body'>
                    <div style={{ fontWeight: 700 }}>Fotos</div>
                    <div>Enviadas: {this.state.picsLen}</div>
                    <div>Processadas: {this.state.procLen}</div>
                    <div>Processando: {this.state.underProcLen}</div>
                    <div>Erro: {this.state.errorLen}</div>
                </div>
            </div>
        )
    }

}

ProcessingStatus.propTypes = {
    pictures: PropTypes.arrayOf(PropTypes.object),
}