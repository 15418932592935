import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import range from 'lodash/range'

function WatermarkPosition (props) {
  return (
    <div>
    <label
      className="enum optional"
      htmlFor="customer_custom_watermark_position">
      Posição da marca d'água
    </label>
    <div className="watermark-position-grid">
      {
        range(1, 10).map((n, k) => (
          <div key={k} className="position-cell">
            <input
              type="radio"
              defaultValue={`pos-${n}`}
              defaultChecked={n === (props.defaultPosition ? +props.defaultPosition : 9) ? 'checked' : undefined}
              name="wm-pos"
              id={`wm-pos-${n}`} />
            <label htmlFor={`wm-pos-${n}`} onClick={() => props.onChange(n)}>
              {n}
            </label>
          </div>
        ))
      }
    </div>
    </div>
  )
}

WatermarkPosition.propTypes = {

}



export default WatermarkPosition
