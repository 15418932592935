
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Input, Form } from 'antd'
import swal from 'sweetalert'

import Auth from '~/modules/Auth'

export default class SendWhatsappBox extends Component {

    constructor(props) {
        super(props)

        this.state = {
            clientName: '',
            clientTel: '',
            clientMessage: '',
            isValidTel: false,
            valid: false,
            url: '',
            ...props.data
        }

        props.data.hideConfirm = true
        this.onChange = this.onChange.bind(this)
        this.validate = this.validate.bind(this)
        this.send = this.send.bind(this)
        props.$send.subscribe(this.send)
    }

    onChange(value, key) {
        if (!key) {
            key = value.target.name
            value = value.target.value
        }

        const newState = { [key]: value }

        telStatement: if (key === 'clientTel') {
            if (!value || typeof value !== 'string') {
                newState[key] = ''
                newState.isValidTel = false
                break telStatement
            }

            if ([4].indexOf(value.length) !== -1) {
                newState[key] = value.slice(0, 2)
                newState.isValidTel = false
                break telStatement
            }

            value = value.replace(/[\D\(\)\-]/g, '')

            if (!value) {
                value = ''
            }
            else if (value.length === 1) {
                value = `(${value}`
            }
            else if (value.length === 2) {
                value = `(${value}) `
            }
            else if (value.length <= 6) {
                const x = value.match(/(\d{2})(\d{1,4})/);
                value = `(${x[1]}) ${x[2]}`
            }
            else if (value.length <= 10) {
                const x = value.match(/(\d{2})(\d{4})(\d{1,4})/);
                value = `(${x[1]}) ${x[2]}-${x[3]}`
            }
            else {
                const x = value.match(/(\d{2})(\d{5})(\d{4})/);
                value = `(${x[1]}) ${x[2]}-${x[3]}`
            }

            newState[key] = value

            newState.isValidTel = /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{2,3}\-[0-9]{4}$/.test(value)
        }

        this.setState(newState)
        this.validate({ ...this.state, ...newState })
    }

    validate(state) {
        let valid = state.isValidTel && state.clientName && state.clientMessage
        this.setState({ valid })
        this.props.upState({ valid, clientName: state.clientName, clientMessage: state.clientMessage })
    }

    send() {
        if (!this.state.valid) {
            return
        }

        const tel = '55' + this.state.clientTel.toString().replace(/\D+/g, '')

        let message = `Olá, ${this.state.clientName}! Tudo bem?\n\n`
        message += `${this.state.clientMessage}\n\n`
        message += `${this.state.link}\n\n`
        message += 'Um abraço,\n'
        message += Auth.userData.displayName

        const url = `https://web.whatsapp.com/send?phone=${tel}&text=${encodeURIComponent(message)}`

        window.open(url)

        swal.close()
    }

    render() {
        return (
            <Form layout="horizontal" onSubmit={this.props.onSubmit}>
                <FormItem label="Nome do cliente" key="clientName">
                    <Input
                        name="clientName"
                        value={this.state.clientName}
                        onChange={this.onChange}
                    />
                </FormItem>

                <FormItem label="Whatsapp" key="clientTel">
                    <Input
                        name="clientTel"
                        value={this.state.clientTel}
                        onChange={this.onChange}
                        placeholder="(31) 9 9999-9999"
                    />
                </FormItem>

                <FormItem label="Mensagem" key="clientMessage">
                    <Input.TextArea
                        name="clientMessage"
                        value={this.state.clientMessage}
                        onChange={this.onChange}
                        autosize={{ minRows: 4, maxRows: 6 }}
                    />
                </FormItem>
            </Form>
        )
    }
}

const formItemLayout = {
    labelCol: {
        sm: { span: 24 },
        md: { span: 10 }
    },
    wrapperCol: {
        sm: { span: 24 },
        md: { span: 12 }
    },
    colon: false,
    className: 'ps-ant-formItem'
}

const FormItem = props => <Form.Item {...formItemLayout} {...props} />

SendWhatsappBox.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func
}
