import React from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'
import toastr from 'toastr'

import Api from 'app/modules/Api'

async function prompt (data) {
  const {sheet, updateGallery} = data
  const willDelete = await swal({
    title: `Excluir lâmina #${sheet.position + 1}?`,
    text: 'Tem certeza de que deseja excluir todos os dados da lâmina? Esta ação não pode ser revertida.',
    icon: 'warning',
    dangerMode: true,
    buttons: {
      cancel: 'Cancelar',
      confirm: 'Excluir'
    }
  })
  if (willDelete) {
    await deleteSheet(sheet)
    updateGallery()
  }
}

async function deleteSheet (sheet) {
  // ReactGA.event({
  //   category: 'Seleção',
  //   action: 'Deletou uma lâmina'
  // })
  await Api.del(`/revise/sheet/${sheet.id}`, {
    album_id: sheet.rev_album_id,
    parent_id: sheet.parent_id
  })
  toastr.success('Lâmina removida!')
  return true
}

export default {
  prompt
}
